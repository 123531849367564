





import { Component, Prop, Vue, Mixins } from "vue-property-decorator";
import { MemberDTO } from "generated/member/member-dto";
@Component
export default class Member extends Vue {
  @Prop({})
  protected dto!: MemberDTO;

  get showCurrentPage() {
    return this.$store.getters["memberPage/pageId"] === this.dto.pageId;
  }
}
