















import { Component, Prop, Vue } from "vue-property-decorator";

import AccountInformation from "./components/AccountInformation.vue";
import ContactInformation from "./components/ContactInformation.vue";
import FormOfPayment from "./components/FormOfPayment.vue";
import Information from "./components/Information.vue";
import UpdatePassword from "./components/UpdatePassword.vue";
import BankInformation from "./components/BankInformation.vue";

import { PersonalInformationDTO } from "generated/member/personal-information-dto";

@Component({
  components: {
    AccountInformation,
    ContactInformation,
    FormOfPayment,
    Information,
    UpdatePassword,
    BankInformation
  }
})
export default class PersonalInformation extends Vue {
  @Prop({})
  protected dto!: PersonalInformationDTO;

  get showCurrentPage() {
    return this.$store.getters["memberPage/pageId"] === this.dto.pageId;
  }
}
