





import {Component, Mixins} from 'vue-property-decorator';
import ModalsMixin from 'utils/modals/modalsMixin';

@Component({})
export default class MainNavSearch extends Mixins(ModalsMixin) {

  toggleMenu(this: any): void {
    this.toggleModal('main-nav-menu');
  }

}
