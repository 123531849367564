






































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { FlightStatusDTO } from "generated/flightStatus/flight-status-dto";
import { FlightStatusApiResponseDTO } from "generated/api/flightStatus/flight-status-api-response-dto"
import { createFormData } from "generated/api/base-api-request-dto";
import Datepicker from 'utils/datepicker/Datepicker.vue';
import restClient from "utils/restClient";

@Component({
  components: {
    Datepicker
  }
})
export default class FlightStatus extends Vue {
    @Prop()
    protected dto!: FlightStatusDTO;

    private flightNumber: string = "";
    private dateFrom: Date = new Date();

    private formErrorMessage: string = "";
    private formState = {
        ok: false,
        error: false,
        loading: false
    };

    private flights: FlightStatusApiResponseDTO[] = [];

    get filterDateFrom(): Date {
        var currentDate = new Date();
        currentDate.setDate(currentDate.getDate() - 2);
        return currentDate;
    }

    get filterDateTo(): Date {
        var currentDate = new Date();
        currentDate.setDate(currentDate.getDate() + 1);
        return currentDate;
    }
    
    onClickedDateFrom(this: any) {
        window.setTimeout(this.$refs.dateFrom.focus, 10);
    }
    
    onFlightStatusFormSubmit(event) {
        this.formState.loading = true;
        this.formState.ok = false;
        this.formState.error = false;

        restClient
            .post(this.dto.form.action, { 
                flightNumber: this.flightNumber, 
                dateFrom: this.dateFrom 
            })
            .then(response => {
                this.formState.loading = false;
                this.formState.ok = true;
                this.flights = response.flights;
            });
    }
}
