







































































































































































































import { Component, Prop, Vue, Mixins } from "vue-property-decorator";
import ValidationMixin from "utils/mixins/validationMixin";
import { RegisterMemberDTO } from "generated/registerMember";

import {
  constructDTO as constructSignup,
  SessionApiSignUpRequestDTO
} from "generated/api/sessionApi/session-api-sign-up-request-dto";
import {
  SessionApiSignUpResponseDTO
} from "generated/api/sessionApi/session-api-sign-up-response-dto";
import FormWizardSteps from "utils/components/FormWizardSteps.vue";
import Glide from "@glidejs/glide";
import FormClient from "utils/formClient";
import PhoneInput from "utils/components/PhoneInput.vue";
import PasswordEye from "utils/components/PasswordEye.vue";
import { createEmptyFormData } from "generated/api/base-api-request-dto";

import { CountryDTO } from "generated/country-dto";
import CountryService from "utils/services/countryService";

interface RegisterDepartmentState {
  signup: SessionApiSignUpRequestDTO;
  confirmation: {
    signupEmail: string;
    departmentEmail: string;
  };
}

@Component({
  components: {
    FormWizardSteps,
    PasswordEye,
    PhoneInput
  }
})
export default class RegisterMember extends Mixins(ValidationMixin) {
  @Prop({})
  private dto!: RegisterMemberDTO;
  @Prop({})
  private onMemberRegistered?: Function;
  private componentId: string = Math.random().toString(36).substring(2) + Date.now().toString(36);
  private activeStep: number = 0;
  private glide!: any;
  private selectedYear!: any;
  private selectedMonth!: any;
  private formErrorMessage: string = "";
  private countries: CountryDTO[] = [];
  private formState = {
    ok: false,
    error: false,
    loading: false
  };
  private fullPhoneNumber: any = {
    countryCode: null,
    phoneNumber: null
  };

  private state: RegisterDepartmentState = {
    signup: constructSignup(),    
    confirmation: {
      signupEmail: <any>null,
      departmentEmail: <any>null
    }
  };

  async mounted() {
    this.glide = new Glide(`.glide_${this.componentId}`, {
      type: "slider",
      keyboard: false,
      swipeThreshold: false,
      dragThreshold: false,
      startAt: 0,
      animationDuration: 900,
      gap: 100
    }).mount();
    this.state.signup.newsAndOffers = true;
    this.countries = await CountryService.fetch(this.dto.countryRequestUrl);
  }

  get isNotEqualSignupEmailText(): string {
    return this.state.signup.email != this.state.confirmation.signupEmail
      ? "Stämmer inte överens med fältet regler"
      : "";
  }

  get headerBackgroundStyle(): string {
    return this.dto.headerImageBackgroundUrl != null
      ? `url('${this.dto.headerImageBackgroundUrl}')`
      : "";
  }

  get steps(): String[] {
    const stepTranslation = this.dto.translations.step;
    return [
      stepTranslation + " 1",
      stepTranslation + " 2",
      this.dto.translations.stepComplete + "!"
    ];
  }

  get years(): number[] {
    const year = new Date().getFullYear();
    return [...Array(110).keys()].map(i => year - i);
  }

  daysInMonth() {
    if (this.selectedYear && this.selectedMonth) {
      return new Date(this.selectedYear, this.selectedMonth, 0).getDate();
    }
    return 31;
  }

  focusOnNextSlide(event, ref: HTMLInputElement) {    
    if (ref != null) {
      setTimeout(() => {
        this.nextStep(event);
      }, 250);
    }
  }

  // update state when phonenumber changed
  onPhoneNumberChange(newFullPhoneNumber) {
    this.state.signup.countryCode = newFullPhoneNumber.countryCode;
    this.state.signup.phoneNumber = newFullPhoneNumber.phoneNumber;
  }

  getGlideIndex(elem) {
    for (; elem && elem !== document; elem = elem.parentNode) {
      if (elem.hasAttribute("data-glide-index")) {
        return elem.dataset.glideIndex;
      }
    }
    return null;
  }

  onRegisterMemberSubmit(event: any) {
    // Slide to first invalid input (if exists), set error class and focus
    const firstInvalidElem = event.target.querySelector("*:invalid");
    if (firstInvalidElem) {
      const glideIndex = this.getGlideIndex(firstInvalidElem);
      this.goTo(glideIndex);
      firstInvalidElem.classList.add("validation-error");
      firstInvalidElem.focus();
    }

    if (!event.target.checkValidity()) return;
    const formData = createEmptyFormData(this.dto.form);

    formData.append("password", this.state.signup.password);
    formData.append("year", this.state.signup.year.toString());
    formData.append("month", this.state.signup.month.toString());
    formData.append("day", this.state.signup.day.toString());
    formData.append("firstname", this.state.signup.firstname);
    formData.append("surname", this.state.signup.surname);
    formData.append("gender", this.state.signup.gender);
    formData.append("email", this.state.signup.email);
    formData.append("phoneNumber", this.state.signup.phoneNumber);
    formData.append("countryCode", this.state.signup.countryCode);
    formData.append("newsAndOffers", this.state.signup.newsAndOffers ? "true" : "false");

    if (this.dto.afterSuccessfulRegistrationLink != null) {
      formData.append(
        "successfulRedirectUrl",
        this.dto.afterSuccessfulRegistrationLink.link
      );
    }

    this.formState.loading = true;
    this.formState.ok = false;
    this.formState.error = false;

    FormClient.send(this.dto.form, formData)
      .then((response: SessionApiSignUpResponseDTO) => {
        this.formState.loading = false;
        this.formState.ok = true;
        this.formState.error = false;
        if(this.onMemberRegistered)
          this.onMemberRegistered(response,this.state.signup);
        else
          window.location.href = response.redirectUrl;
      })
      .catch(error => {
        this.formState.loading = false;
        this.formState.ok = false;
        this.formState.error = true;
        this.formErrorMessage = error.message;
      });
  }

  validateCustomConfirmInputOnFocus(
    event: FocusEvent,
    confRef,
    message: string
  ) {
    this.validateInputOnFocus(event);
    this.validateInput(confRef, message);
  }

  nextStep(event) {
    event.target.blur();
    const firstInvalidElem = event.target.closest('.glide__slide--active').querySelector("*:invalid");
    
    if (firstInvalidElem) {
      firstInvalidElem.classList.add("validation-error");
      firstInvalidElem.focus();
    } else {
      this.glide.go(">");
      this.activeStep = this.glide.index;
    }
  }

  prevStep() {
    this.glide.go("<");
    this.activeStep = this.glide.index;
  }

  goTo(index) {
    this.glide.go(`=${index}`);
    this.activeStep = this.glide.index;
  }

  selectYear(e) {
    this.selectedYear = e.target.value;
  }

  selectMonth(e) {
    this.selectedMonth = e.target.value;
  }
}
