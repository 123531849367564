

































































import { Component, Prop, Vue, Mixins } from "vue-property-decorator";

import FormClient from "utils/formClient";
import RestClient from "utils/restClient";
import ModalsMixin from "utils/modals/modalsMixin";
import ValidationMixin from "utils/mixins/validationMixin";

import { PersonalInformationDTO } from "generated/member/personal-information-dto";
import { MemberApiCardInformationResponseDTO } from "generated/api/member/member-api-card-information-response-dto";

import SubmitButton from "utils/components/SubmitButton.vue";
import CreditCard from "./components/CreditCard.vue";
import Invoice from "./components/Invoice.vue";
import CreateCreditCardForm from "./components/CreateCreditCardForm.vue";

@Component({
  components: {
    SubmitButton,
    CreditCard,
    Invoice,
    CreateCreditCardForm
  }
})
export default class FormOfPayment extends Mixins(
  ModalsMixin,
  ValidationMixin
) {
  @Prop({})
  protected dto!: PersonalInformationDTO;

  private state: any = {
    hasPrivateCard: this.dto.hasPrivateCreditCard,
    hasCompanyCard: this.dto.hasCompanyCreditCard,
    hasInvoice: this.dto.hasInvoiceCreditCard
  };

  onRemoveCardClick(card) {
    FormClient.send(this.dto.formOfPaymentRemoveCreditCardRequest, this.createRemoveFormData(card))
      .then(resp => {
        this.dto.privateCreditCard = <any>null;
        this.state.hasPrivateCard = false;
      })
      .catch(error => {});
  }

  onRemoveCompanyCardClick(card) {
    FormClient.send(this.dto.formOfPaymentRemoveCreditCardRequest, this.createRemoveFormData(card))
      .then(resp => {
        this.dto.companyCreditCard = <any>null;
        this.state.hasCompanyCard = false;
      })
      .catch(error => {});
  }

  fetchCardInformation(success: boolean) {
    RestClient.get(this.dto.memberApiCardInformationRequest.action)
      .then((resp: MemberApiCardInformationResponseDTO) => {
        this.dto.privateCreditCard = resp.privateCreditCard;
        this.dto.companyCreditCard = resp.companyCreditCard;
        this.dto.invoiceCreditCard = resp.invoiceCreditCard;
        this.state.hasPrivateCard = this.dto.privateCreditCard != null;
        this.state.hasCompanyCard = this.dto.companyCreditCard != null;
        this.state.hasInvoice = this.dto.invoiceCreditCard != null;
        this.toggleModal();
      })
      .catch(error => {});
  }

  private createRemoveFormData(card): FormData {
    const formData = new FormData();
    formData.append("cardId", card.id);
    formData.append(
      "frequentFlyerId",
      this.dto.formOfPaymentRemoveCreditCardRequest.frequentFlyerId
    );

    return formData;
  }
}
