class IEDetector  {
  private _isIEBrowser : boolean = false;
  private _version : number = -1;
  
  constructor(){
    this._version = this.detectIE();
    this._isIEBrowser = this._version !== -1;
  }

  get isIEBrowser() : boolean {
    return this._isIEBrowser;
  }

  get version() : number {
    return this._version;
  }

  private detectIE() : number {
    let version = -1;

    if (window.navigator && window.navigator.userAgent) {
      let ua = window.navigator.userAgent;

      // Test values; Uncomment to check result …

      // IE 10
      // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';

      // IE 11
      // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';

      // Edge 12 (Spartan)
      // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';

      // Edge 13
      // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

      let msie = ua.indexOf('MSIE ');
      if (msie > 0) {
        // IE 10 or older => return version number
        version = parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
      }

      let trident = ua.indexOf('Trident/');
      if (trident > 0) {
        // IE 11 => return version number
        let rv = ua.indexOf('rv:');
        version = parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
      }
    }

    // other browser
    return version;
  }
}

export default new IEDetector();
