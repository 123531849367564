import { Component, Prop, Vue } from "vue-property-decorator";
import _get from "lodash/get";

import { AirSearchPresentDTO } from "generated/airSearch";
import {
  AirSearchFlowApiResponseDTO,
  BaseRetailResponseDTO,
  AirSearchFlowApiRequestDTO,
  createEmptyFormData
} from "generated/api";
import { BaseAirSearchDTO } from "generated/airsearch/base-air-search-dto";
import { DestinationRoutePartialDTO } from "generated/airSearch/destination-route-partial-dto";
import { DepartureRoutePartialDTO } from "generated/airSearch/departure-route-partial-dto";
import FormClient from "utils/formClient";
import { nameofFactory } from "utils/nameofFactory";
import { toEretailDatetimeString, yyyymmdd } from "utils/dateFormat";

const nameOfAirSearchRequest = nameofFactory<AirSearchFlowApiRequestDTO>();

interface DepartureRouteDictionary extends DepartureRoutePartialDTO {
  destinationRoutesDictionary: {number : DestinationRoutePartialDTO};
}

@Component
export default class AirsearchMixin extends Vue {
  @Prop({})
  public onRetailResponse!: (response: BaseRetailResponseDTO) => void;
  public formErrorMessage: string = "";
  public formState = {
    ok: false,
    error: false,
    loading: false
  };

  private _routesDictionary! : {number : DepartureRouteDictionary};

  public createFormFromRoutesData(
    routes : DepartureRoutePartialDTO[],
    request: AirSearchFlowApiRequestDTO,
    state: AirSearchPresentDTO,
  ): FormData {
    const formData = createEmptyFormData(request);
    const button = this.$el.getElementsByClassName('btn-success')[0];

    if(button) {
      const abValue = button.getAttribute('data-overrideab');
      if(abValue) {
        formData.append(nameOfAirSearchRequest("OVERRIDE_AB_TEST"), abValue);
      }
    }
    formData.append(nameOfAirSearchRequest("typeOfTravel"), request.typeOfTravel.toString());
    formData.append(nameOfAirSearchRequest("viewPort"), request.viewPort);

    // To and from
    const { to, from } = this.getAirports(routes,  _get(state, "from.airport.airportId", -1), _get(state, "to.airport.airportId", -1));
    const priceType = _get(state, "ticketType.priceType");

    formData.append(nameOfAirSearchRequest("b_LOCATION_1"), _get(from, "airport.code"));
    formData.append(nameOfAirSearchRequest("flightPossibilitiesStatus"), _get(to, "airport.flightPossibilitiesStatus"));
    formData.append(nameOfAirSearchRequest("b_LOCATION_2"), _get(to, "airport.code"));

    formData.append(nameOfAirSearchRequest("tRIP_TYPE_BOOL"),state.isRoundTrip ? "true" : "false");
    formData.append(nameOfAirSearchRequest("b_DATE_1"),toEretailDatetimeString(state.startDate));
    formData.append(nameOfAirSearchRequest("b_DATE_2"), toEretailDatetimeString(state.endDate));
    formData.append(nameOfAirSearchRequest("lowFareCalendar"),state.showLowFareCalendar ? "true" : "false");
    formData.append(nameOfAirSearchRequest("payWithAVoucher"),state.payWithAVoucher ? "true" : "false");
    formData.append(nameOfAirSearchRequest("priceType"), priceType);
    formData.append(nameOfAirSearchRequest("priceTypeName"), _get(state, "ticketType.priceTypeName"));
    formData.append(nameOfAirSearchRequest("isGreenFare"), to.airport.priceTypes.length > 0 && to.airport.priceTypes.find(x => x.priceType == priceType) != null && to.airport.priceTypes.find(x => x.priceType == priceType).isGreenFare ? "true" : "false");

    const passengers = JSON.stringify(state.passengers);
    formData.append(
      nameOfAirSearchRequest("passengers"),
      passengers.substring(1, passengers.length - 1)
    );

    return formData;
  }

  public hideToDatepicker(dto: BaseAirSearchDTO): boolean {
    return !dto.airSearchPreset.isRoundTrip && dto.disableTripToggle;
  }

  public sendForm(request: AirSearchFlowApiRequestDTO, formData: FormData) {
    this.formState.loading = true;
    this.formState.ok = false;
    this.formState.error = false;

    FormClient.send(request, formData)
      .then((response: AirSearchFlowApiResponseDTO) => {
        this.formState.loading = false;
        this.formState.ok = true;
        if (this.onRetailResponse) {
          this.onRetailResponse(response);
        }
      })
      .catch(error => {
        this.formState.loading = false;
        this.formState.error = true;
        this.formErrorMessage = error.message;
      });
  }

  public validateInputs(inputs = this.$refs) {
    let formErrors = <any>[];

    // loop through all refs and validate if possible
    Object.entries(inputs).forEach(([, input]) => {
      if (Object.prototype.hasOwnProperty.call(input, "validate")) {
        const elementErrors = input["validate"]();
        formErrors.push(...elementErrors);
      }
    });

    return formErrors;
  }

  protected getAirport(routes : DepartureRoutePartialDTO[], airportFromNr : number) : DepartureRouteDictionary
  {
      // Create a dictionary for fast look up
      if(!this._routesDictionary) {
        this._routesDictionary = this.createRouteDictionary(routes);
      }

      return this._routesDictionary[airportFromNr];
  }

  protected getAirports(routes : DepartureRoutePartialDTO[], airportFromNr : number, airportToNr : number){
    let foundFromAirport : DepartureRouteDictionary | undefined = this.getAirport(routes, airportFromNr);

    return {
      from : foundFromAirport as DepartureRoutePartialDTO | undefined,
      to : (foundFromAirport !== undefined) ? foundFromAirport.destinationRoutesDictionary[airportToNr] : undefined as DepartureRoutePartialDTO | undefined
    };
  }

  private createRouteDictionary(routes : DepartureRoutePartialDTO[]) : {number : DepartureRouteDictionary}{
    return  <{number : DepartureRouteDictionary}>routes.reduce((map, obj) => {
      // Create a dictionary for destination routes
      map[obj.airport.airportId] = Object.assign(obj, {
        destinationRoutesDictionary : obj.destinationRoutes.reduce((mapd, objd) => {
          mapd[objd.airport.airportId] = objd;
          return mapd;
        }, {})
      });

      return map;
    }, {});
  }
}
