




























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Offer extends Vue {
  @Prop({
    default: {
      title: 'title',
      description: 'description',
      level: 3,
      link: '',
      img: 'https://dummyimage.com/400x400&text=img',
      city: 'Malmö',
      category: 'resa'
    }
  })
  private dto!: any;

  private level: number = 1;
}
