import { render, staticRenderFns } from "./MemberHeader.vue?vue&type=template&id=39a7c570&"
import script from "./MemberHeader.vue?vue&type=script&lang=ts&"
export * from "./MemberHeader.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "MemberHeader.vue"
export default component.exports