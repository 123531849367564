


















































































































import { Component, Prop, Vue, Mixins, Watch } from "vue-property-decorator";
import { AirSearchMemberNextTripDTO } from "generated/airSearch/air-search-member-next-trip-dto";
import { TravelerBookingApiBookingDTO } from "generated/api/travelerBooking/traveler-booking-api-booking-dto";
import { TravelerBookingApiTripSummaryDTO } from "generated/api/travelerBooking/traveler-booking-api-trip-summary-dto";
import { TravelerBoardingPassesBookingRequestDTO } from "generated/api/travelerBoardingPasses/traveler-boarding-passes-booking-request-dto";
import { TravelerBoardingCheckInRequestDTO } from "generated/api/travelerBoarding/traveler-boarding-check-in-request-dto";
import memberProfileService from "utils/services/memberProfileService";
import RestClient from "utils/restClient";
import ModalsMixin from "utils/modals/modalsMixin";
import RetailMixin from "utils/mixins/retailMixin";

@Component({})
export default class MemberNextTrip extends Mixins(ModalsMixin, RetailMixin) {
  @Prop()
  protected dto!: AirSearchMemberNextTripDTO;

  private hasNextBooking: boolean = false;
  private nextBooking!: TravelerBookingApiBookingDTO;
  private nextBookingTripSummary!: TravelerBookingApiTripSummaryDTO;
  private fetchingBookings: boolean = true;
  private nextBookingFlightNumber: string = "";

  private areYouSureChoice1Callback!: () => void;
  private areYouSureChoice2Callback!: () => void;
  private forwardToTitle: String = "";

  created() {
    this.fetchingBookings = true;
    
    memberProfileService
      .fetchNextBooking(this.dto.nextBookingRequest)
      .then(response => {        
        if (response.hasBooking) {
          const tripSegments = response.nextBooking.tripSegments;
          const responseBooking = response.nextBooking;

          this.nextBooking = responseBooking;

          if (responseBooking.mainTrip.isTravelActive) {
            this.nextBookingTripSummary = responseBooking.mainTrip;
          } else if (
            responseBooking.hasReturnTrip &&
            responseBooking.returnTrip.isTravelActive
          ) {
            this.nextBookingTripSummary = responseBooking.returnTrip;
          } else {
            this.nextBookingTripSummary = responseBooking.mainTrip;
          }

          for (let index = 0; index < tripSegments.length; index++) {
            const tripSegment = tripSegments[index];
            if (tripSegment.isTravelActive) {
              this.nextBookingFlightNumber = tripSegment.flightNumber;
              break;
            }
          }
        }
      })
      .finally(() => {
        this.hasNextBooking = this.nextBooking != null;
        this.fetchingBookings = false;
      });
  }

  showSubpage(pageId) {
    this.$store.dispatch("memberPage/setPageId", pageId);
  }

  possibleToCheckIn(booking: TravelerBookingApiBookingDTO) {
    return (
      booking.mainTrip.possibleToCheckIn ||
      (booking.returnTrip ? booking.returnTrip.possibleToCheckIn : false)
    );
  }

  possibleToRebook(booking: TravelerBookingApiBookingDTO) {
    return (
      booking.isTravelActive && !booking.isAgencyBooking
    );
  }

  onCheckIn(booking: TravelerBookingApiBookingDTO) {
    this.forwardToTitle = this.dto.bookingsTranslations.bookingTableCheckIn;
    this.sendCheckInAction(booking);
  }

  onAddServices(booking: TravelerBookingApiBookingDTO) {
    const data = this.createRequest(this.dto.addServicesActionUrl, booking);
    this.forwardToTitle = this.dto.bookingsTranslations.addServices;

    if (this.goDirectToAction(booking)) {
      this.sendAction(data);
    } else {
      this.showAreYouSureModal(
        () => {
          this.onCheckIn(booking);
        },
        () => {
          this.sendAction(data);
        }
      );
    }
  }

  onRebook(booking: TravelerBookingApiBookingDTO) {
    const data = this.createRequest(this.dto.rebookActionUrl, booking);
    this.forwardToTitle = this.dto.bookingsTranslations.rebook;

    if (this.goDirectToAction(booking)) {
      this.sendAction(data);
    } else {
      this.showAreYouSureModal(
        () => {
          this.onCheckIn(booking);
        },
        () => {
          this.sendAction(data);
        }
      );
    }
  }

  onCancelTrip(booking: TravelerBookingApiBookingDTO) {
    const data = this.createRequest(this.dto.refundActionUrl, booking);
    this.forwardToTitle = this.dto.bookingsTranslations.cancelTrip;

    if (this.goDirectToAction(booking)) {
      this.sendAction(data);
    } else {
      this.showAreYouSureModal(
        () => {
          this.onCheckIn(booking);
        },
        () => {
          this.sendAction(data);
        }
      );
    }
  }

  onShow(booking: TravelerBookingApiBookingDTO) {
    const data = this.createRequest(this.dto.retriveActionUrl, booking);
    this.forwardToTitle = this.dto.bookingsTranslations.show;

    if (this.goDirectToAction(booking)) {
      this.sendAction(data);
    } else {
      this.showAreYouSureModal(
        () => {
          this.onCheckIn(booking);
        },
        () => {
          this.sendAction(data);
        }
      );
    }
  }

  private sendCheckInAction(booking: TravelerBookingApiBookingDTO) {
    this.$store.dispatch(
      "pageLoader/text",
      `${this.dto.bookingsTranslations.bookingTableForwardTo} ${this.forwardToTitle}`
    );
    this.$store.dispatch("pageLoader/show");

    RestClient.post(this.dto.checkInRequest.action, {
      surname: booking.mainTraveler.surname,
      bookingReference: booking.reference,
      cultureInfo: this.dto.checkInRequest.cultureInfo
    })
    .then(response => {
      window.location.href = response.Url;
    })
    .catch(error => {
      this.$store.dispatch("pageLoader/hide");
    });
  }

  private sendAction(model: TravelerBoardingPassesBookingRequestDTO) {
    RestClient.post(model.action, model).then(
      resp => {

        resp.isTripFlowEnabled = false;

        this.onTripFlow(resp, form => {
          this.$store.dispatch(
            "pageLoader/text",
            `${this.dto.bookingsTranslations.bookingTableForwardTo} ${
              this.forwardToTitle
            }`
          );
          this.$store.dispatch("pageLoader/show");
          form.submit();
        });
      },
      error => {
        console.log(error);
      }
    );
  }

  private goDirectToAction(booking: TravelerBookingApiBookingDTO): boolean {
    const currentDate = new Date();
    const hoursBeforeDeparture = (booking.mainTrip.departure.date.getTime() - currentDate.getTime()) / (1000*60*60);

    return hoursBeforeDeparture > 24;
  }

  private showAreYouSureModal(
    callbackChoice1: () => void,
    callbackChoice2: () => void
  ) {
    this.areYouSureChoice1Callback = callbackChoice1;
    this.areYouSureChoice2Callback = callbackChoice2;
    this.showModal("are-you-sure-info");
  }

  private createRequest(
    actionUrl: string,
    booking: TravelerBookingApiBookingDTO
  ): TravelerBoardingPassesBookingRequestDTO {
    return {
      action: actionUrl,
      bookingReference: booking.reference,
      surname: booking.mainTraveler.surname,
      viewportSize: this.dto.viewportSize,
      bookingType: booking.bookingType,
      directFlightOnly: booking.directFlight,
      method: "",
      inputList: [],
    };
  }
}
