export default class CacheService {
  private cachePool : { [id: string] : any; } = {};

  hasValue(key : string) : boolean{
    return this.cachePool[key] != null;
  }

  getValue<T>(key : string) : T {
    return <T>this.cachePool[key];
  }

  setValue<T>(key : string, value : T) : T {
    return <T>(this.cachePool[key] = value);
  }

  clear(){
    this.cachePool = {};
  }
}
