















import { Component, Prop, Vue, Mixins, Watch } from "vue-property-decorator";
import { LinksGridControlDTO } from '../../generated/linksGridControl/links-grid-control-dto';

@Component({})
export default class Links extends Vue {
  @Prop()
  protected dto!: LinksGridControlDTO;

  showSubpage(pageId) {
    this.$store.dispatch("memberPage/setPageId", pageId);
  }
}
