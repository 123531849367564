


























import { Component, Prop, Vue, Mixins } from "vue-property-decorator";

import DynamicBackground from "../dynamicBackground/DynamicBackground.vue";
import Airsearch from "../airsearch/Airsearch.vue";
import TripFlowEditor from "../airsearch/TripFlowEditor.vue";
import PromotionsCarousel from "../promotionsCarousel/PromotionsCarousel.vue";
import RetailMixin from "utils/mixins/retailMixin";
import { BaseRetailResponseDTO } from "generated/api/base-retail-response-dto";
import {
  AirSearchDTO,
  AirSearchCaruselDTO,
  filterMobileCaruselItems
} from "generated/airSearch";

@Component({
  components: {
    DynamicBackground,
    Airsearch,
    PromotionsCarousel,
    TripFlowEditor
  }
})
export default class AirsearchSection extends Mixins(RetailMixin) {
  @Prop({})
  private dto!: AirSearchDTO;
  @Prop({})
  private caruseldto!: AirSearchCaruselDTO[];
  private filteredCaruseldto = filterMobileCaruselItems(this.caruseldto);

  get carouselIndex(): number {
    return this.$store.getters.airSearchCarouselIndex;
  }

  get airSearchContent() {
    return this.dto;
  }

  get carouselContent() {
    return this.filteredCaruseldto;
  }

  sendToRetail(response: BaseRetailResponseDTO): void {
    this.onTripFlow(response, form => {
      this.$store.dispatch("pageLoader/text", "Vi är nästan klara för avgång!");
      this.$store.dispatch("pageLoader/show");
      form.submit();
    });
  }
}
