









































import {Prop, Vue} from 'vue-property-decorator';
import Component from 'vue-class-component';
import {ReceiptGridControlDto} from 'generated/receipt/receipt-grid-control-dto';
import RestClient from 'utils/restClient';
import Datepicker from 'utils/datepicker/Datepicker.vue';

@Component({
  components: {
    Datepicker
  }
})
export default class SendReceiptGridControl extends Vue {

  @Prop({})
  private dto!: ReceiptGridControlDto;

  private email: string = '';
  private bookingRef: string = '';
  private surname: string = '';
  private travelDate: Date = new Date();
  private errors: Array<string> = [];

  private receiptSent = {
    triggered: false,
    class: '',
    message: '',
    success: false
  };

  validateForm() {

      this.validate();

  }

  validate() {
    this.errors = [];
    if (!this.surname.length) {
      this.errors.push('surname');
    }
    if (!this.bookingRef.length) {
      this.errors.push('bookingRef');
    }
    const date = new Date(this.travelDate);
    if (!date || isNaN(date.getDate())) {
      this.errors.push('travelDate');
    }
    if (this.emailNotValid) {
      this.errors.push('email');
    }
  }

  sendReceipt() {
    this.receiptSent.triggered = true;
    this.validate();
    if (this.emailNotValid || this.errors.length) {
      return;
    }
    let formatMessage = (header: string, text: string): string => {
      return header ? `${header} ${text}` : (text || '');
    };

    let sendSuccess = false;
    this.$store.dispatch('pageLoader/show');
    RestClient.post(this.dto.receiptDetails.sendReceiptUrl, {
      surname: this.surname,
      bookingReference: this.bookingRef,
      mailTo: this.email,
      travelDate: `${this.travelDate.getFullYear()}-${this.travelDate.getMonth() + 1}-${this.travelDate.getDate()}T00:00:00.000Z`,
    })
      .then(response => {
        sendSuccess = response.success;
      })
      .finally(() => {
        if (sendSuccess) {
          this.receiptSent.class = 'text-success';
          this.receiptSent.message = formatMessage(this.dto.receiptDetails.translations.successMailHeader, this.dto.receiptDetails.translations.successMailMessage);
        } else {
          this.receiptSent.class = 'text-danger';
          this.receiptSent.message = formatMessage(this.dto.receiptDetails.translations.errorMailHeader, this.dto.receiptDetails.translations.errorMailMessage);
        }
        this.$store.dispatch('pageLoader/hide');
      });
  }

  get emailNotValid(): boolean {
    const regex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    return !regex.test(this.email);
  }

  get formIsLocked(): boolean {
    return this.receiptSent.triggered && this.receiptSent.success;
  }

  isValid(field: string) {
    return !this.errors.includes(field);
  }
}

