




























import { Component, Prop, Mixins } from "vue-property-decorator";
import ModalsMixin from "utils/modals/modalsMixin";
import TacList from "src/components/tac/TacList.vue";
import TacForm from "src/components/tac/TacForm.vue";
import { TacValues } from "src/components/tac/models";
import { PersonalInformationDTO } from "generated/member/personal-information-dto";
import { FrontendUserStoreDTO } from 'generated/store/frontend-user-store-dto';

@Component({
  components: {
    TacList,
    TacForm
  }
})
export default class AccountInformation extends Mixins(
  ModalsMixin
) {
  @Prop({})
  protected dto!: PersonalInformationDTO;

  private tacValues: TacValues = {
    ...{
      orderNumber: this.siteUser.travelAccountInformation.orderNumber.value,
      projectNumber: this.siteUser.travelAccountInformation.projectNumber.value,
      employeeNumber: this.siteUser.travelAccountInformation.employeeNumber.value,
      department: this.siteUser.travelAccountInformation.department.value,
      costCenter: this.siteUser.travelAccountInformation.costCenter.value,
      customerNumber: this.siteUser.travelAccountInformation.customerNumber.value,
      travelOrderIdentity: this.siteUser.travelAccountInformation.travelOrderIdentity.value,
      freeText: this.siteUser.travelAccountInformation.freeText.value
    }
  };

  get siteUser(): FrontendUserStoreDTO {
    return this.$store.getters["site/user"];
  }

  get tacList() {
    return this.siteUser.tacList;
  }

  resetState() {
    this.tacValues = {
      ...{
        orderNumber: this.siteUser.travelAccountInformation.orderNumber.value,
        projectNumber: this.siteUser.travelAccountInformation.projectNumber.value,
        employeeNumber: this.siteUser.travelAccountInformation.employeeNumber.value,
        department: this.siteUser.travelAccountInformation.department.value,
        costCenter: this.siteUser.travelAccountInformation.costCenter.value,
        customerNumber: this.siteUser.travelAccountInformation.customerNumber.value,
        travelOrderIdentity: this.siteUser.travelAccountInformation.travelOrderIdentity.value,
        freeText: this.siteUser.travelAccountInformation.freeText.value
      }
    };
  }
}
