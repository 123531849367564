










import { Component, Prop, Vue } from "vue-property-decorator";

interface FormState {
  ok: boolean;
  error: boolean;
  loading: boolean;
}

@Component
export default class SubmitButton extends Vue {
  @Prop({
    default: () => {
      return {
        ok: false,
        error: false,
        loading: false
      };
    }
  })
  private state!: FormState;

  @Prop({
    default: () => ""
  })
  private errorMessage!: string;

  @Prop({
    default: () => ""
  })
  private okMessage!: string;

  @Prop({
    default: () => ""
  })
  private buttonText!: string;

  @Prop({
    default: () => false
  })
  private disabled!: boolean;
}
