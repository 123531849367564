import { Module, GetterTree, ActionTree, MutationTree } from "vuex";
import { MemberPageState, RootState } from "../types";

const namespaced: boolean = true;

export const state: MemberPageState = {
  pageId: -1
};

const getters: GetterTree<MemberPageState, RootState> = {
  pageId: state => state.pageId,
};

const actions: ActionTree<MemberPageState, RootState> = {
  setPageId({ commit }, value) {
    commit('setPageId', parseInt(value, 10));
  },
};

const mutations: MutationTree<MemberPageState> = {
  setPageId(state, value) {
    state.pageId = value;
  },
};

export const module: Module<MemberPageState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
