








































import _get from "lodash/get";
import { Component, Prop, Vue, Watch, Model } from 'vue-property-decorator';
@Component
export default class OffersFilterPanel extends Vue {
  @Model('model-changed')
  private modelValue!: {};
  @Prop({ required: true })
  private cities!: string[];
  @Prop({ required: true })
  private categories!: string[];

  private selectedTab = '';
  private filter: any = {};
  private TABS = {
    CITIES: 'CITIES',
    CATEGORIES: 'CATEGORIES'
  };

  created() {
    if (this.modelValue) {
      this.filter = Object.assign({}, this.modelValue);
    } else {
      this.clearFilter();
    }
  }

  selectTab(tab) {
    this.selectedTab = this.selectedTab === tab ? '' : tab;
  }

  isSelectedTab(tab) {
    return this.selectedTab === tab;
  }

  hasFilters(tab) {
    if (tab === this.TABS.CITIES) {
      return this.filter.cities.length > 0;
    } else if (tab === this.TABS.CATEGORIES) {
      return this.filter.categories.length > 0;
    } else {
      return false;
    }
  }

  clearFilter() {
    this.filter = {
      cities: [],
      categories: []
    };
  }

  @Watch('filter', { deep: true })
  onFilterChange(newFilter) {
    const newFilterCopy = Object.assign({}, newFilter);
    this.$emit('model-changed', newFilterCopy);
  }
}
