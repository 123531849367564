

















































import { Component, Prop, Vue } from "vue-property-decorator";

import Slider from "utils/components/Slider.vue";
import FormClient from "utils/formClient";

import { DonateDTO } from "generated/member/donate-dto";
import { FrontendUserStoreDTO } from 'generated/store/frontend-user-store-dto';
import { createFormData } from "generated/api/base-api-request-dto";

@Component({
  components: {
    Slider
  }
})
export default class Donate extends Vue {
  @Prop({})
  protected dto!: DonateDTO;
  private selectedPoints : number = this.siteUser.awardPoints >= 1000 ? 1000 : 0;
  private formErrorMessage: string = "";
  private formState = {
    ok: false,
    error: false,
    loading: false
  };

  get siteUser(): FrontendUserStoreDTO {
    return this.$store.getters["site/user"];
  }


  get sliderStep() {
    const steps = this.dto.steps;
    for (let index = 0; index < steps.length; index += 1) {
      const step = steps[index];
      if (this.siteUser.awardPoints >= step.minimum) {
        if (step.maximum === -1) {
          return step.stepSize;
        } else if (this.siteUser.awardPoints <= step.maximum) {
          return step.stepSize;
        }
      }
    }
    return 1;
  }

  onDonateFormSubmit(event) {
    this.formState.loading = true;
    this.formState.ok = false;
    this.formState.error = false;

    FormClient.send(
      this.dto.form,
      createFormData(event.target, this.dto.form)
    )
      .then(response => {
        this.formState.loading = false;
        this.formState.ok = true;
        this.$store.dispatch("site/setUserAwardPoints", response);
      })
      .catch(error => {
        this.formState.loading = false;
        this.formState.error = true;
        this.formErrorMessage = error.message;
      });
  }
}
