




















































import { Component, Prop, Vue } from "vue-property-decorator";
import { FooterDTO } from "generated/footer-dto";
import { NcNavigationIconItemDTO } from "generated/nc-navigation-icon-item-dto";

@Component
export default class FooterComponent extends Vue {
  @Prop({})
  private dto!: FooterDTO;

  get hrefPhonenumber(): string {
    return "tel:" + this.dto.customerServicePhonenumberRef;
  }

  get hrefEmail(): string {
    return "mailto:" + this.dto.customerServiceEmail;
  }

  isNotUndefined(item: any): boolean {
    return item !== undefined;
  }

  iconClassName(iconItem: NcNavigationIconItemDTO): string {
    return "footer__main-icon fa " + iconItem.icon;
  }

  showChat(): void {
    const chatWebTab = document.getElementById('ArtviseWebHelpTab');
    const chatExpandedContainer = document.getElementById('ArtviseDialogExpandButton');
    if (chatWebTab !== undefined && chatWebTab !== null){
        chatWebTab.click();
    } else if (chatExpandedContainer !== undefined && chatExpandedContainer !== null) {
      chatExpandedContainer.click();
    }
  }
}
