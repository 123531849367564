










import _get from "lodash/get";
import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import FilterPanel from "./filterPanel/FilterPanel.vue";
import Pagination from "utils/components/Pagination.vue";
import TripFlowEditor from "../airsearch/TripFlowEditor.vue";
import ModalsMixin from "utils/modals/modalsMixin";
import RetailMixin from "utils/mixins/retailMixin";
import RestClient from "utils/restClient";
import {MyBookingsDTO} from "generated/myBookings/my-bookings-dto";

import {
    TravelerBookingApiBookingDTO,
    TravelerBookingApiTripSummaryDTO,
    validateDTOs as validateBookings
} from "generated/api/travelerBooking";
import {TravelerBoardingPassesBookingRequestDTO} from "generated/api/travelerBoardingPasses/traveler-boarding-passes-booking-request-dto";
import dateHelper from '../../utils/dateHelper';
import MyBookingsList from "./MyBookingsList.vue";

@Component({
    components: {
        MyBookingsList,
        FilterPanel,
        Pagination,
        TripFlowEditor
    }
})
export default class MyBookings extends Mixins(ModalsMixin, RetailMixin) {
    @Prop({})
    private dto!: MyBookingsDTO;
    private bookings: TravelerBookingApiBookingDTO[] = validateBookings(this.dto.bookings);
    @Prop({default: () => false})
    private expandAll!: boolean;
    @Prop({default: () => true})
    private filterEnabled!: boolean;
    @Prop({default: () => true})
    private paginationEnable!: boolean;
    @Prop({default: () => true})
    private exportToCsvEnable!: boolean;
    @Prop({default: () => true})
    private enableTableHeader!: boolean;

    private areYouSureChoice1Callback!: () => void;
    private areYouSureChoice2Callback!: () => void;
    private forwardToTitle: String = "";
    private currentPage: number = 1;

    private filteredBookings: TravelerBookingApiBookingDTO[] = validateBookings(this.bookings);
    private totalRows: number = this.bookings.length;

    private itemsPerPage: number = this.paginationEnable ? 10 : 10000;
    private expandBookingItem: string = "";

    private currentDate: Date = new Date();
    private filterDateFrom: Date | null = new Date();
    private filterDateTo: Date | null = null;
    private filterDateFromStart: Date = new Date(this.currentDate.getUTCFullYear() - 1, 0, 1);
    private startRangeMonthOffset: number = -(dateHelper.getDifferenceInMonths(this.filterDateFromStart, this.currentDate) + 1);

    private filterSettings = {
        departures: [],
        destinations: [],
        reference: "",
        dateFrom: this.filterDateFrom,
        dateFromStart: this.filterDateFromStart,
        startRangeMonthOffset: this.startRangeMonthOffset,
        dateTo: this.filterDateTo,
        query: "",
        prepaid: null,
    };
    private receiptForms: any = {
        visibleFormNumber: "",
        value: this.dto.receiptDetails.currentMemberEmail,
        sendReceipt: {
            triggered: false,
            message: "",
            class: "",
        },
    };

    get headerBackgroundStyle(): string {
        return this.dto.overviewHeaderBackgroundUrl != null
            ? `linear-gradient(0deg,rgba(0, 160, 176, 0.9),rgba(0, 160, 176, 0.9)), url('${
                this.dto.overviewHeaderBackgroundUrl
            }')`
            : "linear-gradient(0deg,rgba(0, 160, 176, 0.9),rgba(0, 160, 176, 0.9))";
    }

    get siteLanguage(): string {
        return this.$store.getters["site/general"].language;
    }

    get emailNotValid(): boolean {
        const regex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        return !regex.test(this.receiptForms.value);
    }

    getTravelDuration(trip: TravelerBookingApiTripSummaryDTO): string {
        return trip != null
            ? `${trip.travelDurationHours}h ${trip.travelDurationMinutes}m`
            : "";
    }

    isOnlyOneBooking() {
        if (this.bookings.length !== 1) return false;
        // clear filter date from
        this.filterSettings.dateFrom = null;
        // expand the only booking item
        this.expandBooking(this.bookings[0].reference);
        return true;
    }

    airportsFrom(): string[] {
        return this.getUniqueValuesFromProp(
            this.bookings,
            "mainTrip.departure.airport"
        );
    }

    airportsTo(this: any): string[] {
        return this.getUniqueValuesFromProp(
            this.filteredBookings,
            "mainTrip.destination.airport"
        );
    }

    @Watch("currentPage")
    onCurrentPage() {
        const min = (this.currentPage - 1) * this.itemsPerPage;
        const max = this.currentPage * this.itemsPerPage;
        this.filterList(min, max);
    }

    @Watch("filterSettings")
    onFilterSettings() {
        this.filterList(0, this.itemsPerPage);
        this.currentPage = 1;
    }

    buildFilterUrl(departures, destinations, reference, dateFrom, dateTo, query, prepaid) {
        var params = '?dateFrom=' + dateFrom + '&dateTo=' + dateTo + '&reference=' + reference + '&query=' + query + '&prepaid=' + prepaid;
        if (departures && departures.length > 0) {
            departures.forEach(element => {
                params += "&departure=" + element;
            });
        }
        if (destinations && destinations.length > 0) {
            destinations.forEach(element => {
                params += "&destination=" + element;
            });
        }
        var downloadElement = document.getElementById('exportToExcel') as HTMLLinkElement;
        var baseUrl = downloadElement.attributes.getNamedItem("data-baseUrl");
        if (baseUrl != null) {
            downloadElement.href = baseUrl.value + params;
        }
    }

    filterList(min: number, max: number) {
        let copyList = [...this.bookings];
        let filtered: Array<any> = [];
        const {
            departures,
            destinations,
            reference,
            dateFrom,
            dateFromStart,
            dateTo,
            query,
            prepaid
        } = this.filterSettings;
        let amountOfRows: number = 0;
        this.buildFilterUrl(departures, destinations, reference, dateFrom, dateTo, query, prepaid);
        for (let index = 0; index < copyList.length; index++) {
            const item = copyList[index];
            const {mainTrip, returnTrip} = item;

            if (dateFrom) {
                const dateFromFilter = dateFrom.getTime();
                const mainTripDateValue = new Date(mainTrip.departure.date).getTime();
                const returnTripDateValue = (returnTrip !== null && returnTrip.departure !== null ? new Date(returnTrip.departure.date).getTime() : 0);

                if (returnTripDateValue > 0) {
                    if (returnTripDateValue < dateFromFilter) continue;
                } else {
                    if (mainTripDateValue < dateFromFilter) continue;
                }
            }

            // filter on date to
            if (dateTo) {
                const dateToFilter = dateTo.getTime();
                const mainTripDateValue = new Date(mainTrip.departure.date).getTime();
                const returnTripDateValue = (returnTrip !== null && returnTrip.departure !== null ? new Date(returnTrip.departure.date) : 0);

                if (returnTripDateValue > 0) {
                    if (returnTripDateValue > dateToFilter) continue;
                } else {
                    if (mainTripDateValue > dateToFilter) continue;
                }
            }

            // filter on departure
            if (departures && departures.length > 0) {
                if (!departures.includes(<never>mainTrip.departure.airport)) {
                    continue;
                }
            }

            // filter on destination
            if (destinations && destinations.length > 0) {
                if (!destinations.includes(<never>mainTrip.destination.airport)) {
                    continue;
                }
            }

            // filter on reference
            if (reference && reference.length > 0) {
                if (!item.reference.toLowerCase().includes(reference.toLowerCase())) {
                    continue;
                }
            }

            // filter query
            if (query && query.length > 0) {
                if (!this.matchSearchCriteria(item, query)) {
                    continue;
                }
            }

            // filter prepaid
            if (prepaid != null && prepaid) {
                if (item.bookingType != "Prepaid") {
                    continue;
                }
            }

            amountOfRows++;

            if (amountOfRows > min && amountOfRows <= max) {
                filtered.push(item);
            }
        }

        this.totalRows = amountOfRows;
        this.filteredBookings = filtered;
    }

    matchSearchCriteria(item, query) {
        return item.reference.toLowerCase().includes(query.toLowerCase()) ||
            item.mainTrip.departure.airport.toLowerCase().includes(query.toLowerCase()) ||
            item.mainTrip.destination.airport.toLowerCase().includes(query.toLowerCase());
    }

    onCheckIn(booking: TravelerBookingApiBookingDTO) {
        this.forwardToTitle = this.dto.translations.bookingTableCheckIn;
        this.sendCheckInAction(booking);
    }

    onAddServices(booking: TravelerBookingApiBookingDTO) {
        const data = this.createRequest(this.dto.addServicesActionUrl, booking);
        this.forwardToTitle = this.dto.translations.addServices;

        if (this.goDirectToAction(booking)) {
            this.sendAction(data);
        } else {
            this.showAreYouSureModal(
                () => {
                    this.onCheckIn(booking);
                },
                () => {
                    this.sendAction(data);
                }
            );
        }
    }

    onRebook(booking: TravelerBookingApiBookingDTO) {
        const data = this.createRequest(this.dto.rebookActionUrl, booking);
        this.forwardToTitle = this.dto.translations.rebook;

        if (this.goDirectToAction(booking)) {
            this.sendAction(data);
        } else {
            this.showAreYouSureModal(
                () => {
                    this.onCheckIn(booking);
                },
                () => {
                    this.sendAction(data);
                }
            );
        }
    }

    onCancelTrip(booking: TravelerBookingApiBookingDTO) {
        const data = this.createRequest(this.dto.refundActionUrl, booking);
        this.forwardToTitle = this.dto.translations.cancelTrip;

        if (this.goDirectToAction(booking)) {
            this.sendAction(data);
        } else {
            this.showAreYouSureModal(
                () => {
                    this.onCheckIn(booking);
                },
                () => {
                    this.sendAction(data);
                }
            );
        }
    }

    onShow(booking: TravelerBookingApiBookingDTO) {
        const data = this.createRequest(this.dto.retriveActionUrl, booking);
        this.forwardToTitle = this.dto.translations.show;

        if (this.goDirectToAction(booking)) {
            this.sendAction(data);
        } else {
            this.showAreYouSureModal(
                () => {
                    this.onCheckIn(booking);
                },
                () => {
                    this.sendAction(data);
                }
            );
        }
    }

    isReceiptFormVisible(bookingReference: string) {
        return this.receiptForms.visibleFormNumber === bookingReference;
    }

    toggleReceiptEmailForm(bookingReference: string) {
        if (this.receiptForms.visibleFormNumber === bookingReference) {
            this.receiptForms.visibleFormNumber = "";
            this.hideReceiptEmailMessage();
            return;
        }
        this.receiptForms.visibleFormNumber = bookingReference;
    }

    hideReceiptEmailMessage = () => {
        this.receiptForms.sendReceipt.triggered = false;
    };

    sendReceipt(dto: MyBookingsDTO, booking: TravelerBookingApiBookingDTO): void {
        if (this.emailNotValid) {
            return;
        }
        let formatMessage = (header: string, text: string): string => {
            return header ? `${header} ${text}` : (text || '');
        };
        let sendSuccess = false;
        this.$store.dispatch("pageLoader/show");
        RestClient.post(dto.receiptDetails.sendReceiptUrl, {
            surname: booking.mainTraveler.surname,
            bookingReference: booking.reference,
            mailTo: this.receiptForms.value,
            travelDate: booking.mainTrip.departure.date.toISOString(),
        })
            .then(response => {
                sendSuccess = response.success;
            })
            .finally(() => {
                this.receiptForms.sendReceipt.triggered = true;
                if (sendSuccess) {
                    this.receiptForms.sendReceipt.class = 'fa fa-check-circle text-success';
                    this.receiptForms.sendReceipt.message = formatMessage(this.dto.receiptDetails.translations.successMailHeader, this.dto.receiptDetails.translations.successMailMessage)
                } else {
                    this.receiptForms.sendReceipt.class = 'fa fa-times-circle text-danger';
                    this.receiptForms.sendReceipt.message = formatMessage(this.dto.receiptDetails.translations.errorMailHeader, this.dto.receiptDetails.translations.errorMailMessage)
                }

                this.$store.dispatch("pageLoader/hide");
            });
    }

    expandBooking(reference, event?: MouseEvent) {
        if (event) {
            (<any>this).$scrollTo(event.target, 500, {easing: 'ease-in', offset: -120});
        }
        this.expandBookingItem = reference;

        const htmlDocument = document as HTMLDocument;
        if (htmlDocument != null) {
            if (reference !== "") {

                let carTrawlerScript = htmlDocument.createElement('script');
                carTrawlerScript.setAttribute('src', this.dto.carTrawlerScriptUrl);

                const htmlHeadElement = htmlDocument.head as HTMLHeadElement;
                if (htmlHeadElement != null) {
                    htmlHeadElement.appendChild(carTrawlerScript);
                }

            } else {

                const carTrawlerScript = htmlDocument.querySelectorAll('script[src="' + this.dto.carTrawlerScriptUrl + '"]');
                if (carTrawlerScript != null && carTrawlerScript.length > 0 && carTrawlerScript[0].parentNode != null) {
                    carTrawlerScript[0].parentNode.removeChild(carTrawlerScript[0]);
                }
            }
        }
    }

    getTravelerField(booking: TravelerBookingApiBookingDTO) {
        if (booking.mainTraveler) {
            return [
                booking.mainTraveler.firstName,
                booking.mainTraveler.surname,
                booking.numberOfPassengers > 1
                    ? ` (+${booking.numberOfPassengers - 1})`
                    : ""
            ]
                .join(" ")
                .trim();
        }
        return "";
    }

    possibleToCheckIn(booking: TravelerBookingApiBookingDTO) {
        return (
            booking.mainTrip.possibleToCheckIn ||
            (booking.returnTrip ? booking.returnTrip.possibleToCheckIn : false)
        );
    }

    possibleToRebook(booking: TravelerBookingApiBookingDTO) {
        return (
            booking.isTravelActive && !booking.isAgencyBooking
        );
    }

    closeExpand() {
        this.expandBooking("");
        this.hideReceiptEmailMessage();
    }

    showBackdrop() {
        return this.expandBookingItem !== "";
    }

    isExpanded(reference) {
        return this.expandBookingItem === reference;
    }

    getUniqueValuesFromProp(bookings: TravelerBookingApiBookingDTO[], prop) {
        const uniqueValues = [];

        for (let index = 0; index < bookings.length; index++) {
            const propValue = <never>_get(bookings[index], prop);

            if (propValue && uniqueValues.indexOf(propValue) === -1) {
                uniqueValues.push(propValue);
            }
        }

        return uniqueValues;
    }

    private createRequest(
        actionUrl: string,
        booking: TravelerBookingApiBookingDTO
    ): TravelerBoardingPassesBookingRequestDTO {
        return {
            action: actionUrl,
            bookingReference: booking.reference,
            surname: booking.mainTraveler.surname,
            viewportSize: this.dto.viewportSize,
            bookingType: booking.bookingType,
            directFlightOnly: booking.directFlight,
            method: "",
            inputList: []
        };
    }

    private sendCheckInAction(booking: TravelerBookingApiBookingDTO) {
        this.$store.dispatch(
            "pageLoader/text",
            `${this.dto.translations.bookingTableForwardTo} ${this.forwardToTitle}`
        );
        this.$store.dispatch("pageLoader/show");

        RestClient.post(this.dto.checkInRequest.action, {
            surname: booking.mainTraveler.surname,
            bookingReference: booking.reference,
            cultureInfo: this.dto.checkInRequest.cultureInfo
        })
            .then(response => {
                window.location.href = response.Url;
            })
            .catch(error => {
                this.$store.dispatch("pageLoader/hide");
            });
    }

    private sendAction(model: TravelerBoardingPassesBookingRequestDTO) {
        RestClient.post(model.action, model).then(
            resp => {
                this.onTripFlow(resp, form => {
                    this.$store.dispatch(
                        "pageLoader/text",
                        `${this.dto.translations.bookingTableForwardTo} ${
                            this.forwardToTitle
                        }`
                    );
                    this.$store.dispatch("pageLoader/show");
                    form.submit();
                });
            },
            error => {
                console.log(error);
            }
        );
    }

    private goDirectToAction(booking: TravelerBookingApiBookingDTO): boolean {
        const currentDate = new Date();
        const hoursBeforeDeparture = (booking.mainTrip.departure.date.getTime() - currentDate.getTime()) / (1000 * 60 * 60);

        return hoursBeforeDeparture > 24;
    }

    private showAreYouSureModal(
        callbackChoice1: () => void,
        callbackChoice2: () => void
    ) {
        this.areYouSureChoice1Callback = callbackChoice1;
        this.areYouSureChoice2Callback = callbackChoice2;
        this.showModal("are-you-sure-info");
    }


}
