

































import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({})
export default class DynamicBackground extends Vue {
  @Prop({ default: () => 0 })
  private carouselIndex!: number;
  @Prop({})
  private carouselContent!: Array<object>;

  mounted() {
    // play if first slide is video
    const firstSlide = this.carouselContent[this.carouselIndex];
    if (this.isVideo(firstSlide)) this.playVideo(this.carouselIndex);
  }

  @Watch("carouselIndex")
  onCarouselIndexChanged(newIndex: number, oldIndex: number) {
    const newSlide = this.carouselContent[newIndex];
    const oldSlide = this.carouselContent[oldIndex];

    if (this.isVideo(newSlide)) {
      this.playVideo(newIndex);
    }
    if (this.isVideo(oldSlide)) {
      const that = this;

      // pause video first after 1.5s ( pause after transition is done)
      setTimeout(function() {
        that.pauseVideo(oldIndex);
      }, 1500);
    }
  }

  styleUrl(slide) : string{
    return slide != null && slide.media != null ? `url('${slide.media.ref}')` : '';
  }

  isImage(slide): boolean {
    return slide.media.type.split("/")[0] === "image";
  }

  isVideo(slide): boolean {
    return slide.media.type.split("/")[0] === "video";
  }

  playVideo(index: number) {
    const ref = this.$refs[index][0];
    if (ref) {
      ref.play();
    }
  }

  pauseVideo(index: number) {
    const ref = this.$refs[index][0];
    if (ref) {
      ref.pause();
    }
  }
}
