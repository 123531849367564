import "@babel/polyfill";
import "url-polyfill";

import Vue from "vue";
import vueScrollTo from "vue-scrollto";
import "./main.scss";

// Utils
import { getStore } from "./utils/store";
import dateHelper from "./utils/dateHelper";
import { hhmm, yyyymmdd, hhmmutc, yyyymmddutc } from "./utils/dateFormat";

import LazyLoad from "./utils/lazy/LazyLoad.vue";

import throttle from "lodash/throttle";
import stickybits from "stickybits";

// Components
import HeaderComponent from "./components/headerComponent/HeaderComponent.vue";
import FooterComponent from "./components/footerComponent/FooterComponent.vue";
import AirsearchSection from "./components/airsearchSection/AirsearchSection.vue";
import AirsearchSectionMember from "./components/airsearchSection/AirsearchSectionMember.vue";
import AirsearchSectionMaxpax from "./components/airsearchSection/AirsearchSectionMaxpax.vue";

import AirsearchProfile from './components/airsearch/AirsearchProfile.vue';
import MemberHeader from "./components/airsearch/MemberHeader.vue";
import MemberNextTrip from "./components/airsearch/MemberNextTrip.vue";
import MemberFooter from "./components/airsearch/MemberFooter.vue";
import MemberBanner from "./components/airsearch/MemberBanner.vue";
import MemberOrganization from "./components/airsearch/MemberOrganization.vue";
import MemberProducts from "./components/airsearch/MemberProducts.vue";
import MemberBonus from "./components/airsearch/MemberBonus.vue";

import SessionRestrictedLoginPage from './components/sessionRestricted/SessionRestrictedLoginPage.vue'

import Links from "./components/dtge/Links.vue";
import FlightStatus from "./components/flightStatus/FlightStatus.vue";

import MemberBar from "./components/memberBar/MemberBar.vue";
import PageLoader from "./utils/components/PageLoader.vue";
import ScrollToTop from "./utils/components/ScrollToTop.vue";
import LogoutAction from "./utils/components/LogoutAction.vue";
import SessionExpiration from "./utils/components/SessionExpiration.vue";
import SetState from "./utils/components/SetState.vue";

import MyBookingsSection from "./components/myBookings/MyBookingsSection.vue";
import MyBookings from "./components/myBookings/MyBookings.vue";
import MyBookingsList from "./components/myBookings/MyBookingsList.vue";

import Erretail from "./components/erretail/Erretail.vue";
import ErretailForm from "./components/erretail/ErretailForm.vue";

import MemberPageSection from "./components/memberPages/MemberPageSection.vue";
import Member from "./components/memberPages/member/Member.vue";

import MyProducts from "./components/memberPages/myProducts/MyProducts.vue";
import Donate from "./components/memberPages/donate/Donate.vue";
import BuyBioFuel from "./components/memberPages/buyBioFuel/BuyBioFuel.vue";
import MiniAirsearch from "./components/airsearch/MiniAirsearch.vue";
import PointsHistory from "./components/memberPages/pointsHistory/PointsHistory.vue";
import ProductActivities from "./components/memberPages/productActivities/ProductActivities.vue";
import RegisterPoints from "./components/memberPages/registerPoints/RegisterPoints.vue";
import RegisterPointsLight from "./components/memberPages/registerPointsLight/RegisterPointsLight.vue";
import PersonalInformation from "./components/memberPages/personalInformation/PersonalInformation.vue";

import RegisterMember from "./components/registerMember/RegisterMember.vue";
import RegisterDepartment from "./components/registerDepartment/RegisterDepartment.vue";
import RegisterConfirmation from "./components/registerDepartment/RegisterConfirmation.vue";

import Boarding from "./components/boarding/Boarding.vue";
import InfoDetails from "./components/infoDetails/InfoDetails.vue";
import InfoOverview from "./components/infoOverview/InfoOverview.vue";
import InfoHeader from "./components/infoHeader/InfoHeader.vue";

import WebshopTile from "./components/webshopTile/WebshopTile.vue";
import MemberPrepaidFlowButtonGridControl from "./components/memberPrepaidFlowButtonGridControl/MemberPrepaidFlowButtonGridControl.vue";

import Destinations from "./components/compositions/Destinations.vue";
import Modal from "./utils/modals/Modal.vue";
import SearchPage from "./components/searchPage/SearchPage.vue";
import ForgotPassword from "./components/forgotPassword/forgotPassword.vue";
import ForgotMemberId from "./components/forgotMemberId/forgotMemberId.vue";
import LoginPage from "./components/loginPage/LoginPage.vue";
import LoginPageModal from "./components/loginPage/LoginPageModal.vue";
import OffersCompilation from "./components/offersCompilation/OffersCompilation.vue";
import DetailPage from "./components/detailPage/DetailPage.vue";
import MyActivities from "./components/myActivities/MyActivities.vue";
import MemberAuthorizedLink from "./components/memberAuthorizedLink/MemberAuthorizedLink.vue";
import IFrameComponent from "utils/components/IFrameComponent.vue";
import ProgressBarComponent from "utils/components/ProgressBarComponent.vue";
import PasswordEye from "utils/components/PasswordEye.vue"
import SendReceiptGridControl from './components/receipt/SendReceiptGridControl.vue';

Vue.filter("toLocale", (val: any) => parseInt(val, 10).toLocaleString());
Vue.filter("dateOnly", (val: any) => val.split(" ")[0]);
Vue.filter("toCreditCardFormat", (val: string) => {
  let format = "",
    length = val.length;
  for (let index = 0; index < length; index++) {
    format += val.charAt(index);
    format += index % 4 == 3 && index != length - 1 ? " " : "";
  }
  return format;
});
Vue.filter("captilizeWord", (word: string) => {
  if (!word) return "";
  const newWordString = word.toString();
  return `${newWordString.charAt(0).toUpperCase()}${newWordString
    .slice(1)
    .toLowerCase()}`;
});

// Date filter
Vue.filter("parseDateOnlyUTC", yyyymmddutc);
Vue.filter("parseDateOnly", yyyymmdd);
Vue.filter("parseDateUTC", (date: Date) => {
  return date
    ? [yyyymmddutc(date), hhmmutc(date)].join(" ")
    : "DATE CANNOT BE NULL";
});
Vue.filter("parseDate", (date: Date) => {
  return date ? [yyyymmdd(date), hhmm(date)].join(" ") : "DATE CANNOT BE NULL";
});
Vue.filter("parseTimeOnlyUTC", hhmmutc);
Vue.filter("parseNiceDateUTC", (date: Date, dayOfWeek: number) => {
  const day = date.getUTCDate();
  const month = dateHelper.getLongMonth(date.getUTCMonth());
  const weekday = dateHelper.getLongWeekday(dayOfWeek);
  return [weekday, day, month].join(" ");
});

// Global components
Vue.use(vueScrollTo);
Vue.component("modal", Modal);
Vue.config.productionTip = false;

Vue.prototype.userData = null;

const vm = new Vue({
  el: "#app",
  store: getStore(),
  components: {
    AirsearchSection,
    AirsearchSectionMaxpax,
    AirsearchSectionMember,

    AirsearchProfile,
    MemberHeader,
    MemberNextTrip,
    MemberFooter,
    MemberBanner,
    MemberOrganization,
    MemberProducts,
    MemberBonus,

    SessionRestrictedLoginPage,

    Links,
    FlightStatus,

    PageLoader,
    ScrollToTop,
    LogoutAction,
    SessionExpiration,
    SetState,

    MemberPageSection,
    Member,
    MemberAuthorizedLink,

    MyProducts,
    MiniAirsearch,
    Donate,
    BuyBioFuel,
    PointsHistory,
    ProductActivities,
    RegisterPoints,
    RegisterPointsLight,
    PersonalInformation,

    RegisterMember,
    RegisterDepartment,
    RegisterConfirmation,

    Boarding,
    InfoDetails,
    InfoOverview,
    InfoHeader,
    HeaderComponent,
    MemberBar,
    FooterComponent,

    MyBookings,
    MyBookingsList,
    MyBookingsSection,

    Erretail,
    ErretailForm,

    WebshopTile,
    MemberPrepaidFlowButtonGridControl,

    Destinations,
    SearchPage,
    ForgotPassword,
    ForgotMemberId,
    LoginPage,
    LoginPageModal,
    OffersCompilation,
    DetailPage,
    MyActivities,
    IFrameComponent,
    ProgressBarComponent,
    LazyLoad,
    PasswordEye,
    SendReceiptGridControl
  }
});

if (!vm) {
  throw Error(":(");
}

const makePropertyReadonly = (data) => JSON.parse(JSON.stringify(data));
const handleScroll = (scrollSections, activeSection): any => {
  const currentScrollTop = (document as any).documentElement.scrollTop || document.body.scrollTop;

  for (let i = 0; i < scrollSections.length; i += 1) {

    const scrollSection = scrollSections[i];
    const sectionOffsetFromBottom = scrollSection.offsetTop + scrollSection.offsetHeight - 100;

    if (currentScrollTop <= sectionOffsetFromBottom) {
      activeSection = scrollSection;
      break;
    }
  }
};
const addScrolling = () => {
  const scrollSections = (document as any)
  .getElementById("scrollable-content")
  .querySelectorAll("article");
  
  const activeSection = scrollSections[0];
  const isDesktop = window.innerWidth > 991;

  if (isDesktop) {
    window.addEventListener("scroll", throttle(function() { handleScroll(scrollSections, activeSection) }, 100));
    stickybits("#sticky-nav", { stickyBitStickyOffset: 100 });
  }
};

export default {
  /*
  * Readonly property, Specific session user
  */
  getUser: () => makePropertyReadonly(vm.$store.getters["site/user"]),
  addScrolling: () => addScrolling(),
  scrollTo: (index) => vueScrollTo.scrollTo('#section_' + index, 500, { easing: 'ease-in', offset: -80, force: true, cancelable: false, x: false, y: true }),
};
