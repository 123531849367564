















import { Component, Prop, Vue } from 'vue-property-decorator';
import RegisterDepartmentLogin from './RegisterDepartmentLogin.vue';
import { RegisterConfirmationDTO } from 'generated/registerDepartment/register-confirmation-dto';
import { SessionApiLoginResponseDTO } from "generated/api/sessionApi/session-api-login-response-dto";

@Component({
  components: {
    RegisterDepartmentLogin
  }
})
export default class RegisterConfirmation extends Vue {
  @Prop({})
  private dto!: RegisterConfirmationDTO;

  get headerBackgroundStyle(): string {
    return this.dto.registerBackgroundUrl != null
      ? `url('${this.dto.registerBackgroundUrl}')`
      : '';
  }
  
  onLoginMember(response : SessionApiLoginResponseDTO) {
    window.location.href = response.userRedirectUrl;
  }
}
