


















































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import dateHelper from 'utils/dateHelper';

@Component({})
export default class DatepickerDropdown extends Vue {
  @Prop({ default: true })
  private isSettingStartDate!: boolean;
  @Prop({})
  private onSelectedDateCb!: Function;
  @Prop({})
  private startDate!: Date;
  @Prop({})
  private endDate!: Date;
  @Prop({})
  private minimumStartDate!: Date;
  @Prop({})
  private maximumStartDate!: Date;
  @Prop({default: () => 0})
  private startRangeMonthOffset!: number;

  private visibleMonth: Date = new Date();
  private today: Date = new Date();
  private lastBookableDay: Date = new Date();

  private prevDateRestriction : Date = dateHelper.getMonthOffset(this.today, this.startRangeMonthOffset);
  private selectedStartDate: Date = new Date(this.startDate);
  private selectedEndDate: Date = new Date(this.endDate);
  private visibleStartDate: Date = new Date(this.startDate);
  private visibleEndDate: Date = new Date(this.endDate);

  init() {
    const oneYearAhead = new Date(this.today);
    oneYearAhead.setFullYear(this.today.getFullYear() + 1);
    oneYearAhead.setDate(1); // set to first day in month
    this.lastBookableDay = oneYearAhead;
    this.today.setHours(0, 0, 0, 0);

    this.selectedStartDate = new Date(this.startDate);
    this.selectedEndDate = new Date(this.endDate);
    
    this.setVisibleStartDate(new Date(this.startDate));
    this.setVisibleEndDate(new Date(this.endDate));
    this.setVisibleMonth(new Date(this.startDate));
  }

  created() {
    this.init();
  }

  @Watch('startDate')
  onStartDateChanged(this: any, date) {
    this.selectedStartDate = new Date(date);
    this.setVisibleStartDate(this.selectedStartDate);

    if (date) {
      this.setVisibleMonth(this.selectedStartDate);
    }
  }

  @Watch('endDate')
  onEndDateChanged(this: any, date) {
    this.selectedEndDate = new Date(date);
    this.setVisibleEndDate(this.selectedEndDate);

    if (date) {
      this.setVisibleMonth(this.selectedEndDate);
    }
  }

  get currentMonthAndYear() {
    const month = dateHelper.monthNames[this.visibleMonth.getMonth()];
    const year = this.visibleMonth.getFullYear();
    return `${month} ${year}`;
  }

  setVisibleStartDate(date) {
    this.visibleStartDate = new Date(date);
  }

  setVisibleEndDate(date) {
    this.visibleEndDate = new Date(date);
  }

  onDateClicked(date) {
    this.onSelectedDateCb(date);
  }

  onDateMouseEnter(date) {
    if (this.isSettingStartDate) {
      this.setVisibleStartDate(date);
    } else {
      this.setVisibleEndDate(date);
    }
  }

  onCalendarMouseLeave() {
    // reset visible start- & end dates to dates that were selected
    this.setVisibleStartDate(this.selectedStartDate);
    this.setVisibleEndDate(this.selectedEndDate);
  }

  setVisibleMonth(date: Date) {
    this.visibleMonth = dateHelper.getFirstDayInMonth(date);
  }

  isPrevDisabled() {
    return this.visibleMonth.getTime() <= this.prevDateRestriction.getTime();
  }

  isNextDisabled() {
    return this.visibleMonth.getTime() >= this.lastBookableDay.getTime();
  }

  onPrevMonthClick() {
    if (this.isPrevDisabled()) return;
    const prevMonth = new Date(this.visibleMonth);
    prevMonth.setMonth(this.visibleMonth.getMonth() - 1);
    this.setVisibleMonth(prevMonth);
  }

  onNextMonthClick() {
    if (this.isNextDisabled()) return;
    const nextMonth = new Date(this.visibleMonth);
    nextMonth.setMonth(this.visibleMonth.getMonth() + 1);
    this.setVisibleMonth(nextMonth);
  }

  get weekDays(): Array<String> {
    return dateHelper.weekDayNames;
  }

  // Pads with days from previous month until it reaches Monday
  get prevMonthDaysPadding() {
    const days: Date[] = [];
    let currentDay = new Date(this.visibleMonth);

    while (currentDay.getDay() !== 1) {
      currentDay.setDate(currentDay.getDate() - 1);
      days.unshift(new Date(currentDay) as any);
    }
    return days;
  }

  // Days of selected month
  get visibleMonthDays() {
    const days: Date[] = [];
    const nextMonth = new Date(this.visibleMonth);
    nextMonth.setMonth(this.visibleMonth.getMonth() + 1);

    const currentDay = new Date(this.visibleMonth);
    while (currentDay.getTime() < nextMonth.getTime()) {
      days.push(new Date(currentDay));
      currentDay.setDate(currentDay.getDate() + 1);
    }

    return days;
  }

  // Pads with days from next month until it reaches Sunday
  get nextMonthPadding() {
    const days: Date[] = [];
    let currentDay = new Date(this.visibleMonth);

    // set currentDay to last day of month
    currentDay.setMonth(this.visibleMonth.getMonth() + 1);
    currentDay.setDate(0);

    while (currentDay.getDay() !== 0) {
      currentDay.setDate(currentDay.getDate() + 1);
      days.push(new Date(currentDay));
    }
    return days;
  }

  getDateClass(date) {
    let classNames = '';
    if (date.getMonth() !== this.visibleMonth.getMonth()) {
      classNames += 'disabled inactive-month ';
    }
    if (this.minimumStartDate != null && date.getTime() < this.minimumStartDate.getTime()) {
      classNames += 'disabled ';
    }
    if (this.maximumStartDate != null && date.getTime() > this.maximumStartDate.getTime()) {
      classNames += 'disabled ';
    }
    if (date.getTime() === this.today.getTime()) {
      classNames += 'current ';
    }
    if (date.getTime() === this.visibleStartDate.getTime()) {
      classNames += 'start-date ';
    }
    if (date.getTime() === this.visibleEndDate.getTime()) {
      classNames += 'end-date ';
    }

    if (
      date.getTime() >= this.visibleStartDate.getTime() &&
      date.getTime() <= this.visibleEndDate.getTime()
    ) {
      classNames += 'range ';
    }

    return classNames;
  }

  get displayedDays() {
    return [
      ...this.prevMonthDaysPadding,
      ...this.visibleMonthDays,
      ...this.nextMonthPadding
    ];
  }
}
