import { render, staticRenderFns } from "./PageLoader.vue?vue&type=template&id=04107452&scoped=true&"
import script from "./PageLoader.vue?vue&type=script&lang=ts&"
export * from "./PageLoader.vue?vue&type=script&lang=ts&"
import style0 from "./PageLoader.vue?vue&type=style&index=0&id=04107452&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04107452",
  null
  
)

component.options.__file = "PageLoader.vue"
export default component.exports