



import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class SetState extends Vue {
  @Prop({})
  private dtos!: any;

  created(){ 
    if(this.dtos !== ""){
      let states : Array<{
        path : string,
        data : any
      }> = JSON.parse(this.dtos);

      for (let index = 0; index < states.length; index++) {
        const state = states[index];
        this.$store.dispatch(state.path, state.data);
      }
    }
  }

  mounted(){
    // destroy the vue listeners, etc
    this.$destroy();

    // remove the element from the DOM
    if(this.$el && this.$el.parentNode){
      this.$el.parentNode.removeChild(this.$el);
    }
  }
}
