





























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class TripFlowEditor extends Vue {
  @Prop({})
  private onSend!: Function;
  @Prop({})
  private tripFormItems!: Array<any>;
  onClick(e) {
    this.onSend(e);
  }
}
