

















import { Component, Prop, Vue } from "vue-property-decorator";
import { MemberBarDTOTranslations } from "generated/memberBar/member-bar-dto-translations";
import { SessionApiChangeToTmRequestDTO, constructDTO as constructRequest } from "generated/api/sessionApi/session-api-change-to-tm-request-dto";
import restClient from "utils/restClient";

@Component
export default class ChangeToTmModal extends Vue {
  @Prop({})
  private translations!: MemberBarDTOTranslations;

  @Prop({ default: constructRequest })
  private request!: SessionApiChangeToTmRequestDTO;

   onChangeToTmSubmit(){
    restClient
      .post(this.request.action)
      .then(response => {
        if (response.isTravelManager) {
          this.$store.dispatch("pageLoader/text", "Skickas till TM...");
          window.location.href = response.redirectUrl;
        } 
      });
  }
}
