import restClient from '../restClient';

class ForgotMemberIdService {
  constructor() {
    this.baseUrl = '/Umbraco/Api/ForgotMemberIdApi';
  }
  /* eslint-disable class-methods-use-this */
  /* eslint no-console: 0 */
  async createEmail(data) {
    try {
      const url = `${this.baseUrl}/CreateEmail`;
      const result = await restClient.post(url, data);
      return result;
    } catch (error) {
      console.log(error);
    }
    return null;
  }
}
export default new ForgotMemberIdService();
