








import {Component, Prop, Vue} from "vue-property-decorator";
import MemberAuthorizedLink from "../memberAuthorizedLink/MemberAuthorizedLink.vue";
import {MemberPrepaidFlowButtonGridControlDto} from "../../generated/member/prepaidFlowButtonGridControl/member-prepaid-flow-button-grid-control-dto";

@Component({
    components: {
        MemberAuthorizedLink
    }
})
export default class MemberPrepaidFlowButtonGridControl extends Vue {
    @Prop()
    private dto!: MemberPrepaidFlowButtonGridControlDto;

    get buttonClass(): string {
        if (this.dto.buttonClass !== null && this.dto.buttonClass !== '') {
            return this.dto.buttonClass;
        }
        return "btn-primary";
    }

    get bitsIframeWithProduct(): string {
        return (
            this.$store.getters["site/pageUrl"].bitFlowIframeLink +
            "?category=" +
            this.dto.relatedPrepaidProductId
        );
    }
}
