import restClient from '../restClient';
import { MemberApiNextBookingRequestDTO } from 'generated/api/member/member-api-next-booking-request-dto';
import { MemberApiNextBookingResponseDTO } from 'generated/api/member/member-api-next-booking-response-dto';
import { validateDTO } from "generated/api/travelerBooking/traveler-booking-api-booking-dto";


class MemberProfileService {
  async fetchNextBooking(request: MemberApiNextBookingRequestDTO): Promise<MemberApiNextBookingResponseDTO> {
    const response = await restClient.get(request.action, false) as MemberApiNextBookingResponseDTO;

    if (response.hasBooking) {
      response.nextBooking = validateDTO(response.nextBooking);
    }

    return response;
  }
}

export default new MemberProfileService();
