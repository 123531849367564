<template>
<input type="range" class="donate__form-slider" :name="name" :value="modelValue" v-on:change="onChange" v-on:input="onChange" min="0" :step="step" :max="max">
</template>
<script>
export default {
  props: {
    value: String,
    max: Number,
    name: String,
    step: {
      type: [Number, String],
      // default step 1% of max
      default: function() {
        return Math.ceil(this.max / 100);
      }
    },
    modelValue: {
      default: false
    }
  },
  model: {
    prop: 'modelValue',
    event: 'change'
  },
  methods: {
    onChange: function(e) {
      this.$emit('change', e.target.value);
    }
  }
};
</script>
