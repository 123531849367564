




































































import { Component, Prop, Vue, Model } from "vue-property-decorator";
import { AirSearchMemberProductsDTO } from "generated/airSearch/air-search-member-products-dto";
import { hhmm, yyyymmdd } from "utils/dateFormat";
import MemberAuthorizedLink from "../memberAuthorizedLink/MemberAuthorizedLink.vue";

@Component({
  components: {
    MemberAuthorizedLink
  }
})
export default class MemberProducts extends Vue {
  @Prop()
  protected dto!: AirSearchMemberProductsDTO;

  get bitsIframeWithProduct(): string {
    return this.$store.getters["site/pageUrl"].bitFlowIframeLink;
  }

  formatExpiryDate(date: string) {
    var parsedDate = new Date(date.split('T')[0]);
    return yyyymmdd(parsedDate, "-");
  }
}
