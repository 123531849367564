







































import { Component, Prop, Vue } from 'vue-property-decorator';
import Glide from '@glidejs/glide';
import { AirSearchCaruselDTO } from 'generated/airSearch';

@Component({})
export default class PromotionsCarousel extends Vue {
  @Prop({})
  private carouselContent!: Array<AirSearchCaruselDTO>;

  private currentIndex: number = 0;
  private glide = null;
  private timeoutHandler = null;

  mounted() {
    this.initGlide();
  }

  get currentContent(this: any) {
    return this.carouselContent[this.currentIndex].content;
  }

  get disabled(this: any) {
    if (this.glide === null) return true;
    // Disable glide when there is 1 or less slides
    if (this.carouselContent.length <= 1) {
      this.glide.disable();
      return true;
    } else {
      this.glide.enable();
      return false;
    }
  }

  // Initiates Glide (carousel slider lib)
  initGlide(this: any) {
    const that = this;
    this.glide = new Glide('.glide', {
      type: 'carousel',
      autoplay: false,
      animationDuration: 1500,
      hoverpause: false
    }).mount();

    this.glide.on('run', function(move) {
      that.currentIndex = that.glide.index;
      that.$store.dispatch('setCarouselIndex', that.currentIndex);
      that.nextSlideAfterDuration();
    });

    if (this.carouselContent.length <= 1) {
      this.glide.disable();
    }

    this.nextSlideAfterDuration();
  }

  // runs next slide after duration of current slide
  nextSlideAfterDuration(this: any) {
    window.clearTimeout(this.timeoutHandler);
    this.timeoutHandler = window.setTimeout(() => {
      this.glide.go('>');
    }, this.currentContent.duration != null ? this.currentContent.duration : 15000);
  }
}
