























































import { Component, Prop, Vue, Mixins } from "vue-property-decorator";

import FormClient from "utils/formClient";
import ModalsMixin from "utils/modals/modalsMixin";
import ValidationMixin from "utils/mixins/validationMixin";

import { PersonalInformationDTO } from "generated/member/personal-information-dto";
import { FrontendUserStoreDTO } from "generated/store/frontend-user-store-dto";
import { createFormData } from "generated/api/base-api-request-dto";
import SubmitButton from "utils/components/SubmitButton.vue";

import { CountryDTO } from "generated/country-dto";
import CountryService from "utils/services/countryService";

@Component({
  components: {
    SubmitButton
  }
})
export default class ContactInformation extends Mixins(
  ModalsMixin,
  ValidationMixin
) {
  @Prop({})
  protected dto!: PersonalInformationDTO;
  private formErrorMessage: string = "";
  private countries: CountryDTO[] = [];
  private formState = {
    ok: false,
    error: false,
    loading: false
  };

  private state = Object.assign(
    {},
    {
      addressLine1: this.siteUser.addressLine1,
      addressLine2: this.siteUser.addressLine2,
      email: this.siteUser.email,
      zip: this.siteUser.zip,
      city: this.siteUser.city,
      country: this.siteUser.country,
      language: this.siteUser.language
    }
  );

  get siteUser(): FrontendUserStoreDTO {
    return this.$store.getters["site/user"];
  }

  async mounted() {
    this.countries = await CountryService.fetch(this.dto.countryRequestUrl);
  }

  resetState() {
    this.state = Object.assign(
      {},
      {
        addressLine1: this.siteUser.addressLine1,
        addressLine2: this.siteUser.addressLine2,
        email: this.siteUser.email,
        zip: this.siteUser.zip,
        city: this.siteUser.city,
        country: this.siteUser.country,
        language: this.siteUser.language
      }
    );
  }
}
