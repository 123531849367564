























import { Component, Prop, Vue } from 'vue-property-decorator';
import Offer from './Offer.vue';
import OffersFilterPanel from './OffersFilterPanel.vue';
@Component({
  components: {
    Offer,
    OffersFilterPanel
  }
})
export default class OffersCompilation extends Vue {
  private offers = this.generateFakeOffers(10);
  private offersPerRow = 3;
  private filterSettings = {
    cities: [],
    categories: []
  };

  // TO-DO: remove and replace with dto
  generateFakeOffers(amount) {
    const ret: any = [];
    const cities = ['Malmö', 'Stockholm', 'Göteborg'];
    const categories = ['Mat & dryck', 'Hälsa', 'Nöje', 'Övrigt'];
    for (let i = 0; i < amount; i++) {
      ret.push({
        img: 'https://dummyimage.com/400x400&text=img',
        title: "title",
        description: "description",
        level: Math.floor(Math.random() * 3) + 1,
        link: '',
        city: cities[Math.floor(Math.random() * cities.length)],
        category: categories[Math.floor(Math.random() * categories.length)]
      });
    }
    return ret;
  }

  // returns all unique cities from offers
  get cities() {
    return [...new Set(this.offers.map(item => (item as any).city))];
  }

  // returns all unique categories from offers
  get categories() {
    return [...new Set(this.offers.map(item => (item as any).category))];
  }

  // filters
  filteredOffers() {
    let filteredList = [...this.offers];
    filteredList = filteredList.filter(item => {
      const { cities, categories } = this.filterSettings;
      // filter on cities
      if (cities && cities.length > 0) {
        if (!cities.includes(<never>item.city)) return false;
      }
      // filter on categories
      if (categories && categories.length > 0) {
        if (!categories.includes(<never>item.category)) return false;
      }
      return true;
    });

    return filteredList;
  }
}
