import restClient from '../restClient';
import { RouteListRequestDTO } from 'generated/api/route/route-list-request-dto';
import { RouteListResponseDTO } from 'generated/api/route/route-list-response-dto';

class RouteService {
  async fetch(request: RouteListRequestDTO): Promise<RouteListResponseDTO> {
    return await restClient.get(request.action) as RouteListResponseDTO;
  }
}

export default new RouteService();
