








































import { Component, Prop, Vue, Mixins, Watch } from "vue-property-decorator";
import { RegisterPointsLightDTO } from "generated/member/register-points-light-dto";

import { RouteItemResponseDTO } from "generated/api/route/route-item-response-dto";
import routeService from 'utils/services/routeService';
import FormClient from "utils/formClient";
import ValidationMixin from "utils/mixins/validationMixin";
import SubmitButton from "utils/components/SubmitButton.vue";
import RestClient from "utils/restClient";
import RetailMixin from "utils/mixins/retailMixin";

interface RegisterPointsLightState {
  surname: string;
  bookingReference: number;
}

@Component({
  components: {
    SubmitButton
  }
})
export default class RegisterPointsLight extends Mixins(ValidationMixin, RetailMixin) {
  @Prop({})
  protected dto!: RegisterPointsLightDTO;
  private formErrorMessage: string = "";
  private formOkMessage: string = "";  
  private formState = {
    ok: false,
    error: false,
    loading: false
  };

  private state: RegisterPointsLightState = {
    surname: "",
    bookingReference: <any>null
  };

  get showCurrentPage() {
    return this.$store.getters["memberPage/pageId"] === this.dto.pageId;
  }

  mounted() {
  }

  onRegistrationPointsLightFormSubmit(event) {
    const formData = new FormData();
      formData.append("surname", this.state.surname);
      formData.append("bookingReference", this.state.bookingReference.toString());
      FormClient.send(this.dto.form, formData)
        .then(response => {
          this.formState.loading = false;
          this.formState.ok = true;
          this.formOkMessage = response.message;

          if (!response.message) {
            this.sendRegisterPointsAction(response.bookingReference, response.bookingType, response.directFlight, response.cultureInfo);
          }
        })
        .catch(error => {
          this.formState.loading = false;
          this.formState.error = true;
          this.formErrorMessage = error.message;
        });
  }

  private sendRegisterPointsAction(bookingReference: string, bookingType: string, directFlight: string, cultureInfo: string) {
    this.$store.dispatch(
      "pageLoader/text",
      `${this.dto.translations.bookingTableForwardTo} ${this.dto.translations.bookingTableAddPoints}`
    );
    this.$store.dispatch("pageLoader/show");

    RestClient.post(this.dto.registerPointsAction, {
      surname: this.state.surname,
      bookingReference: bookingReference,
      bookingType: bookingType,
      directFlightOnly: directFlight,
      cultureInfo: cultureInfo,
    })
    .then(response => {
      response.isTripFlowEnabled = false;

      this.onTripFlow(response, form => {
        this.$store.dispatch(
          "pageLoader/text",
          `${this.dto.translations.bookingTableForwardTo} ${this.dto.translations.bookingTableAddPoints}`
        );
        this.$store.dispatch("pageLoader/show");
        form.submit();
      });
    })
    .catch(error => {
      this.$store.dispatch("pageLoader/hide");
    });
  }
}
