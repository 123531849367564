// import 'promise-polyfill/src/polyfill';
// import 'whatwg-fetch';

const CUSTOM_CULTURE_HEADER = "X-Umb-Culture";
const CUSTOM_PAGEID_HEADER = "X-Umb-PageId";

class RestClient {
  private headers: { [id: string]: string };

  constructor() {
    this.headers = {
      "Content-Type": "application/json",
      "X-Umb-Culture": "sv-SE",
      "X-Umb-PageId": "-1"
    };
    this.setCulture(document.body.getAttribute('lang'));
    this.setPageId(document.body.getAttribute('data-pageid'));
  }

  setCulture(culture: string | null) {
    if (culture !== null && this.headers[CUSTOM_CULTURE_HEADER] !== culture) {
      this.headers[CUSTOM_CULTURE_HEADER] = culture;
    }
  }

  setPageId(currentPageId: string | null) {
    if (currentPageId !== null && this.headers[CUSTOM_PAGEID_HEADER] !== currentPageId) {
      this.headers[CUSTOM_PAGEID_HEADER] = currentPageId;
    }
  }

  /* eslint-disable class-methods-use-this */
  handleError(request) {
    if (!request.ok) {
      const error = {
        ok: false,
        statusCode: request.status,
        message: request.statusText
      };
      throw error;
    }
  }

  /* eslint-disable class-methods-use-this */
  async handleSuccess(request) {
    var contentType = request.headers.get("content-type");
    if (contentType && contentType.includes("application/json")) {
      return await request.json();
    } else {
      return request.statusText;
    }
  }

  async get(route, cache = true) {
    if (!cache) {
      let url;

      try {
        url = new URL(route);
      } catch (_) {
        url = new URL(window.location.protocol + "//" + window.location.host + route);
      }

      const params = { _: new Date().valueOf() }
      Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));

      route = url.toString();
    }

    const request = await window.fetch(route, {
      headers: this.headers,
      method: "GET",
      credentials: "include",
    });
    this.handleError(request);
    return this.handleSuccess(request);
  }

  async post(route, data?) {
    const reqData: any = {
      headers: this.headers,
      method: "POST",
      credentials: "include",
    };

    if (data) {
      reqData.body = JSON.stringify(data);
    }

    const request = await window.fetch(route, reqData);
    this.handleError(request);
    return this.handleSuccess(request);
  }

  async put(route, data?) {
    const reqData: any = {
      headers: this.headers,
      method: "PUT",
      credentials: "include",
    };

    if (data) {
      reqData.body = JSON.stringify(data);
    }

    const request = await window.fetch(route, reqData);
    this.handleError(request);
    return this.handleSuccess(request);
  }

  async delete(route) {
    const request = await window.fetch(route, {
      headers: this.headers,
      method: "DELETE",
      credentials: "include",
    });
    this.handleError(request);
    return this.handleSuccess(request);
  }

  joinRoute(...paths: string[]) {
    return paths.join("/");
  }
}

export default new RestClient();
