































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { LoginDTO } from "generated/login/login-dto";
import { SessionApiLoginRequestDTO } from "generated/api/sessionApi/session-api-login-request-dto";
import { SignInAction } from "utils/store/actionTypes";
import { LoginDTOTranslations } from "generated/login/login-dto-translations";
import { FrontendPageLoginUrlStoreDTO } from "generated/store/frontend-page-login-url-store-dto";
import PasswordEye from "utils/components/PasswordEye.vue";

@Component({components:{PasswordEye}})
export default class LoginPage extends Vue {
  @Prop({})
  private registerAsPopup!: boolean;

  @Prop({})
  private request!: SessionApiLoginRequestDTO;

  @Prop({})
  private headerBackgroundUrl!: string;

  @Prop({})
  private description!: string;

  get links(): FrontendPageLoginUrlStoreDTO {
    return this.$store.getters["site/loginUrl"];
  }

  get translation(): LoginDTOTranslations {
    return this.$store.getters["site/translation"].login;
  }

  private state = {
    userName: <any>null,
    password: <any>null,
    keepMeSignedIn: this.request.keepMeSignedIn
  };

  private formErrorMessage: string = "";
  private formState = {
    ok: false,
    error: false,
    loading: false,
    usernameValid: false,
    usernameEmpty: false
  };

  get headerBackgroundStyle(): string {
    return this.headerBackgroundUrl != null && this.headerBackgroundUrl != ""
      ? `url('${this.headerBackgroundUrl}')`
      : "";
  }

  onLoginFormSubmit(event: any): void {
    this.formState.loading = true;
    this.formState.ok = false;
    this.formState.error = false;

    const signInPayload: SignInAction = {
      ...Object.assign(this.request, this.state),
      onSuccess: response => {
        this.formState.loading = false;
        this.formState.ok = true;
      },
      onError: error => {
        this.formState.loading = false;
        this.formState.error = true;
        this.formErrorMessage = error.message;
      }
    };

    this.$store.dispatch("request/signInMember", signInPayload);
  }

  registerMember(event: MouseEvent) {
    if (!this.registerAsPopup) {
      return;
    }
    event.preventDefault();
    this.$store.dispatch('modals/show', 'register-member-page-modal');
  }

  async validateUsernameInputOnFocus(event: FocusEvent) {
    if (event.currentTarget !== null) {
      const element = event.currentTarget as HTMLInputElement;

      if (element.getAttribute("required") && !element.value) {
        this.formState.usernameValid = true;
        this.formState.usernameEmpty = true;
      } else if (
        element.getAttribute("pattern") &&
        element.value &&
        !new RegExp(element.getAttribute("pattern") as string).test(
          element.value
        )
      ) {
        this.formState.usernameValid = false;
        this.formState.usernameEmpty = false;
      } else {
        this.formState.usernameValid = true;
        this.formState.usernameEmpty = false;
      }
    }
  }
}
