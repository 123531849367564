<template>
<div class="pagination">
  <div
    v-for="index in amtPages"
    class="pagination__page"
    :class="{'active': index === modelValue}"
    v-on:click="selectPage(index)"
    :key="`page_${index}`"
    >
    {{index}}
  </div>
</div>
</template>
<script>
export default {
  props: {
    totalRows: Number,
    perPage: {
      type: Number,
      default: 10
    },
    // currentPage (first page = 1)
    modelValue: {
      default: 1
    }
  },
  computed: {
    amtPages() {
      return Math.ceil(this.totalRows / this.perPage);
    }
  },
  model: {
    prop: 'modelValue',
    event: 'change'
  },
  methods: {
    selectPage: function(index) {
      this.$emit('change', index);
    }
  }
};
</script>