<template>
<div class="form-wizard__steps">
  <template v-for="(step, index) in steps">
    <div class="form-wizard__step" :key="`step_${index}`" :ref="`step_${index}`" v-on:click="select(index)">
      <div class="form-wizard__step-label">{{step}}</div>
    </div>
    <div class="form-wizard__line" v-if="index !== steps.length-1" :key="`line_${index}`" :ref="`line_${index}`"></div>
  </template>
</div>
</template>
<script>
export default {
  props: {
    select: Function,
    steps: Array,
    activeStep: {
      type: Number,
      default: 0
    }
  },
  mounted() {
    const stepElem = this.$refs[`step_${this.activeStep}`][0];
    stepElem.classList.add('--active');
  },
  watch: {
    activeStep: {
      handler(newIndex, oldIndex) {
        this.animateStep(oldIndex, newIndex);
      }
    }
  },
  methods: {
    animateStep: function(from, target) {
      // remove all active/animation classes
      Object.keys(this.$refs).forEach(key => {
        this.$refs[key][0].classList.remove(
          '--active',
          '--animate-left',
          '--animate-right'
        );
      });

      const steps = target - from;
      const isMovingToRight = steps > 0;
      const next = from + Math.sign(steps);

      const lineAnimationClass = isMovingToRight
        ? '--animate-right'
        : '--animate-left';

      // animate line
      const lineToAnimate = this.$refs[`line_${isMovingToRight ? from : next}`][0];
      lineToAnimate.classList.add(lineAnimationClass);

      // Set active class on step dot after delay
      setTimeout(() => {
        const stepElem = this.$refs[`step_${next}`][0];
        stepElem.classList.add('--active');
        if (next !== target) {
          this.animateStep(next, target);
        }
      }, 250);
    }
  }
};
</script>
