import restClient from '../restClient';
import { ProductProductsResponseDTO } from 'generated/api/product/product-products-response-dto';
import { ProductProductsRequestDTO } from 'generated/api/product/product-products-request-dto';
import { PrivatePrepaidItemDTO } from "generated/prepaid/private-prepaid-item-dto";
import { validateDTO } from 'generated/prepaid/prepaid-item-dto';

class ProductService {
  async fetchProducts(request: ProductProductsRequestDTO): Promise<ProductProductsResponseDTO> {
    const response = await restClient.get(request.action) as ProductProductsResponseDTO;
    response.organisationProducts = response.organisationProducts.map(validateDTO);
    response.privateProducts = response.privateProducts.map(it => validateDTO(it) as PrivatePrepaidItemDTO);
    return response;
  }
}

export default new ProductService();
