



































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { ForgotPasswordDTO } from "../../generated/forgotPassword";
import fps from '../../utils/services/forgotPasswordService';

@Component({})
export default class ForgotPassword extends Vue {
    @Prop({})
    private dto!: ForgotPasswordDTO;
    private currentView = '';
    private formLoading = false;
    private formCreateLinkModel = {
      UserName: '',
      FirstName: '',
      LastName: '',
      PasswordResetUrl: this.dto.passwordResetUrl,
    };
    private formUpdateProfileModel = {
      Token: '',
      NewPassword: '',
    };

    get disableUpdateButton() : boolean {
      if(this.formLoading) return true;
      return false;
    }

    mounted() {
      this.onLoadSetCurrentView();
    }

    async onLoadSetCurrentView() {
      if(this.hasTokenInUrl()){
        let valid = await fps.validateLink(this.formUpdateProfileModel.Token);
        if(valid){
          this.currentView = 'update-init';
        } else {
          this.currentView = 'update-invalid';
        }
      } else {
        this.currentView = 'create-init';
      }
    }

    hasTokenInUrl() {
      let url = new URL(window.location.href);
      let token = url.searchParams.get("token");
      if(token) {
        this.formUpdateProfileModel.Token = token;
        return true;
      } else {
        return false;
      }
    }

    async onForgotPasswordCreateLinkFormSubmit(event: any) {
      if(event.target[0].validity.valid &&
        event.target[1].validity.valid &&
        event.target[2].validity.valid) {
          this.formLoading = true;
          let result = await fps.createLink(this.formCreateLinkModel);
          this.formLoading = false;
          this.onHandleSubmitCreateLinkResult(result);
      }
    }

    onHandleSubmitCreateLinkResult(result: any) {
      switch(result) {
        case "invalid":
          this.currentView = 'create-invalid';
          break;
        case "success":
          this.currentView = 'create-success';
          break;
        case "error":
        default:
          this.currentView = 'create-error';
          break;
      }
    }

    async onForgotPasswordUpdateProfileFormSubmit(event: any) {
      if(event.target[0].validity.valid &&
        event.target[0].validity.valid) {
          this.formLoading = true;
          let result = await fps.updateProfile(this.formUpdateProfileModel.Token, this.formUpdateProfileModel);
          this.formLoading = false;
          this.onHandleSubmitUpdateProfileResult(result);
      }
    }

    onHandleSubmitUpdateProfileResult(result: any) {
      switch(result) {
        case 'invalid':
          this.currentView = 'update-invalid';
          break;
        case 'success':
          this.currentView = 'update-success';
          break;
        case 'error':
        default:
          this.currentView = 'update-error';
          break;
      }
    }
}
