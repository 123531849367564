



















































































































































































import { Component, Prop, Vue, Mixins } from "vue-property-decorator";

import FormClient from "utils/formClient";
import ModalsMixin from "utils/modals/modalsMixin";
import ValidationMixin from "utils/mixins/validationMixin";

import { PersonalInformationDTO } from "generated/member/personal-information-dto";
import { FrontendUserStoreDTO } from "generated/store/frontend-user-store-dto";
import { FrontendUtilStoreDTO } from "generated/store/frontend-util-store-dto";
import { createFormData } from "generated/api/base-api-request-dto";

import PhoneInput from "utils/components/PhoneInput.vue";
import SubmitButton from "utils/components/SubmitButton.vue";

import { CountryDTO } from "generated/country-dto";
import CountryService from "utils/services/countryService";

@Component({
  components: {
    PhoneInput,
    SubmitButton
  }
})
export default class ContactInformation extends Mixins(
  ModalsMixin,
  ValidationMixin
) {
  @Prop({})
  protected dto!: PersonalInformationDTO;
  private formErrorMessage: string = "";
  private countries: CountryDTO[] = [];
  private formState = {
    ok: false,
    error: false,
    loading: false
  };
  private state = Object.assign(
    {},
    {
      addressLine1: this.siteUser.addressLine1,
      addressLine2: this.siteUser.addressLine2,
      email: this.siteUser.email,
      zip: this.siteUser.zip,
      city: this.siteUser.city,
      country: this.siteUser.country,
      language: this.siteUser.language
    }
  );

  private phoneState: any = Object.assign(
    {},
    {
      countryCode: parseInt(this.siteUser.phoneCountryCode),
      phoneNumber: this.siteUser.phone
    }
  );

  get siteUser(): FrontendUserStoreDTO {
    return this.$store.getters["site/user"];
  }

  get siteUtils(): FrontendUtilStoreDTO {
    return this.$store.getters["site/util"];
  }

  async mounted() {
    this.countries = await CountryService.fetch(this.dto.countryRequestUrl);
  }

  resetState() {
    this.state = Object.assign(
      {},
      {
        addressLine1: this.siteUser.addressLine1,
        addressLine2: this.siteUser.addressLine2,
        email: this.siteUser.email,
        zip: this.siteUser.zip,
        city: this.siteUser.city,
        country: this.siteUser.country,
        language: this.siteUser.language
      }
    );
  }

  onContactInformationFormSubmit(event) {
    const firstInvalidElem = this.getFirstOrDefaultInvalidInput(event.target);

    if (firstInvalidElem) {
      this.focusOnInvalidInput(firstInvalidElem);
    } else {
      const formData = createFormData(
        event.target,
        this.dto.memberApiContactInformationRequest
      );

      formData.append("phone", this.phoneState.phoneNumber);
      formData.append(
        "phoneCountryCode",
        this.phoneState.countryCode.toString()
      );

      this.formState.loading = true;
      this.formState.ok = false;
      this.formState.error = false;

      FormClient.send(this.dto.memberApiContactInformationRequest, formData)
        .then(response => {
          this.formState.loading = false;
          this.formState.ok = true;
          this.$store.dispatch("site/setUserContactInformation", response);
        })
        .catch(error => {
          this.formState.loading = false;
          this.formState.error = true;
          this.formErrorMessage = error.message;
        });
    }
  }
}
