





































































































































































































import { Component, Prop, Mixins, Watch } from "vue-property-decorator";
import _get from "lodash/get";
import { AirSearchMemberDTO } from "generated/airSearch/air-search-member-dto";
import {
  AirSearchMemberDTOPrepaidProducts,
  validateAirSearchMemberDTOPrepaidProducts
} from "generated/airSearch/air-search-member-dto-prepaid-products";
import { AirSearchMemberFlowApiRequestDTO } from "generated/api/air-search-member-flow-api-request-dto";
import {
  AirSearchPresentDTO,
  validateAirSearchPresentDTO
} from "generated/airSearch";
import { AirSearchFlowApiRequestDTO } from "generated/api";

import { hhmm, yyyymmdd } from "utils/dateFormat";
import ModalsMixin from "utils/modals/modalsMixin";
import AirsearchMixin from "./AirsearchMixin";
import AirsearchTabs from "./AirsearchTabs.vue";
import {TacOrRc, TacValues} from 'src/components/tac/models';
import SelectAirportInput from "./SelectAirportInput.vue";
import ToggleSelector from "./ToggleSelector.vue";
import RangeDatepicker from "utils/datepicker-range/RangeDatepicker.vue";
import PassengersInput from "./PassengersInput.vue";
import TicketTypeInput from "./TicketTypeInput.vue";
import PrePaidTicketTypeInput from "./PrePaidTicketTypeInput.vue";
import UnattendedMinorModal from "./UnattendedMinorModal.vue";
import InvoiceReferenceModal from "./InvoiceReferenceModal.vue";
import TacReferenceModal from "./TacReferenceModal.vue";
import ChooseTacReferenceModal from "./ChooseTacReferenceModal.vue";
import OnlyOneClipLeftModal from "./OnlyOneClipLeftModal.vue";
import TicketInformation from "./TicketInformation.vue";

import EventBus from "utils/event-bus/event-bus";
import { AirSearchCampaignTicketTypeDTO } from "generated/airSearch/air-search-campaign-ticket-type-dto";
import { EVENT_MEMBER_ON_FOCUS_BONUS_TRIP } from "utils/event-bus/types";
import { attachObjectToFormData } from "utils/formHelper";
import { nameofFactory } from "utils/nameofFactory";
import {
  typeOfTravel,
  passengertypes,
  tickettypes,
  minPassengers,
  maxPassengers
} from "./tickettypes";

import CacheService from "utils/cache/cacheService";
import { PriceTypeRouteDTO } from "@/generated/airSearch/price-type-route-dto";
const nameOfAirSearchRequest = nameofFactory<
  AirSearchMemberFlowApiRequestDTO
>();

@Component({
  components: {
    OnlyOneClipLeftModal,
    ChooseTacReferenceModal,
    SelectAirportInput,
    ToggleSelector,
    RangeDatepicker,
    TicketTypeInput,
    PassengersInput,
    UnattendedMinorModal,
    PrePaidTicketTypeInput,
    InvoiceReferenceModal,
    TacReferenceModal,
    AirsearchTabs,
    TicketInformation
  }
})
export default class AirsearchMember extends Mixins(
  AirsearchMixin,
  ModalsMixin
) {
  @Prop({})
  protected dto!: AirSearchMemberDTO;

  private cacheService: CacheService = new CacheService();

  // If the user has added a Diners Company card the information saved in Resekontoinformation should be sent with the booking as Remarks in SO_GL
  private enableTacModal: boolean =
    this.dto.travelAccountAvailable && this.dto.isDinersAccount;

  // If the user has ticket type Agreement (Avtal) available on his company profile there should be a possibility for the user to add a reference to the booking / invoice.
  private enableInvoiceModal: boolean =
          this.dto.paymentReferenceAvailable;

  private hasTacAndInvoice: boolean =
          this.enableTacModal && this.enableInvoiceModal;

  public state: AirSearchPresentDTO = validateAirSearchPresentDTO(
    this.dto.airSearchPreset
  );

  private filteredPrivateTicketTypes = this.getFilterTicketTypes(
    this.dto.form.typeOfTravel, <any>{}
  );

  private isCooperative: boolean =
    this.dto.form.typeOfTravel == typeOfTravel.Corporate;

  private selectedProduct: AirSearchMemberDTOPrepaidProducts = this.typedDTOForm
    .selectedPrepaidProduct;

  private ticketTypeInputClass = "";
  private startDateChanged = false;
  private endDateChanged = false;
  private ticketTypeDropdownOpened = false;
  private payWithAVoucherDisabled = false;
  private enableAirportInputFocusEvent = true;
  private defaultMinimumDate: Date = new Date(2021, 4, 12, 0, 0, 0);

  get typedDTOForm(): AirSearchMemberFlowApiRequestDTO { return this.dto.form as AirSearchMemberFlowApiRequestDTO; }
  get prepaidProductNames() { return this.dto.prepaidProductNames; }
  get priceTypeName() { return _get(this.state, "ticketType.priceTypeName"); }
  get routesFrom() { return  _get(this.state, "to.destinationRoutes", this.dto.routes); }
  get routesTo() { return _get(this.state, "from.destinationRoutes", this.dto.routes); }

  get routeInformation() {
     const { to } = this.getAirports(this.dto.routes,  _get(this.state.from, "airport.airportId", -1), _get(this.state.to, "airport.airportId", -1));
     return _get(to, "airport.routeInformation", null)
  }

  get showPrepaidProducts() {
    const from = _get(this.state, "from.airport", undefined);
    const to = _get(this.state, "to.airport", undefined);
    const startDate = _get(this.state, "startDate", undefined);

    return (
      from !== undefined &&
      to !== undefined &&
      startDate !== undefined &&
      this.isCooperative
    );
  }

  get prepaidProducts(): AirSearchMemberDTOPrepaidProducts[] {
    if (!this.showPrepaidProducts) {
      this.cacheService.clear();
      return [];
    }

    const endDate =
      this.state.isRoundTrip && this.state.endDate !== null
        ? this.state.endDate
        : this.state.startDate;
    const cacheKey: string =
      this.state.from.airport.code.toString() +
      this.state.to.airport.code.toString() +
      this.state.startDate.toString() +
      endDate.toString();

    if (this.cacheService.hasValue(cacheKey)) {
      return this.cacheService.getValue<AirSearchMemberDTOPrepaidProducts[]>(
        cacheKey
      );
    } else {
      this.cacheService.clear();

      const mapSpread = (product: AirSearchMemberDTOPrepaidProducts) => ({
        ...product
      });

      const prepaidFilter = (product: AirSearchMemberDTOPrepaidProducts) => {
        const destinationValid =
          product.destinations.filter(
            dest =>
              this.state.from.airport.code === dest.from &&
              this.state.to.airport.code === dest.to
          ).length !== 0;

        const timeValid =
          product.startDate <= this.state.startDate &&
          product.expiryDate >= endDate;

        return destinationValid && timeValid;
      };

      return this.cacheService.setValue(
        cacheKey,
        Object.values(
          this.dto.prepaidProducts
            .map(mapSpread)
            .filter(prepaidFilter)
        )
      );
    }
  }

  get minPassengers(): number[] {
    const ticketTypeKey = _get(
      this.state.ticketType,
      "priceTypeName",
      "default"
    );
    return minPassengers[ticketTypeKey];
  }

  get maxPassengers(): number[] {
    const ticketTypeKey = _get(
      this.state.ticketType,
      "priceTypeName",
      "default"
    );
    return maxPassengers[ticketTypeKey];
  }

  get passengerOptions() {
    const { translations } = this.dto;
    return [
      {
        caption: translations.adultsCaption,
        subcaption: translations.adultsSubCaption
      },
      {
        caption: translations.childsCaption,
        subcaption: translations.childsSubCaption
      },
      {
        caption: translations.infantsCaption,
        subcaption: translations.infantsSubCaption
      }
    ];
  }

  onStartDateChanged(newDate: Date) {
    this.state.startDate = newDate;
    this.checkPayWithAVoucher();

    if (!this.state.isRoundTrip) {
      this.resetTicketType(this.state.ticketType);
    }

    this.openTicketTypeDropdown();
  }

  onEndDateChanged(newDate: Date) {
    this.state.endDate = newDate;
    this.checkPayWithAVoucher();
    this.resetTicketType(this.state.ticketType);
    this.openTicketTypeDropdown();
  }

  resetTicketType(ticketType: any): void {
    const prepaidProducts = this.prepaidProducts;

    if (this.showPrepaidProducts && prepaidProducts.length !== 0) {
      const selectedPrepaidProduct = this.prepaidProducts.find(
        t => t === this.selectedProduct
      );
      if (selectedPrepaidProduct) {
        this.selectedProduct = selectedPrepaidProduct;
      } else {
        this.selectedProduct = prepaidProducts[0];
      }
    } else {
      const selectedTicketType = this.filteredPrivateTicketTypes.find(
        t => t.priceType === this.state.ticketType.priceType
      );

      if (selectedTicketType) {
        this.state.ticketType = selectedTicketType;
      } else {
        this.state.ticketType = this.filteredPrivateTicketTypes[0];
      }
    }
  }

  onRoundTripChanged(newValue: boolean) {
    this.state.isRoundTrip = newValue;
    this.checkPayWithAVoucher();
  }

  checkPayWithAVoucher() {
    this.payWithAVoucherDisabled = this.checkPayWithAVoucherDisabled();
    if (this.state.payWithAVoucher && this.payWithAVoucherDisabled) {
      this.state.payWithAVoucher = false;
    }
  }

  checkPayWithAVoucherDisabled(): boolean {
    if (this.state.showPayWithAVoucher) {
      if (this.state.startDate && (this.state.startDate < this.state.payWithAVoucherMinimumDate || this.state.startDate > this.state.payWithAVoucherMaximumDate)) {
        return true;
      } else if (this.state.endDate && (this.state.endDate < this.state.payWithAVoucherMinimumDate || this.state.endDate > this.state.payWithAVoucherMaximumDate)) {
        return true;
      }
    }

    return false;
  }

  getFilterTicketTypes(typeOfTravel: number, priceTypes: PriceTypeRouteDTO[]) {
    var privateTicketTypes = this.dto.privateTicketTypes
    .filter(it => it.typeOfTravelFilter !== null && it.typeOfTravelFilter !== undefined
      ? it.typeOfTravelFilter === typeOfTravel
      : true
    ).filter(it => priceTypes !== null && priceTypes !== undefined && priceTypes.length > 0
      ? priceTypes.find(x => x.priceType == it.priceType) != null
      : true
    );

    if (this.state && this.state.to && this.state.to.campaignsTicketTypes) {
      privateTicketTypes = privateTicketTypes.concat(this.state.to.campaignsTicketTypes);
    }

    return privateTicketTypes;
  }

  created() {
    // Validate all properties
    this.dto.prepaidProducts = validateAirSearchMemberDTOPrepaidProducts(
            this.dto.prepaidProducts
    );
    this.resetTicketType({});
  }

  mounted() {
    EventBus.$on(EVENT_MEMBER_ON_FOCUS_BONUS_TRIP, () => {
      const setBonusTrip = () => {
        this.state.ticketType = <any>this.filteredPrivateTicketTypes.find(
          t => t.priceType === tickettypes.Bonus
        );;
      };

      if (this.isCooperative) {
        this.isCooperative = false;
      }
      this.dto.form.typeOfTravel = typeOfTravel.Private;
      this.ticketTypeInputClass = "animation-attention";

      // Set bonus trip after animation
      setTimeout(() => {
        setBonusTrip();
        this.ticketTypeInputClass = "";
      }, 800);
    });

    this.checkPayWithAVoucher();
  }

  @Watch("state.from")
  onNewStateFrom(this: any, newFromState) {
    this.updateFilteredTicketTypes(newFromState, this.state.to);
    this.resetTicketType();

    // Focus should be applied once
    if(this.enableAirportInputFocusEvent && this.$refs.toInput !== undefined) {
      if(newFromState.airport){
        this.state.to = <any>{};
        (this.$refs.toInput as any).$refs.input.focus();
        this.enableAirportInputFocusEvent = false;
      }
    }

    // If no destinationRoutes exist, we need to back to root
    const from = _get(newFromState, "airport", undefined);
    const to = _get(this.state, "to.airport", undefined);
    const toDestinationRoutesState = _get(this.state, "to.destinationRoutes", undefined);
    if(from == undefined && to != undefined && toDestinationRoutesState == undefined){
      const toRoutes = this.getAirport(this.dto.routes, to.airportId);

      if(toRoutes !== undefined){
        this.state.to = <any>toRoutes;
      }
    }
  }

  @Watch("state.to")
  onNewStateTo(this: any, newToState) {
    this.updateFilteredTicketTypes(this.state.from, newToState);
    this.resetTicketType();

    // Focus should be applied once
    if(this.enableAirportInputFocusEvent && this.$refs.fromInput !== undefined) {
      if(newToState.airport){
        this.state.from = <any>{};
        (this.$refs.fromInput as any).$refs.input.focus();
        this.enableAirportInputFocusEvent = false;
      }
    }

    // If no destinationRoutes exist, we need to back to root
    const from = _get(this.state, "from.airport", undefined);
    const to = _get(newToState, "airport", undefined);
    const fromDestinationRoutesState = _get(this.state, "from.destinationRoutes", undefined);
    if(to == undefined && from !== undefined && fromDestinationRoutesState == undefined){
      const fromRoutes = this.getAirport(this.dto.routes, from.airportId);

      if(fromRoutes !== undefined){
        this.state.from = fromRoutes;
      }
    }
  }

  updateFilteredTicketTypes(fromState, toState){
    const { to } = this.getAirports(this.dto.routes,  _get(fromState, "airport.airportId", -1), _get(toState, "airport.airportId", -1));
    this.filteredPrivateTicketTypes = this.getFilterTicketTypes(this.dto.form.typeOfTravel, _get(to, "airport.priceTypes", []));
  }

  @Watch("isCooperative")
  onIsCooperativeChange(this: any, value: boolean) {
    // toggle true == Corporate, false == Private
    this.dto.form.typeOfTravel = value ? typeOfTravel.Corporate : typeOfTravel.Private;
    this.updateFilteredTicketTypes();
    this.resetTicketType();
  }

  @Watch("selectedProduct")
  onSelectedProduct(this: any, product) {
    if (product) {
      this.state.ticketType = {
        caption: product.details,
        isCampaign: false,
        priceType: tickettypes[product.typeCode],
        priceTypeName: product.typeCode,
        subCaption: null
      };
    }
  }

  @Watch("state.ticketType")
  onTicketTypeChange(this: any, ticketType) {
    if (!this.isPrePaidProduct(ticketType)) {
      this.selectedProduct = undefined;
    }
  }

  openTicketTypeDropdown(){
    if(this.startDateChanged && this.endDateChanged && !this.ticketTypeDropdownOpened)
    {
      (this.$refs.tickettypeInput as any).$refs.input.focus();
      this.ticketTypeDropdownOpened = true;
    }
  }

  isPrePaidProduct(ticketType) {
    const prepaidProducts = this.prepaidProducts;

    if (!ticketType || prepaidProducts.length == 0 || !this.selectedProduct) {
      return false;
    }

    for (let index = 0; index < prepaidProducts.length; index++) {
      const prepaidProduct = prepaidProducts[index];
      if (
        ticketType.caption === prepaidProduct.details &&
        ticketType.priceType === tickettypes[prepaidProduct.typeCode] &&
        ticketType.priceTypeName === prepaidProduct.typeCode
      ) {
        return true;
      }
    }

    return false;
  }

  createAuthorizedFormData(
    request: AirSearchFlowApiRequestDTO,
    state: AirSearchPresentDTO
  ): FormData {
    const formData = this.createFormFromRoutesData(this.dto.routes, request, state);

    if (this.isPrePaidProduct(this.state.ticketType)) {
      attachObjectToFormData(
        this.selectedProduct,
        formData,
        "selectedPrepaidProduct"
      );
    }

    return formData;
  }

  handleSubmit(this: any, event) {
    switch (this.priceTypeName.toLowerCase()) {
      case "childbooking":
        this.showModal("unattended-minor");
        break;
      case "adt":
        if (this.isCooperative) {
          if (this.hasTacAndInvoice) {
            this.showModal("choose-tac-reference");
            break;
          }
          // If the user has added a Diners Company card the information saved in Resekontoinformation should be sent with the booking as Remarks in SO_GL
          else if (this.enableTacModal) {
            this.showModal("tac-reference");
            break;
          }
          // If the user has ticket type Agreement (Avtal) available on his company profile there should be a possibility for the user to add a reference to the booking / invoice.
          else if (this.enableInvoiceModal) {
            this.showModal("invoice-reference");
            break;
          }
        }
      case "tmp":
        if(this.selectedProduct && this.selectedProduct.ticketsLeft === 1 && this.state.isRoundTrip){
          this.showModal("only-one-clip-left-modal");
          break;
        }
        this.sendForm(
            this.dto.form,
            this.createAuthorizedFormData(this.dto.form, this.state)
        );
        break;
      default:
        this.sendForm(
          this.dto.form,
          this.createAuthorizedFormData(this.dto.form, this.state)
        );
        break;
    }
  }

  onSubmitUMModal(umForm: { [id: string]: string }) {
    const formData = this.createAuthorizedFormData(this.dto.form, this.state);

    for (var key in umForm) {
      // check if the property/key is defined in the object itself, not in parent
      if (umForm.hasOwnProperty(key)) {
        formData.append(key, umForm[key]);
      }
    }

    this.sendForm(this.dto.form, formData);
  }

  onReferenceSubmit(reference: string) {
    this.dto.paymentReference = reference;

    const formData = this.createAuthorizedFormData(this.dto.form, this.state);

    if (reference != "") {
      formData.append(nameOfAirSearchRequest("paymentReference"), reference);
    }

    this.sendForm(this.dto.form, formData);
  }
  onChooseTacReferenceSubmit(selectedOption: TacOrRc) {
    if(selectedOption == TacOrRc.Rc){
      this.showModal("invoice-reference");
    }
    else if(selectedOption == TacOrRc.Tac){
      this.showModal("tac-reference");
    }
  }

  onTacReferenceSubmit(tacValues: TacValues) {
    const formData = this.createAuthorizedFormData(this.dto.form, this.state);

    if (tacValues) {
      formData.append(
        "travelAccountInformation.orderNumber.value",
        tacValues.orderNumber
      );
      formData.append(
        "travelAccountInformation.projectNumber.value",
        tacValues.projectNumber
      );
      formData.append(
        "travelAccountInformation.employeeNumber.value",
        tacValues.employeeNumber
      );
      formData.append(
        "travelAccountInformation.department.value",
        tacValues.department
      );
      formData.append(
        "travelAccountInformation.costCenter.value",
        tacValues.costCenter
      );
      formData.append(
        "travelAccountInformation.customerNumber.value",
        tacValues.customerNumber
      );
      formData.append(
        "travelAccountInformation.travelOrderIdentity.value",
        tacValues.travelOrderIdentity
      );
      formData.append(
        "travelAccountInformation.freeText.value",
        tacValues.freeText
      );
    }

    this.sendForm(this.dto.form, formData);
  }

  onSubmit(this: any, e) {
    if (this.formIsValid(e)) {
      this.handleSubmit(e);
    }
  }

  formIsValid(this: any) {
    // validate on BaseAirsearch's refs
    const formErrors = this.validateInputs(this.$refs);
    return formErrors.length === 0;
  }

  onShowLowFareChange() {
    this.state.showLowFareCalendar = !this.state.showLowFareCalendar;
  }

  onPayWithAVoucherChange() {
    this.state.payWithAVoucher = !this.state.payWithAVoucher;
  }

  onCampaignTicketType(this: any, ticketType: AirSearchCampaignTicketTypeDTO) {
    if (ticketType.isCampaign) {
      const query = {
        isRoundTrip: this.state.isRoundTrip
      };

      if (this.state.startDate != null) {
        query["departureDate"] =
          yyyymmdd(this.state.startDate, "") + hhmm(this.state.startDate, "");
      }

      if (this.state.endDate != null) {
        query["returnDate"] =
          yyyymmdd(this.state.endDate, "") + hhmm(this.state.endDate, "");
      }

      if (this.state.from != null && this.state.from.airport != null) {
        query["departureAirportCode"] = this.state.from.airport.code;
      }

      if (this.state.to !== null && this.state.to.airport != null) {
        query["arrivalAirportCode"] = this.state.to.airport.code;
      }

      window.location.href =
        ticketType.campaignUrl +
        encodeURI(
          "?" +
            Object.entries(query)
              .map(k => {
                return k[0].toString() + "=" + k[1].toString();
              })
              .join("&")
        );
    }
  }

  onAirportsSwitch(){
    const { to, from } = this.getAirports(this.dto.routes,  _get(this.state.to, "airport.airportId", -1), _get(this.state.from, "airport.airportId", -1));

    if(to !== undefined && from !== undefined){
      this.state.from = from;
      this.state.to = to;

    }
  }
}
