











import { Component, Prop, Vue, Mixins } from "vue-property-decorator";

import Airsearch from "../airsearch/Airsearch.vue";
import AirsearchMember from "../airsearch/AirsearchMember.vue";
import TripFlowEditor from "../airsearch/TripFlowEditor.vue";
import RetailMixin from "utils/mixins/retailMixin";
import { BaseRetailResponseDTO } from "generated/api/base-retail-response-dto";
import {
   filterMobileCaruselItems
} from "generated/airSearch";
import { MiniAirSearchDTO } from "generated/airsearch/mini-air-search-dto";

@Component({
  components: {
    Airsearch,
    AirsearchMember,
    TripFlowEditor
  }
})
export default class MiniAirsearch extends Mixins(RetailMixin) {
  @Prop({})
  private dto!: MiniAirSearchDTO;

  sendToRetail(response: BaseRetailResponseDTO): void {
    this.onTripFlow(response, form => {
      this.$store.dispatch("pageLoader/text", "Vi är nästan klara för avgång!");
      this.$store.dispatch("pageLoader/show");
      form.submit();
    });
  }
}
