/**
 * Modal mixin - used to show, toggle, hide and get currently shown modal
 * @param {string} modalType - The modalType to use by default in the methods (not required)
 */

import { Component, Vue } from "vue-property-decorator";
import eventBus from "utils/event-bus/event-bus";
import { EVENT_MODAL_HIDDEN } from "utils/event-bus/types";

const getBodyEl = () => document.getElementsByTagName("body")[0];
const enableBodyScroll = () => getBodyEl().classList.remove("modal-open");
const preventBodyScroll = () => getBodyEl().classList.add("modal-open");

@Component
export default class ModalsMixin extends Vue {
  public defaultModalValue: string = "";

  get currentModal(this: any) {
    return this.$store.getters["modals/currentModal"];
  }

  isCurrentModal(this: any, modal = "") {
    return this.currentModal === modal;
  }

  showModal(this: any, modal = "") {
    preventBodyScroll();
    return this.$store.dispatch("modals/show", modal);
  }

  toggleModal(this: any, modal = "") {
    this.currentModal ? enableBodyScroll() : preventBodyScroll();
    return this.$store.dispatch("modals/toggle", modal);
  }

  hideModal(this: any) {
    enableBodyScroll();
    eventBus.$emit(EVENT_MODAL_HIDDEN);
    return this.$store.dispatch("modals/hide");
  }
}
