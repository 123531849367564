



import { Component, Prop, Vue, Mixins } from "vue-property-decorator";
import restClient from "utils/restClient";

import RetailMixin from "utils/mixins/retailMixin";
import TripFlowEditor from "../airsearch/TripFlowEditor.vue";
import { TravelerBoardingPassesBookingRequestDTO } from "generated/api/travelerBoardingPasses/traveler-boarding-passes-booking-request-dto";

@Component({
  components:{
    TripFlowEditor
    }
  }
)
export default class Erretail extends Mixins(RetailMixin) {
  @Prop()
  private dto!: TravelerBoardingPassesBookingRequestDTO;

  mounted() {
    this.sendAction(this.dto);
  }

  private sendAction(model: TravelerBoardingPassesBookingRequestDTO) {
    restClient.post(model.action, model).then(
      resp => {
        this.onTripFlow(resp, form => {
          this.$store.dispatch("pageLoader/text", "");
          this.$store.dispatch("pageLoader/show");
          form.submit();
        });
      },
      error => {
        console.log(error);
      }
    );
  }
}
