





import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { PrepaidFlowConfig } from "generated/member/prepaid-flow-config";
import { MessageTypes } from "generated/member/message-types";
import restClient from '../restClient';

interface IFrameState {
    loaded: boolean;
    disabled: boolean;
}
interface Data {
    productType: string,
    productName: string,
    statusCode: string
}
interface Message {
    messageId: string,
    messageType: string,
    data: Data
}

@Component
export default class IFrameComponent extends Vue {
    @Prop({
        default: () => {
            return {
                loaded: false,
                disabled: false
            };
        }
    })
    private state!: IFrameState;

    @Prop({
        required: true
    })
    private forceupdatesessionurl!: string;

    @Prop({
        required: true
    })
    private returnurl!: string;

    @Prop({
        required: true
    })
    private src!: string;

    @Prop({
        required: true
    })
    private config!: PrepaidFlowConfig;

    @Prop({
        default: () => "0"
    })
    private frameBorder!: string;

    @Prop({
    })
    private className!: string;

    private iframeElement!: HTMLIFrameElement;

    @Watch("state.loaded")
    onLoadedFrame(loaded) {

    }
    created(): void {
        this.initEvents();
    }

    destroy(): void {
        this.destroyEvents();
    }

    mounted(): void {
        this.initIframe();
    }

    initIframe(): void {
        this.state.disabled = true;
        let self = this;
        this.iframeElement =  document.createElement('iframe');

        if (this.className) {
            this.iframeElement.className += ' ' + this.className;
        }

        if (this.frameBorder) {
            this.iframeElement.frameBorder = this.frameBorder;
        }

        this.$el.appendChild(this.iframeElement);
        this.iframeElement.src = encodeURI(this.src);

        this.iframeElement.onload = function() {
            self.state.loaded = true;
            self.state.disabled = false;
        }
    };

    private initEvents() {
        const cfg = this.config;
        const forceSessionUrl = this.forceupdatesessionurl;

        window.addEventListener('message', receiveMessage, false);
        
        // TODO: This should be a private method, i.e  window.addEventListener('message', this.receiveMessage.bind(this), false);
        // Otherwise it will be trouble navigating to this.returnurl 
        function receiveMessage (event) {                
            if(typeof event.data === 'string' || event.data instanceof String)
            {
                // Then we know that it is a json object
                const deserializedEventData: Message =  JSON.parse(event.data);
                
                if(deserializedEventData == null){
                    console.warn("Wrong message format:", event.data);
                }
                else {
                    let foundMessageSecret = cfg.messageSecretses.find(
                        x=> x.messageId == deserializedEventData.messageId
                            && x.messageType == deserializedEventData.messageType);

                    if(foundMessageSecret){
                        
                        // Something has been done and we need to force update the session
                        if(foundMessageSecret.fetchANewSession){
                            restClient
                            .put(forceSessionUrl)
                            .then(response => {
                                if(!response.success){
                                    console.error("Cannot force update session");
                                }
                            })
                        }

                        //console.log(deserializedEventData);
                    }
                    else {
                        console.log("Wrong message params");
                    }
                }
                 
            } else {
                console.warn("Ignoring data", event.data);
            }
        }
    }

    private destroyEvents() {
        window.removeEventListener('message', removeMessage);
        function removeMessage (event) {
            console.log('deleted')
        }
    }
}
