<template>
    <transition name="fade">
      <div v-show="isCurrentModal(id)" class="modal-backdrop" v-on:click="hideCurrentModal()">
        <section :class="innerClass"  v-on:click.stop>
          <i class="modal__close fa fa-close" aria-label="close modal" v-on:click="hideCurrentModal()"></i>
          <slot></slot>
        </section>
      </div>
    </transition>
</template>
<script>
import ModalsMixin from "utils/modals/modalsMixin";

export default {
  mixins: [ModalsMixin],
  props: {
    id: {
      type: String,
      required: true
    },
    innerClass: {
        type:String,
        default: "modal"
    },
    onHide: {
      type: Function,
      required: false
    },
      onHidden: {
          type: Function,
          required: false
      },
    onShow: {
      type: Function,
      required: false
    },
    onToggle: {
      type: Function,
      required: false
    }
  },
  methods: {
    // Checks if @modal is currently shown
    isCurrentModal(modal = this.id) {
      if(this.$store.getters["modals/currentModal"] === modal) {
          if (this.onShow) {
              this.onShow();
          }
          return true;
      }
      return false;
    },
    showCurrentModal(modal = this.id) {
      if (this.onShow) {
        this.onShow();
      }
      this.showModal(modal);
    },
    toggleCurrentModal(modal = this.id) {
      if (this.onToggle) {
        this.onToggle();
      }
      this.toggleModal(modal);
    },
    hideCurrentModal() {
      if (this.onHide) {
        this.onHide();
      }
      this.hideModal().then(()=> {
          if (this.onHide) {
              this.onHide();
          }
      });
    }
  }
};
</script>
