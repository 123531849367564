

















































import { Component, Prop, Vue } from 'vue-property-decorator';

import { RegisterDepartmentDTOTranslations } from 'generated/registerDepartment/register-department-dto-translations';
import { SessionApiLoginRequestDTO, constructSessionApiLoginRequestDTO } from "generated/api/sessionApi/session-api-login-request-dto";

import FormClient from 'utils/formClient';
import PasswordEye from "utils/components/PasswordEye.vue";
import { createFormData } from "generated/api/base-api-request-dto";

@Component({components:{PasswordEye}})
export default class RegisterDepartmentLogin extends Vue {  
  @Prop({})
  private translations!: RegisterDepartmentDTOTranslations;

  @Prop({ default: constructSessionApiLoginRequestDTO })
  private loginRequest!: SessionApiLoginRequestDTO;

  @Prop({ default : () => true })
  private showCreateMember!: boolean;

  @Prop()
  private onCreateMember!: Function;

  @Prop()
  private onLoginMember!: Function;

  private password:string='';
  private formErrorMessage: string = '';
  private formState = {
    ok: false,
    error: false,
    loading: false
  };

  onLoginFormSubmit(event: any): void {
    this.formState.loading = true;
    this.formState.ok = false;
    this.formState.error = false;
    
    const formData = createFormData(event.target, this.loginRequest);

    this.$store.dispatch("pageLoader/text", "Vänta ett ögonblick...");
    this.$store.dispatch("pageLoader/show");

    FormClient
      .send(this.loginRequest, formData)
      .then((response) => {
        this.formState.loading = false;
        this.formState.error = false;
        this.formState.ok = true;

        this.$store.dispatch("pageLoader/text", "Loggas in...");
        this.onLoginMember(response);
      })
      .catch(error => {
        this.$store.dispatch("pageLoader/hide");
        this.formState.loading = false;
        this.formState.error = true;
        this.formErrorMessage = error.message;
      });
  }
}
