






















import { Component, Prop, Mixins } from "vue-property-decorator";
import ModalsMixin from "utils/modals/modalsMixin";
import ValidationMixin from "utils/mixins/validationMixin";

import {
  PersonalInformationDTO,
  validateDTO
} from "generated/member/personal-information-dto";

@Component({})
export default class BankInformation extends Mixins(
  ModalsMixin,
  ValidationMixin
) {
  @Prop({})
  protected dto: PersonalInformationDTO = validateDTO(this.dto);
  private formErrorMessage: string = "";
  private formState = {
    ok: false,
    error: false,
    loading: false
  };
}
