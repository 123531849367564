
















import { Component, Prop, Vue } from "vue-property-decorator";
import { AirSearchTabDTO } from "generated/airSearch";
import { A_TARGET } from "../../constant";

@Component({})
export default class AirsearchTabs extends Vue {
  @Prop()
  protected tabs!: AirSearchTabDTO[];

  renderTarget(tab: AirSearchTabDTO) {
    return tab.newWindow ? A_TARGET.BLANK : A_TARGET.SELF;
  }
}
