import { Module, GetterTree, ActionTree, MutationTree } from "vuex";
import { SiteState, RootState, } from "../types";
import initials from "../initials";

const namespaced: boolean = true;

export const state: SiteState = {
  general: initials.createFrontendDefaultGeneral(),
  user: initials.createFrontendDefaultUser(),
  pageUrl: initials.createFrontendDefaultPageUrl(),
  translation: initials.createFrontendDefaultTranslations(),
  util : initials.createDefaultUtil()
};

const getters: GetterTree<SiteState, RootState> = {
  pageUrl: state => state.pageUrl,
  loginUrl: state => state.pageUrl.loginSection,
  user: state => state.user,
  userTacList: state => state.user.tacList,
  general: state => state.general,
  translation: state => state.translation,
  util: state => state.util
};

const actions: ActionTree<SiteState, RootState> = {
  setPageUrl({ commit }, value) {
    commit('setPageUrl', value);
  },
  setGeneral({ commit }, value) {
    commit('setGeneral', value);
  },
  setUser({ commit }, value) {
    commit('setUser', value);
  },
  setUserTac({ commit }, value) {
    commit('setUserTac', value);
  },
  setUserAwardPoints({ commit }, value) {
    commit('setUserAwardPoints', value);
  },
  setUserContactInformation({ commit }, value) {
    commit('setUserContactInformation', value);
  },
  setTranslation({ commit }, value) {
    commit('setTranslation', value);
  },
  setUtil({ commit }, value) {
    commit('setUtil', value);
  },
};

const mutations: MutationTree<SiteState> = {
  setPageUrl(state, value) {
    state.pageUrl = value;
  },
  setGeneral(state, value) {
    state.general = value;
  },
  setUser(state, value) {
    state.user = value;
  },
  setUserTac(state, value) {
    state.user.tacList = value;
  },
  setUserAwardPoints(state, value) {
    state.user.awardPoints = value.awardPoints;
  },
  setUserContactInformation(state, value) {
    state.user.addressLine1 = value.addressLine1;
    state.user.addressLine2 = value.addressLine2;
    state.user.city = value.city;
    state.user.country = value.country;
    state.user.email = value.email;
    state.user.language = value.language;
    state.user.phone = value.phone;
    state.user.phoneCountryCode = value.phoneCountryCode;
    state.user.zip = value.zip;
    state.user.displayCountry = value.displayCountry;
    state.user.displayLanguage = value.displayLanguage;
  },
  setTranslation(state, value) {
    state.translation = value;
  },
  setUtil(state, value) {
    state.util = value;
  },
};

export const module: Module<SiteState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
