































































































import { Component, Prop, Vue } from "vue-property-decorator";
import throttle from "lodash/throttle";
import stickybits from "stickybits";

import { DetailDTO } from "generated/detail/detail-dto";
import { DetailCleanDTO } from "generated/detail/detail-clean-dto";

@Component({})
export default class DetailPage extends Vue {
  private scrollSections!: NodeListOf<HTMLElement>;
  private activeSection = {};
  private isDesktop = true;
private currentUrl = window.location.pathname;
  @Prop({})
  private dto!: DetailDTO | DetailCleanDTO;

  get headerBackgroundStyle(): string {
    if ((<DetailDTO>this.dto).hasHeaderBackground) {
      return `url('${(<DetailDTO>this.dto).headerBackgroundUrl}')`;
    } else {
      return "";
    }
  }

  mounted() {
    this.scrollSections = (document as any)
      .getElementById("scrollable-content")
      .querySelectorAll("article");
    this.activeSection = this.scrollSections[0];

    this.isDesktop = window.innerWidth > 991;

    if (this.isDesktop) {
      window.addEventListener("scroll", throttle(this.handleScroll, 100));

      // polyfill for position sticky
      stickybits("#sticky-nav", { stickyBitStickyOffset: 100 });
    }
  }

  handleScroll() {
    const currentScrollTop =
      (document as any).documentElement.scrollTop || document.body.scrollTop;

    for (let i = 0; i < this.scrollSections.length; i += 1) {
      const scrollSection = this.scrollSections[i];
      const sectionOffsetFromBottom =
        scrollSection.offsetTop + scrollSection.offsetHeight - 100;
      if (currentScrollTop <= sectionOffsetFromBottom) {
        this.activeSection = scrollSection;
        break;
      }
    }
  }

  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  }
}
