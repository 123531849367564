import { DEFAULT_CULTURE } from '../constant';

class DateHelper {
  private _culture: string = DEFAULT_CULTURE;
  private _weekDays!: Array<string>;
  private _longWeekdays!: Array<string>;
  private _months!: Array<string>;
  private _longMonths!: Array<string>;

  private _weekDaysLocalized = [
    { 
      key: 'sv-SE',
      shortValue: ["mån", "tis", "ons", "tors", "fre", "lör", "sön"], 
      longValue: ["måndag", "tisdag", "onsdag", "torsdag", "fredag", "lördag", "söndag"]
    }, 
    { 
      key: 'en-US',
      shortValue: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      longValue: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]
    }
  ];

  constructor() {
    this.setCulture(document.body.getAttribute('lang'));
  }

  /**
   * Return the short weekday names, starting on sunday
   */
  get weekDayNames(): Array<string> {
    if (this._weekDays == null) {
      this._weekDays = this.calculateWeekDayNames('short', true);
    }
    return this._weekDays;
  }

  /**
  * Return the long weekday names, starting on sunday
  */
  get longWeekDayNames(): Array<string> {
    if (this._longWeekdays == null) {
      this._longWeekdays = this.calculateWeekDayNames('long', false);
    }
    return this._longWeekdays;
  }

  get monthNames(): Array<string> {
    if (this._months == null) {
      this._months = this.calculateMonthNames('short', true);
    }
    return this._months;
  }

  get longMonthNames(): Array<string> {
    if (this._longMonths == null) {
      this._longMonths = this.calculateMonthNames('long', false);
    }
    return this._longMonths;
  }

  getDifferenceInMonths(startDate: Date, endDate: Date): number {
    let months: number;
    months = (endDate.getFullYear() - startDate.getFullYear()) * 12;
    months -= startDate.getMonth() + 1;
    months += endDate.getMonth();
    return months <= 0 ? 0 : months;
  }

  getMonth(monthNumber: number): string {
    if (monthNumber < 0 || monthNumber > this.monthNames.length) return "";
    return this.monthNames[monthNumber];
  }

  getLongMonth(monthNumber: number): string {
    if (monthNumber < 0 || monthNumber > this.longMonthNames.length) return "";
    return this.longMonthNames[monthNumber];
  }

  getWeekday(weekdayNumber: number): string {
    if (weekdayNumber < 0 || weekdayNumber > this.weekDayNames.length) return "";
    return this.weekDayNames[weekdayNumber];
  }

  getLongWeekday(weekdayNumber: number): string {
    if (weekdayNumber < 0 || weekdayNumber > this.longWeekDayNames.length) return "";
    return this.longWeekDayNames[weekdayNumber];
  }

  getMonthOffset(currentDate : Date | undefined, month : number) : Date
  {
    let cpyDate = this.getFirstDayInMonth(this.copyDate(currentDate));
    cpyDate.setMonth(cpyDate.getMonth() + month);
    return cpyDate;
  }

  getFirstDayInMonth(currentDate : Date) : Date
  {
    let cpyDate = this.copyDate(currentDate);
    cpyDate.setDate(1); // set to first day in month
    cpyDate.setHours(0, 0, 0, 0);
    return cpyDate;
  }

  setCulture(culture: string | null) {
    if (culture !== null && this._culture !== culture) {
      this._culture = culture;
      this._weekDays = this.calculateWeekDayNames('short', true);
      this._longWeekdays = this.calculateWeekDayNames('long', false);
      this._months = this.calculateMonthNames('short', true);
      this._longMonths = this.calculateMonthNames('long', false);
    }
  }
  
  getStandardizedDateAsString(date: Date) {
    const dateAsString = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    return dateAsString;
  }

  getNumberOfWeek(dateInput: Date) {
    // Copy date so don't modify original
    const date = new Date(Date.UTC(dateInput.getFullYear(), dateInput.getMonth(), dateInput.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    date.setUTCDate(date.getUTCDate() + 4 - (date.getUTCDay()||7));
    // Get first day of year
    const yearStart = new Date(Date.UTC(date.getUTCFullYear(), 0, 1));
    // Calculate full weeks to nearest Thursday
    const weekNo = Math.ceil((((Number(date) - Number(yearStart)) / 86400000) + 1) / 7);
    // Return array of year and week number
    return weekNo;
  }

  private copyDate(currentDate : Date | undefined) : Date {
    return currentDate != null && currentDate != undefined ? new Date(currentDate) : new Date();
  }

  private calculateWeekDayNames(weekdayFormat: string, capitalize: boolean): Array<string> {
    let weekdays: Array<string> = [];
    let localizedWeekdays = this._weekDaysLocalized.find(x => x.key == this._culture);

    if (!localizedWeekdays) {
      let defaultLocalizedWeekdays = this._weekDaysLocalized.find(x => x.key == DEFAULT_CULTURE);

      if (defaultLocalizedWeekdays) {
        localizedWeekdays = defaultLocalizedWeekdays;
      }
    }

    if (localizedWeekdays)
    {
      if (weekdayFormat == 'short') {
        weekdays = localizedWeekdays.shortValue;
      } else if (weekdayFormat == 'long') {
        weekdays = localizedWeekdays.longValue;
      }

      if (capitalize) {
        weekdays.forEach((value, index) => {
          weekdays[index] = this.capitalizeFirstLetter(value);
        })
      }

      return weekdays;
    }

    return weekdays;
  }

  private calculateMonthNames(monthFormat: string, capitalize: boolean): Array<string> {
    let months: Array<string> = [];
    let date = new Date('2018-01-01'); // begin on January

    for (let i = 0; i < 12; i++) {
      if (capitalize) {
        months.push(this.capitalizeFirstLetter(date.toLocaleDateString(this._culture, { month: monthFormat })));
      } else {
        months.push(date.toLocaleDateString(this._culture, { month: monthFormat }));
      }
      date.setMonth(date.getMonth() + 1);
    }

    return months;
  }

  private capitalizeFirstLetter(str: String) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }
}

export default new DateHelper();
