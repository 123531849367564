

















import { Component, Prop, Vue, Mixins, Watch } from "vue-property-decorator";
import ModalsMixin from "utils/modals/modalsMixin";
import { AirSearchMemberBonusDTO } from "generated/airSearch/air-search-member-bonus-dto";
import { FrontendUserStoreDTO } from 'generated/store/frontend-user-store-dto';
import EventBus from "utils/event-bus/event-bus";
import { EVENT_MEMBER_ON_FOCUS_BONUS_TRIP } from "utils/event-bus/types";

@Component({})
export default class MemberBonus extends Mixins(ModalsMixin) {
  @Prop()
  protected dto!: AirSearchMemberBonusDTO;

  get siteUser(): FrontendUserStoreDTO {
    return this.$store.getters["site/user"];
  }

  getExpiryPointsString(expiryDateTranslation: string, expiryPointsDate: Date, expiryPointsValue: string): string {
    return expiryDateTranslation.replace('{0}', expiryPointsValue).replace('{1}', expiryPointsDate.toString().split('T')[0]);
  }

  onBonusTrip() {
    EventBus.$emit(EVENT_MEMBER_ON_FOCUS_BONUS_TRIP);
  }

  showSubpage(pageId) {
    this.$store.dispatch("memberPage/setPageId", pageId);
  }
}
