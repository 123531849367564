




















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import Datepicker from './Datepicker.vue';

import { AirSearchTranslationsDTO } from 'generated/airSearch';

@Component({
  components: {
    Datepicker
  }
})
export default class RangeDatepicker extends Vue {
  @Prop({})
  private translations!: AirSearchTranslationsDTO;

  @Prop({ default: new Date() })
  private startDate!: Date;

  @Prop({ default: new Date() })
  private endDate!: Date;

  @Prop({})
  private minimumStartDate!: Date;

  @Prop({})
  private maximumStartDate!: Date;

  @Prop({ default: true })
  private isRoundTrip!: boolean;

  @Prop({ default: false })
  private hideToDatepicker!: boolean;

  @Prop()
  private onStartChanged!: Function;

  @Prop()
  private onEndChanged!: Function;

  @Prop()
  private onRoundTripChanged!: Function;

  private startDateHasFocus = false;
  private endDateHasFocus = false;

  // These are used to avoid mutating props directly
  private privateStartDate = new Date(this.startDate);
  private privateEndDate = (this.isRoundTrip) ? new Date(this.endDate) : null;
  private validationErrors = <any>[];

  @Watch('startDate')
  onDateFromChanged(this: any, newDateFrom) {
    this.$parent.startDateChanged = true;
    if (!newDateFrom) return;
    const timeFrom = this.startDate ? this.startDate.getTime() : 0;
    const timeTo = this.endDate ? this.endDate.getTime() : 0;
    if(!this.isRoundTrip)
    {
      this.$parent.endDateChanged = true;
    }
    if (this.isRoundTrip && timeFrom >= timeTo) {
      this.privateEndDate = null;
      this.$parent.endDateChanged = false;
      this.$refs.endDate.focus();
    } else {
      this.$refs.startDate.blur();
      this.$parent.openTicketTypeDropdown();
    }

    this.validate();
  }

  @Watch('endDate')
  onDateToChanged(this: any, newEndDate) {
    this.$parent.endDateChanged = true;
    if (!newEndDate) return;
    const timeFrom = this.startDate ? this.startDate.getTime() : 0;
    const timeTo = this.endDate ? this.endDate.getTime() : 0;

    if(timeFrom == 0){
      this.privateStartDate = null;
      this.$refs.startDate.focus();
    } else if (timeFrom > timeTo) {
      this.privateStartDate = null;
      this.$refs.startDate.focus();
    } else {
      this.$refs.endDate.blur();
    }

    this.validate();
  }

  @Watch('isRoundTrip')
  onIsRoundTripChanged(this: any, isRoundTrip) {
    if (!isRoundTrip) {
      this.$parent.endDateChanged = true;
      this.privateEndDate = null;
      this.validate();
    } else {
      this.$parent.endDateChanged = false;
      this.privateEndDate = this.privateStartDate;
    }
    this.$parent.openTicketTypeDropdown();
  }

  @Watch('privateStartDate')
  onStartDateChanged(this: any, newDate) {
    this.onStartChanged(newDate);
  }

  @Watch('privateEndDate')
  onEndDateChanged(this: any, newDate) {
    this.onEndChanged(newDate);
  }

  onClickedDateTo(this: any) {
    if (!this.isRoundTrip) {
      this.onRoundTripChanged(true);
      window.setTimeout(this.$refs.endDate.focus, 10); // force focus
    }
  }

  onFocusChangedDateFrom(hasFocus) {
    this.startDateHasFocus = hasFocus;
  }

  onFocusChangedDateTo(hasFocus) {
    this.endDateHasFocus = hasFocus;
  }

  validate() {
    this.validationErrors = [];

    if (!this.startDate) {
      this.validationErrors.push(this.translations.selectDepartureDate);
    }

    if (this.isRoundTrip && !this.endDate) {
      this.validationErrors.push(this.translations.selectReturnDate);
    }

    return this.validationErrors;
  }

  get isDateFromInactive(this: any): boolean {
    return this.endDateHasFocus;
  }

  get isDateToInactive(this: any): boolean {
    return this.startDateHasFocus || !this.isRoundTrip;
  }
}
