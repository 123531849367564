






import { Component, Prop, Vue } from "vue-property-decorator";
import { MyBookingsDTO } from "generated/myBookings/my-bookings-dto";
import { MyBookingsEntryDTO } from "generated/myBookings/my-bookings-entry-dto";
import MyBookings from "./MyBookings.vue";
import MyBookingsEntry from "./MyBookingsEntry/MyBookingsEntry.vue";
import { TravelerBookingApiBookingDTO } from "generated/api/travelerBooking";

@Component({
  components:
  {
    MyBookingsEntry,
    MyBookings
  }
})
export default class MyBookingsSection extends Vue {
  @Prop({ default: () => true })
  private hasvalidbookingref!: boolean;

  @Prop({})
  private dto!: MyBookingsDTO;

  @Prop({})
  private entrydto!: MyBookingsEntryDTO;
}
