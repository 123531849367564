import { render, staticRenderFns } from "./ScrollToTop.vue?vue&type=template&id=641fd9ab&scoped=true&"
import script from "./ScrollToTop.vue?vue&type=script&lang=ts&"
export * from "./ScrollToTop.vue?vue&type=script&lang=ts&"
import style0 from "./ScrollToTop.vue?vue&type=style&index=0&id=641fd9ab&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "641fd9ab",
  null
  
)

component.options.__file = "ScrollToTop.vue"
export default component.exports