





























import { Component, Prop, Vue, Mixins } from "vue-property-decorator";
import ModalsMixin from "utils/modals/modalsMixin";
import { PrivatePrepaidItemDTO } from "generated/prepaid/private-prepaid-item-dto";
import { MyProductsDTOTranslations } from "generated/member/my-products-dto-translations";

@Component
export default class PrivateProductItem extends Mixins(ModalsMixin) {
  @Prop({ default: () => true })
  protected disableBuyTickets!: boolean;
  @Prop({ required: true })
  protected dto!: PrivatePrepaidItemDTO;
  @Prop({ required: true })
  protected translations!: MyProductsDTOTranslations;

  get ticketType(): string {
    return this.dto.category == 2
      ? this.translations.annualPass
      : this.translations.punchTicket;
  }

  get hasTickets() : boolean {
    return this.dto.ticketsLeft != -1;
  }

  get buttonText() : string {
    this.dto.bitsLink.caption
    return this.dto.category == 2
      ? this.translations.buyNew
      : this.translations.refill;
  }

  get bitsIframeWithProduct(): string {
    return this.$store.getters["site/pageUrl"].bitFlowIframeLink
    + "?category=" + this.dto.category;
  }
}
