














import { Component, Prop, Vue } from "vue-property-decorator";
import { TacDTOTranslations } from "generated/member/tac-dto-translations";
import { TacItem } from "./models";

@Component
export default class TacList extends Vue {
  @Prop({ required: true })
  protected translations!: TacDTOTranslations;
  @Prop({ required : true })
  protected readonlyTacList!: TacItem[];
  @Prop({ default: () => true })
  protected showEmpty!: boolean;  

  get tacList(): TacItem[] {
    const storeList = this.readonlyTacList;
    let filteredList: TacItem[] = [];

    for (let index = 0; index < storeList.length; index++) {
      let tacItem = storeList[index];

      if (this.isTacValid(tacItem)) {
        filteredList.push({
          id: tacItem.id,
          label: this.translations[tacItem.label],
          value: tacItem.value
        });
      }
    }

    return filteredList;
  }

  isTacValid(item: TacItem): boolean {
    if (this.showEmpty) return true;
    if (!item) return false;
    if (!item.value) return false;
    if (item.value == "") return false;
    return true;
  }
}
