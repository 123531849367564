












































import { Component, Prop, Vue, Model, Watch } from 'vue-property-decorator';
import _get from "lodash/get";
import ieDetector from  'utils/ieDetector';
import { AirSearchPropertyValidationTranslationsDTO } from 'generated/validation/air-search-property-validation-translations-dto';

@Component({})
export default class SelectAirportInput extends Vue {
  @Model('select-airport', { type: Object })
  selectedAirport!: any;

  @Prop({})
  private routes!: Array<any>;

  @Prop({ default: '' })
  private inputName!: String;

  @Prop({})
  private inputPlaceholderName!: String;

  @Prop({})
  private inputClass!: String;

  @Prop({})
  private validationTranslations!: AirSearchPropertyValidationTranslationsDTO;

  private showClearButton = false;
  private validationErrors = <any>[];
  private airportTextInput: String = _get(
    this.selectedAirport,
    'airport.displayName',
    ''
  );

  get isIEBrowser() : boolean
  {
    return ieDetector.isIEBrowser;
  } 

  @Watch('selectedAirport')
  onPropertyChanged(airport) {
    const airportName = _get(airport, 'airport.displayName', '');
    this.airportTextInput = airportName;

    if (this.validationErrors.length > 0) {
      this.validate();     
    }
  }

  onInputMousedown(event) {
    // close dropdown if already open
    const isElemFocused = document.activeElement === event.target;
    if (isElemFocused) {
      event.target.blur();
      event.preventDefault();
    }
  }

  onClear(event){
    this.select({});
    (this.$refs.input as any).focus();
  }

  select(route) {
    this.showClearButton = route != null && route != undefined && route.airport;
    this.$emit('select-airport', route);
  }

  selectAndClose(this: any, route) {
    this.select(route);
    this.$refs.input.blur();
  }

  onInput(event) {
    this.airportTextInput = event.target.value;
  }

  onFocus(this: any) {
    this.select({});
  }

  get filteredList() {
    return this.routes.filter((item: any) => {
      const itemAirportName = item.airport.displayName.toLowerCase();
      const itemAirportCode = item.airport.code.toLowerCase();
      const searchString = this.airportTextInput.toLowerCase();

      return (
        itemAirportName.includes(searchString) ||
        itemAirportCode.includes(searchString)
      );
    });
  }

  /* TO-DO: connect translations to DTO */
  validate() {
    this.validationErrors = [];

    // Not empty
    if (this.airportTextInput === '') {
      this.validationErrors.push(this.validationTranslations.notEmpty);
    }
    // Must chose item from list
    const matchInList = this.routes.filter(
      route =>
        route.airport.displayName === this.airportTextInput &&
        route.airport.code === _get(this.selectedAirport, 'airport.code')
    );

    if (matchInList.length === 0) {
      this.validationErrors.push(this.validationTranslations.chooseFromList);
    }

    return this.validationErrors;
  }

  get noResultsText(): String {
    return 'Inga resultat';
  }
}
