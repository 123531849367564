
















import { Component, Prop, Vue, Mixins, Watch } from "vue-property-decorator";
import { AirSearchMemberBannerDTO } from "generated/airSearch/air-search-member-banner-dto";

@Component({})
export default class MemberBanner extends Vue {
  @Prop()
  protected dto!: AirSearchMemberBannerDTO;
}
