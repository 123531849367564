


























































































import { Component, Prop, Vue, Mixins } from "vue-property-decorator";
import modalsMixin from "utils/modals/modalsMixin";
import PrepaidModal from "utils/components/PrepaidModal.vue";
import productService from "utils/services/productService";
import ProductItem from "./ProductItem.vue";
import PrivateProductItem from "./PrivateProductItem.vue";
import { PrepaidItemDTO } from "generated/prepaid/prepaid-item-dto";
import { PrivatePrepaidItemDTO } from "generated/prepaid/private-prepaid-item-dto";
import { MyProductsDTO } from "generated/member/my-products-dto";
import { MemberRelatedLinkDTO } from "generated/member-related-link-dto";
import { resolveMx } from 'dns';
import {ProductCategory} from "utils/domain/productCategories";

@Component({
  components: {
    PrepaidModal,
    ProductItem,
    PrivateProductItem
  }
})
export default class MyProducts extends Mixins(modalsMixin) {
  @Prop({})
  protected dto!: MyProductsDTO;
  private productCategories = ProductCategory;
  private fetching: boolean = true;
  private ticketsPerRow = 3;
  private privateItems: PrivatePrepaidItemDTO[] = [];
  private organisationItems: PrepaidItemDTO[] = [];

  created() {
    productService.fetchProducts(this.dto.productRequest).then(products => {
      this.privateItems = products.privateProducts;
      this.organisationItems = products.organisationProducts;
      this.fetching = false;
    });
  }

  showSubpage(page: MemberRelatedLinkDTO) {
    if (page.isMemberPage) {
      this.$store.dispatch("memberPage/setPageId", page.link);
    } else {
      window.location.href = page.link;
    }
  }

  get hasOrganisationItems(): boolean {
    return this.organisationItems.length != 0;
  }

  get hasPrivateItems(): boolean {
    return this.privateItems.length != 0;
  }

  get showCurrentPage() {
    return this.$store.getters["memberPage/pageId"] === this.dto.pageId;
  }

  bitsIframeWithProduct(productCategory: ProductCategory): string {
    return this.$store.getters["site/pageUrl"].bitFlowIframeLink
            + "?category="+ productCategory;
  }
}
