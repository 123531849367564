import { Component, Vue } from 'vue-property-decorator';
import { BaseRetailResponseDTO } from 'generated/api/base-retail-response-dto';

@Component
export default class RetailMixin extends Vue {
  protected erretailFormId: string = 'erretailForm';
  protected isTripFlowEnabled: boolean = false;
  protected tripFlowFormItems: Array<any> = [];
  protected onTripFlowSubmit: () => void = () => {};

  onTripFlow(
    response: BaseRetailResponseDTO,
    onFormSendCallback: (form: HTMLFormElement) => void = () => {}
  ): void {
    this.removeExistingRetailFormFromDOM();

    if (response) {
      const retailFormElement = this.createRetailForm(response);
      this.isTripFlowEnabled = response.isTripFlowEnabled;

      // If tripflow is enabled, wait til the user has clicked send
      if (this.isTripFlowEnabled) {
        this.tripFlowFormItems = response.tripFormItems;
        this.setTripFlowCallback(() => {
          onFormSendCallback(retailFormElement);
        });
      } else {
        onFormSendCallback(retailFormElement);
      }

    } else {
      throw 'RetailMixin - Cannot process response due to null';
    }
  }

  private removeExistingRetailFormFromDOM(){
    const currentRetailFormElement = document.getElementById(this.erretailFormId);
    if (currentRetailFormElement !== null) {
      (<any>currentRetailFormElement).parentNode.removeChild(
        currentRetailFormElement
      );
    }
  }

  private setTripFlowCallback(callback: () => void) {
    this.onTripFlowSubmit = callback;
  }

  private createRetailForm(response: BaseRetailResponseDTO): HTMLFormElement {
    const form = document.createElement('form');
    const body = document.getElementsByTagName('body')[0];

    response.formItems.forEach(formItem => {
      const input = document.createElement('input');
      input.setAttribute('name', formItem.name);
      input.setAttribute('type', 'text');
      input.setAttribute('value', <any>formItem.value);
      form.appendChild(input);
    });

    form.action = response.url;
    form.method = 'post';
    form.id = this.erretailFormId;
    form.style.display = 'none';

    return body.appendChild(form);
  }
}
