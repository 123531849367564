







import { Component, Prop, Vue } from 'vue-property-decorator';

const scrollOptions = {
  easing: 'ease-in',
  offset: 0, // floating navbar
  force: true,
  cancelable: true,
  onStart: function(element) {
    // scrolling started
  },
  onDone: function(element) {
    // scrolling is done
  },
  onCancel: function() {
    // scrolling has been interrupted
  },
  x: false,
  y: true
};

@Component({})
export default class ScrollToTop extends Vue {
  private scrollUpVisible: boolean = false;
  private scrollDownVisible: boolean = true;

  mounted() {
    window.addEventListener('scroll', this.onScroll);
    window.addEventListener('resize', this.onScroll);
  }

  onScroll() {
    let currentScrollPosition = 0;
    let currentWidth = window.innerWidth;

    if (window.pageYOffset) {
      currentScrollPosition = window.pageYOffset
    } else if (document != null && document.documentElement != null) {
      currentScrollPosition = document.documentElement.scrollTop;
    }

    this.checkScrollButtonsVisibility(currentScrollPosition, currentWidth);
  }

  checkScrollButtonsVisibility(currentScrollPosition, currentWidth) {
    if (currentScrollPosition < 0) {
      return;
    }

    this.scrollUpVisible = !(currentScrollPosition < 1200);
    this.scrollDownVisible = (currentScrollPosition < 1200);
  }

  onScrollTo() {
    if (this.scrollUpVisible) {
      (<any>this).$scrollTo(document.body, 200, scrollOptions);
    }
    else if (this.scrollDownVisible) {
      (<any>this).$scrollTo(document.getElementsByClassName("content__grid")[0], 200, scrollOptions);
    }
  }

  isHomePage(): boolean {
    const documentTypeAlias = this.$store.getters["site/general"].documentTypeAlias;
    return documentTypeAlias === 'startPage' || documentTypeAlias === 'memberStartPage';
  }
}
