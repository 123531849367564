import { render, staticRenderFns } from "./ErretailForm.vue?vue&type=template&id=e8345206&scoped=true&"
import script from "./ErretailForm.vue?vue&type=script&lang=ts&"
export * from "./ErretailForm.vue?vue&type=script&lang=ts&"
import style0 from "./ErretailForm.vue?vue&type=style&index=0&id=e8345206&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e8345206",
  null
  
)

component.options.__file = "ErretailForm.vue"
export default component.exports