export const yyyymmdd = (date: Date, joinCharacter: string = "-"): string => {
  if (typeof(date) === "string") {
    date = new Date(date);
  }

  var mm = date.getMonth() + 1; // getMonth() is zero-based
  var dd = date.getDate();
  return [
    date.getFullYear(),
    (mm > 9 ? "" : "0") + mm,
    (dd > 9 ? "" : "0") + dd
  ].join(joinCharacter);
};

export const yyyymmddutc = (date: Date, joinCharacter: string = "-"): string => {
  if (typeof(date) === "string") {
    date = new Date(date);
  }
  
  var mm = date.getUTCMonth() + 1; // getMonth() is zero-based
  var dd = date.getUTCDate();
  return [
    date.getFullYear(),
    (mm > 9 ? "" : "0") + mm,
    (dd > 9 ? "" : "0") + dd
  ].join(joinCharacter);
};

export const hhmm = (date: Date, joinCharacter: string = ":"): string => {
  let hh = date.getHours();
  let MM = date.getMinutes();
  return [(hh > 9 ? "" : "0") + hh, (MM > 9 ? "" : "0") + MM].join(joinCharacter);
};

export const hhmmutc = (date: Date, joinCharacter: string = ":"): string => {
  let hh = date.getUTCHours();
  let MM = date.getUTCMinutes();
  return [(hh > 9 ? "" : "0") + hh, (MM > 9 ? "" : "0") + MM].join(joinCharacter);
};

export const parse = (date: any): Date  => {
  return date != null ? new Date(date) : date;
}

export const toEretailDatetimeString = (date: Date) : string => {
  if (!date || date == null) return "";
  let currentDate = new Date(date);
  return [yyyymmdd(currentDate, ""), "0000"].join('');
}

export const toEretailDatetimeStringUtc = (date: Date) : string => {
  if (!date || date == null) return "";
  let currentDate = new Date(date);
  return [yyyymmddutc(currentDate, ""), "0000"].join('');
}