import Vue from "vue";
import Vuex from "vuex";
import { module as carousel } from "./components/carousel";
import { module as modals } from "./components/modals";
import { module as memberPage } from "./components/memberPage";
import { module as pageLoader } from "./components/pageLoader";
import { module as site } from "./components/site";
import { module as request } from "./components/request";

Vue.use(Vuex);
const debug = process.env.NODE_ENV !== "production";
let initializedStore: any = null;

const createStore = (modules: any) => {
  initializedStore = new Vuex.Store({
    modules,
    strict: debug
  });
  return initializedStore;
};

export const getStore = () =>
  initializedStore ||
  createStore({
    carousel,
    modals,
    memberPage,
    pageLoader,
    site,
    request
  });
