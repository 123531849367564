export interface TacItem {
  id: number;
  label: string;
  value: string;
}

export interface TacValues {
  orderNumber: string;
  projectNumber: string;
  employeeNumber: string;
  department: string;
  costCenter: string;
  customerNumber: string;
  travelOrderIdentity: string;
  freeText: string;
}

export enum TacOrRc {
  None = 0,
  Tac = 1,
  Rc = 2
}
