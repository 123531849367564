





























































































































































































































import { Component, Prop, Vue, Mixins, Model } from "vue-property-decorator";
import ModalsMixin from 'utils/modals/modalsMixin';
import { AirSearchUmDTO } from "generated/airSearch";
import {
  AirSearchUmRequestApiDTO,
  AirSearchUmPersonInformationRequestApiDTO,
  constructAirSearchUmRequestApiDTO,
  constructAirSearchUmPersonInformationRequestApiDTO
} from "generated/api";
import { nameofFactory } from "utils/nameofFactory";
import { passengertypes } from './tickettypes';

const nameOfAirSearchUmRequestApiDTO = nameofFactory<
  AirSearchUmRequestApiDTO
>();

const nameOfAirSearchUmPersonInformationRequestApiDTO = nameofFactory<
  AirSearchUmPersonInformationRequestApiDTO
>();

@Component
export default class UnattendedMinorModal extends Mixins(ModalsMixin) {
  @Model("passengers-changed", { type: Array })
  private passengers!: Array<number>;
  @Prop({})
  private dto!: AirSearchUmDTO;
  @Prop() private submitCb!: Function;
  @Prop() private isRoundTrip!: boolean;
  @Prop() private unmrFlowEnabled!: boolean;

  private state: AirSearchUmRequestApiDTO = constructAirSearchUmRequestApiDTO();
  private ageFrom = 5;
  private ageTo = 12;

  private useReverseDepratureAsReturn: boolean = false;

  setPersonInformation(
    formData: { [id: string] : string; },
    formPrefix: String,
    model: AirSearchUmPersonInformationRequestApiDTO
  ) {
    formData[formPrefix +
        "." +
        nameOfAirSearchUmPersonInformationRequestApiDTO("name")] = model.name;

    formData[formPrefix +
        "." +
        nameOfAirSearchUmPersonInformationRequestApiDTO("address")] = model.address;

    formData[formPrefix +
        "." +
        nameOfAirSearchUmPersonInformationRequestApiDTO("phonenumber")] = model.phonenumber;
  }

  onSubmit(this: any, event: any) {
    const firstInvalidElem = event.target.querySelector('*:invalid');
    if (firstInvalidElem) {
      firstInvalidElem.classList.add('validation-error');
      firstInvalidElem.focus();
    }

    if (!event.target.checkValidity()) return;

    var umFormData: { [id: string] : string; } = {};
    const formPrefix = "umForm.";
    const hasSecondChild = this.getHasSecondChild();

    umFormData[formPrefix + nameOfAirSearchUmRequestApiDTO("child1_Year")] = this.state.child1_Year;
    umFormData[formPrefix + nameOfAirSearchUmRequestApiDTO("child1_Month")] = this.state.child1_Month;
    umFormData[formPrefix + nameOfAirSearchUmRequestApiDTO("child1_Day")] = this.state.child1_Day;

    umFormData[formPrefix + nameOfAirSearchUmRequestApiDTO("child2_Year")] =  hasSecondChild ? this.state.child2_Year : -1;
    umFormData[formPrefix + nameOfAirSearchUmRequestApiDTO("child2_Month")] = hasSecondChild ? this.state.child2_Month : -1;
    umFormData[formPrefix + nameOfAirSearchUmRequestApiDTO("child2_Day")] = hasSecondChild ? this.state.child2_Day : -1;

    umFormData[formPrefix + nameOfAirSearchUmRequestApiDTO("child2")] = hasSecondChild ? "true" : "false";

    this.setPersonInformation(
      umFormData,
      formPrefix + nameOfAirSearchUmRequestApiDTO("dropOff"),
      this.state.dropOff
    );
    this.setPersonInformation(
      umFormData,
      formPrefix + nameOfAirSearchUmRequestApiDTO("picker"),
      this.state.picker
    );
    this.setPersonInformation(
      umFormData,
      formPrefix + nameOfAirSearchUmRequestApiDTO("dropOffRoundTrip"),
      this.state.dropOffRoundTrip
    );
    this.setPersonInformation(
      umFormData,
      formPrefix + nameOfAirSearchUmRequestApiDTO("pickerRoundTrip"),
      this.state.pickerRoundTrip
    );

    this.submitCb(umFormData);
  }

  totalMinors() : number {
    return this.passengers[passengertypes.CHILDREN];
  }

  getHasSecondChild() : boolean{
    return this.totalMinors() == 2;
  }

  onDelta(delta : number) {
    const newPassengers = [...this.passengers];
    newPassengers[passengertypes.CHILDREN] += delta;
    this.$emit("passengers-changed", newPassengers);
  }

  onClickedUseReverseDepartureData(event) {
    const useReverse = event.target.checked;
    if (useReverse) {
      this.state.pickerRoundTrip = this.state.dropOff;
      this.state.dropOffRoundTrip = this.state.picker;
    } else {
      this.state.pickerRoundTrip = constructAirSearchUmPersonInformationRequestApiDTO();
      this.state.dropOffRoundTrip = constructAirSearchUmPersonInformationRequestApiDTO();
    }
  }

  get years(): Array<number> {
    let years: Array<number> = [];
    const currentYear = new Date().getFullYear();

    for (
      let i = currentYear - this.ageFrom;
      i >= currentYear - this.ageTo;
      i--
    ) {
      years.push(i);
    }
    return years;
  }

  /* Returns an array of all months (January-December) translated after browser locale */
  get months(): Array<string> {
    let months: Array<string> = [];

    let date = new Date("2001-01-01"); // begin on January
    for (let i = 0; i < 12; i++) {
      months.push(
        date.toLocaleString(window.navigator.language, { month: "long" })
      );
      date.setMonth(date.getMonth() + 1);
    }
    return months;
  }

  /* Returns amount of days for given year and month */
  getDays(year: number, month: number) {
    return new Date(year, month, 0).getDate();
  }
}
