








































import { Component, Prop, Mixins, Watch } from "vue-property-decorator";
import { AirSearchTranslationsDTO } from "generated/airsearch/air-search-translations-dto";
import { MemberApiUpdateTravelAccountInformationRequestDTO } from "generated/api/member/member-api-update-travel-account-information-request-dto";

import ModalsMixin from "utils/modals/modalsMixin";
import TacList from "src/components/tac/TacList.vue";
import TacForm from "src/components/tac/TacForm.vue";
import { TacValues } from "src/components/tac/models";

@Component({
  components: {
    TacList,
    TacForm
  }
})
export default class TacReferenceModal extends Mixins(ModalsMixin) {
  @Prop({ required: true })
  protected translations!: AirSearchTranslationsDTO;
  @Prop({ required: true })
  protected request!: MemberApiUpdateTravelAccountInformationRequestDTO;
  @Prop({})
  private onTacReferenceSubmit!: (tacValues: TacValues) => void;
  private isInEditMode: boolean = false;
  private cpyTacList : Array<any> = [];

  get tacValues() : TacValues
  {
      let tacList = {};
      for (let index = 0; index < this.cpyTacList.length; index++) {
        const element = this.cpyTacList[index];
        tacList[element.label] = element.value;
      }
      return <TacValues>tacList;
  }

  get storeTacList() {
    return this.$store.getters["site/userTacList"];
  }

  get editBtnText(): string {
    return !this.isInEditMode
      ? this.translations.tacEditInformation
      : this.translations.referenceCancel;
  }

  mounted(){
    this.resetState();
    if(!this.valid(this.tacValues)) {
        this.isInEditMode = true;
    }
  }

  @Watch("storeTacList")
  onNewTacList(){
    this.resetState();
  }

  onCancel() {
    if (this.isInEditMode) {
      this.resetState();
    }
    this.isInEditMode = !this.isInEditMode;
  }

  onTacSubmit(tacValues: TacValues)
  {
    this.isInEditMode = !this.isInEditMode;

    for (let index = 0; index < this.cpyTacList.length; index++) {
        const element = this.cpyTacList[index];
        if(tacValues[element.label]){
          element.value = tacValues[element.label];
        }
    }
  }

  onContinue() {
    if (this.isInEditMode) {
      this.isInEditMode = !this.isInEditMode;
    } else {
      this.sendTacReferenceSubmit(this.tacValues);
    }
  }

  private resetState() {
    this.cpyTacList = this.getCopyOfTacList();

  }

  private valid(tacValues: TacValues) {
    return Object.values(tacValues).some(p => p !== null && p !== undefined && p !== '');
  }

  private sendTacReferenceSubmit(tacValues: TacValues) {
    if (this.onTacReferenceSubmit) {
      this.onTacReferenceSubmit(tacValues);
    }
  }

  private getCopyOfTacList(){
    let cp = new Array<any>();
    let org = this.storeTacList;

    for (let index = 0; index < org.length; index++) {
      let element = org[index];

      if(element){
        cp.push({...element});
      }
    }
    return cp;
  }
}
