import { render, staticRenderFns } from "./AirsearchSectionMaxpax.vue?vue&type=template&id=b7a81f12&"
import script from "./AirsearchSectionMaxpax.vue?vue&type=script&lang=ts&"
export * from "./AirsearchSectionMaxpax.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "AirsearchSectionMaxpax.vue"
export default component.exports