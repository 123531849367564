










































import { Component, Prop, Vue } from "vue-property-decorator";
import MyBookingsLoginForm from "./MyBookingsLoginForm.vue";
import { nameofFactory } from "utils/nameofFactory";
import { MyBookingsEntryDTO } from "generated/myBookings/my-bookings-entry-dto";
import { TravelerBookingApiSearchRequestDTO } from "generated/api/travelerBooking";

const nameOfInputs = nameofFactory<TravelerBookingApiSearchRequestDTO>();

@Component({
  components: { MyBookingsLoginForm }
})
export default class MyBookingsForm extends Vue {
  @Prop({})
  private dto!: MyBookingsEntryDTO;
  private surename: string = "";
  private bookingReference: string = "";
  private formState = {
    ok: false,
    error: false,
    loading: false
  };

  get hasHeaderBackgroundStyle(): boolean {
    return this.dto.infomationHeaderBackgroundUrl != null;
  }

  get headerBackgroundStyle(): string {
    return this.hasHeaderBackgroundStyle ? `url('${this.dto.infomationHeaderBackgroundUrl}')` : '';
  }

  onNotMemberFormSubmit(e) {
    this.formState.loading = true;
    window.location.search =
      "?" +
      escape(nameOfInputs("surname")) +
      "=" +
      this.surename +
      "&" +
      escape(nameOfInputs("bookingReference")) +
      "=" +
      escape(this.bookingReference.trim());
  }
}
