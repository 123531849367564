







import { Component, Prop, Vue } from "vue-property-decorator";
import { InvoiceCardDTO } from "generated/invoice-card-dto";

@Component
export default class CreditCard extends Vue {
  @Prop({})
  protected card!: InvoiceCardDTO;
}
