import {CountryDTO} from "generated/country-dto";

export class Countries {
    public sweden!: CountryDTO | undefined;
    public norway!: CountryDTO | undefined;
    public denmark!: CountryDTO | undefined;
    public finland!: CountryDTO | undefined;
    public germany!: CountryDTO | undefined;
    public estonia!: CountryDTO | undefined;
    public latvia!: CountryDTO | undefined;
    public france!: CountryDTO | undefined;
    public commonCountries!: (CountryDTO | undefined)[];
    private dto: Array<CountryDTO>;

    constructor(countries: Array<CountryDTO>) {
        this.dto = countries;
        this.initCountries();
        this.initCommonCountries();
    }

    get hasCountries() : boolean {
        return this.dto != undefined && this.dto.length != 0;
    }

    private initCountries() {
        this.sweden = this.dto.find(country => {
            return country.callingCode === 46;
        });
        this.norway = this.dto.find(country => {
            return country.callingCode === 47;
        });
        this.denmark = this.dto.find(country => {
            return country.callingCode === 45;
        });
        this.finland = this.dto.find(country => {
            return country.callingCode === 358;
        });
        this.germany = this.dto.find(country => {
            return country.callingCode === 49;
        });
        this.estonia = this.dto.find(country => {
            return country.callingCode === 372;
        });
        this.latvia = this.dto.find(country => {
            return country.callingCode === 371;
        });
        this.france = this.dto.find(country => {
            return country.callingCode === 33;
        });
    }

    private initCommonCountries() {
        this.commonCountries = [
            this.sweden,
            this.norway,
            this.denmark,
            this.finland,
            this.germany,
            this.estonia,
            this.latvia,
            this.france
        ]
    }
}
