import restClient from '../restClient';
import dateHelper from '../dateHelper';
import { ProductActivityApiActivitiesRequestDTO } from 'generated/api/productActivity/product-activity-api-activities-request-dto';
import { ProductActivityApiActivitiesResponseDTO } from 'generated/api/productActivity/product-activity-api-activities-response-dto';

class MyActivityService {
  async fetch(request: ProductActivityApiActivitiesRequestDTO): Promise<ProductActivityApiActivitiesResponseDTO> {
    const to = this.setToDate(null);    
    return this.fetchQuery(request, this.setFromDate(to, null), to);
  }

  async fetchQuery(request: ProductActivityApiActivitiesRequestDTO, from: Date, to: Date): Promise<ProductActivityApiActivitiesResponseDTO> {
    const fromAsString = dateHelper.getStandardizedDateAsString(from);
    const toAsString = dateHelper.getStandardizedDateAsString(to);
    const url = `${request.action}?from=${fromAsString}&to=${toAsString}`;
    return await restClient.get(url) as ProductActivityApiActivitiesResponseDTO;
  }

  private setFromDate(to : Date, from: Date | null): Date {
    if (from != null) {
      return new Date(from);
    } else {
      let date = new Date(to);
      date.setMonth(date.getMonth() - 1);
      return date;
    }
  }

  private setToDate(to: Date | null): Date {
    return to != null ? new Date(to) : new Date();
  }
}

export default new MyActivityService();
