

























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class PageLoader extends Vue {
  get text() {
    return this.$store.getters['pageLoader/text'];
  }

  get isVisible() {
    return this.$store.getters['pageLoader/isVisible'];
  }
}
