





import { Component, Prop, Vue } from "vue-property-decorator";
import { MemberInformationDTO } from "generated/memberInformation/member-information-dto";
import { TravelerBookingApiBookingDTO } from "generated/api/travelerBooking/traveler-booking-api-booking-dto";
import { AirSearchMemberProfileDTO } from "generated/airSearch";
import { AirSearchMemberTabDTO } from "generated/airSearch/air-search-member-tab-dto";
import {MemberRelatedLinkDTO} from 'generated/member-related-link-dto';

@Component({})
export default class AirsearchProfile extends Vue {
  @Prop({})
  protected dto!: AirSearchMemberProfileDTO;
  @Prop({})
  private memberinformationdto!: MemberInformationDTO;
  private currentTab: string = "";

  get tabs(): AirSearchMemberTabDTO[] {
    return this.dto.tabs;
  }

  created() {
    Vue.prototype.userData = {
      firstName: this.memberinformationdto.firstName,
      lastName: this.memberinformationdto.surName,
      frequentFlyerId: this.memberinformationdto.frequentFlyerId,
      bonusLevel: this.dto.bonusLevel
    };

    if (this.tabs && this.tabs.length > 0) {
      this.currentTab = this.tabs[0].title;
    }
  }

  onClick(tab) {
    this.currentTab = tab;
  }
}
