






































import { Component, Prop, Vue, Model, Watch } from "vue-property-decorator";
import { AirSearchTicketTypeDTO } from "generated/airSearch/air-search-ticket-type-dto";
import { AirSearchCampaignTicketTypeDTO } from "generated/airSearch/air-search-campaign-ticket-type-dto";
import { EVENT_TICKETTYPE_ONSCROLL } from "utils/event-bus/types";
import ieDetector from  'utils/ieDetector';
import eventBus from "utils/event-bus/event-bus";

@Component
export default class TicketTypeInput extends Vue {
  @Model("select-value", { type: Object })
  selectedTicketType!: AirSearchTicketTypeDTO;

  @Prop({})
  private privatetickettypes!: Array<AirSearchTicketTypeDTO>;

  @Prop({})
  private prepaidproducts!: Array<Object>;

  @Prop({})
  private inputPlacholderName!: String;

  @Prop({})
  private inputTranslationsCaption!: String;

  @Prop({})
  private onCampaign!: (ticketType: AirSearchCampaignTicketTypeDTO) => void;

  private errors = [];
  private validationMsgs = []; // delete?
  private isScrolling: boolean = false;
  private activateInfoNoteId : string = "";

  get isIEBrowser() : boolean
  {
    return ieDetector.isIEBrowser;
  } 

  created() {
    if (this.find(this.selectedTicketType) == null) {
      this.selectValue(this.privatetickettypes[0]);
    }
  }

  onScroll(event) {
    if (this.isScrolling) return;
    this.isScrolling = true;
    eventBus.$emit(EVENT_TICKETTYPE_ONSCROLL);
    setTimeout(() => {
      this.isScrolling = false;
    }, 500);
  }

  onInputMousedown(event) {
    // close dropdown if already open
    const isElemFocused = document.activeElement === event.target;
    if (isElemFocused) {
      event.target.blur();
      event.preventDefault();
    }
  }

  find(ticketType: AirSearchTicketTypeDTO): AirSearchTicketTypeDTO | undefined {
    if (ticketType == null || this.privatetickettypes == null) {
      return undefined;
    }

    return this.privatetickettypes.find(
      privatetickettype =>
        privatetickettype.priceType == ticketType.priceType &&
        privatetickettype.priceTypeName == ticketType.priceTypeName &&
        privatetickettype.caption == ticketType.caption &&
        privatetickettype.subCaption == ticketType.subCaption
    );
  }

  getFullCaption(ticketType: AirSearchTicketTypeDTO) {
    if (!ticketType) return null;
    return ticketType.subCaption
      ? `${ticketType.caption} ${ticketType.subCaption}`
      : ticketType.caption;
  }

  selectValue(value: AirSearchTicketTypeDTO) {
    if (value.isCampaign && this.onCampaign != null) {
      this.onCampaign(<AirSearchCampaignTicketTypeDTO>value);
    } else {
      this.$emit("select-value", value);
    }
  }

  validate(this: any) {
    this.errors = [];

    // Not empty
    if (this.selectedTicketType.priceType === null) {
      this.errors.push(this.validationMsgs.chooseFromList);
    }

    return this.errors;
  }

  onClick(event : MouseEvent, product : AirSearchTicketTypeDTO){  
    if(this.isActive(product)){
      this.activateInfoNoteId = "";
    }else{
      const target = event.target as HTMLDivElement;
      const ul = document.getElementById("airsearch-ticket-list") as HTMLUListElement;

      if(target.nextElementSibling != null && ul != null){
        const nextSibling = target.nextElementSibling as HTMLDivElement;
        nextSibling.style.top = (target.offsetTop - ul.scrollTop) + "px";
      }
      
      this.activateInfoNoteId = product.priceType.toString();
    }
  }

  isActive(value : AirSearchTicketTypeDTO){
    return this.activateInfoNoteId == value.priceType.toString();
  }
}
