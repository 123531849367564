


















































































import SearchPanel from "../searchPanel/SearchPanel.vue";
import { Component, Prop, Vue, Watch, Model } from "vue-property-decorator";
import { MyBookingsDTOTranslations } from "generated/myBookings/my-bookings-dto-translations";
import Datepicker from 'utils/datepicker/Datepicker.vue';
import dateHelper from 'utils/dateHelper';

@Component({
  components: {
    SearchPanel,
    Datepicker
  }
})
export default class FilterPanel extends Vue {
  @Model('model-changed')
  private modelValue!: {};
  @Prop({ required: true })
  private airportsFrom!: string[];
  @Prop({ required: true })
  private airportsTo!: Array<any>;
  @Prop({ required: true })
  private translations!: MyBookingsDTOTranslations;

  private selectedTab = "";
  private filter: any = {};
  private TABS = {
    FROM: "FROM",
    TO: "TO",
    DATE: "DATE",
    REFERECE: "REFERECE"
  };

  created() {
    if (this.modelValue) {
      this.filter = Object.assign({}, this.modelValue);
    } else {
      this.clearFilter();
    }
  }

  selectTab(tab) {
    this.selectedTab = this.selectedTab === tab ? "" : tab;
  }

  isSelectedTab(tab) {
    return this.selectedTab === tab;
  }

  hasFilters(tab) {
    if (tab === this.TABS.FROM) {
      return this.filter.departures.length > 0;
    } else if (tab === this.TABS.TO) {
      return this.filter.destinations.length > 0;
    } else if (tab === this.TABS.REFERECE) {
      return this.filter.reference.length > 0;
    } else if (tab === this.TABS.DATE) {
      return this.filter.dateFrom != null || this.filter.dateTo != null;
    } else {
      return false;
    }
  }

  clearFilter() {
    this.filter = {
      departures: [],
      destinations: [],
      reference: "",
      dateFrom: null,
      dateFromStart: null,
      dateTo: null,
      query: "",
      prepaid: null
    };
  }

  @Watch("filter", { deep: true })
  onFilterChange(newFilter) {
    const newFilterCopy = Object.assign({}, newFilter);
    this.$emit("model-changed", newFilterCopy);
  }

  onClickedDateFrom(this: any) {
    window.setTimeout(this.$refs.filterDateFrom.focus, 10);
  }

  onClickedDateTo(this: any) {
    window.setTimeout(this.$refs.filterDateTo.focus, 10);
  }
}
