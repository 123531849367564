



import { Component, Prop, Vue } from "vue-property-decorator";
import restClient from "utils/restClient";
import {
  constructDTO as constructLogoutRequest,
  SessionApiLogoutRequestDTO
} from "generated/api/sessionApi/session-api-logout-request-dto";

@Component
export default class LogoutLink extends Vue {
  @Prop({ default: constructLogoutRequest })
  private dto!: SessionApiLogoutRequestDTO;

  @Prop({ default: () => "" })
  private logoutTitle!: String;

  onLogout(event) {
    Vue.prototype.userData = null;
    restClient
      .post(this.dto.action)
      .then(resp => {
        window.location.replace(window.location.origin);
      })
      .catch(error => {
        window.location.replace(window.location.origin);
      });
  }
}
