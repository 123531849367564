












import { Component, Prop, Vue } from "vue-property-decorator";
import { ProductActivitiesDTO } from "generated/member/productActivities/product-activities-dto";

@Component
export default class ProductActivities extends Vue {
  @Prop({})
  protected dto!: ProductActivitiesDTO;

  get showCurrentPage() {
    return this.$store.getters["memberPage/pageId"] === this.dto.pageId;
  }
}
