








import { Component, Prop, Mixins, Watch } from "vue-property-decorator";
import { AirSearchTranslationsDTO } from "generated/airsearch/air-search-translations-dto";
import ModalsMixin from "utils/modals/modalsMixin";
import TacList from "src/components/tac/TacList.vue";
import TacForm from "src/components/tac/TacForm.vue";
import {TacOrRc} from 'src/components/tac/models';

@Component({
  components: {
    TacList,
    TacForm
  }
})
export default class TacReferenceModal extends Mixins(ModalsMixin) {
  @Prop({ required: true })
  protected translations!: AirSearchTranslationsDTO;
  @Prop({})
  private onOptionSelected!: (selectedOption: TacOrRc) => void

  private tacOrRc = TacOrRc;

}
