















































































import { Component, Prop, Vue, Mixins } from "vue-property-decorator";
import SubmitButton from "utils/components/SubmitButton.vue";

import { isNullOrEmpty } from "utils/stringHelper";
import FormClient from "utils/formClient";
import ModalsMixin from "utils/modals/modalsMixin";
import ValidationMixin from "utils/mixins/validationMixin";
import PasswordEye from "utils/components/PasswordEye.vue";

import { PersonalInformationDTO } from "generated/member/personal-information-dto";

import { getValidationMessageFromState, PropertyValidationTranslationsDTO } from "generated/validation/property-validation-translations-dto";
import { createFormData } from "generated/api/base-api-request-dto";

interface UpdatePasswordState {
  currentpassword: string;
  newPassword: string;
}

@Component({
  components: {
    SubmitButton,
    PasswordEye
  }
})
export default class UpdatePassword extends Mixins(
  ModalsMixin,
  ValidationMixin
) {
  @Prop({})
  protected dto!: PersonalInformationDTO;
  
  private formErrorMessage: string = "";
  private formState = {
    ok: false,
    error: false,
    loading: false
  };

  private state: UpdatePasswordState = {
    currentpassword: "",
    newPassword: "",
  };
  
  get propertyValidation(): PropertyValidationTranslationsDTO {
    return this.$store.getters["site/translation"]["propertyValidation"];
  }

resetState() {
    this.state = {
      currentpassword: "",
      newPassword: "",
    };
  }

  validateCustomInputOnFocus(event: FocusEvent) {
    this.validateInputOnFocus(event);
  }

  onUpdatePasswordSubmit(event) {
    const firstInvalidElem = this.getFirstOrDefaultInvalidInput(event.target);

    if (firstInvalidElem) {
      this.focusOnInvalidInput(firstInvalidElem);
    } else {
      this.formState.loading = true;
      this.formState.ok = false;
      this.formState.error = false;

      FormClient.send(
        this.dto.sessionUpdatePasswordApiRequest,
        createFormData(event.target, this.dto.sessionUpdatePasswordApiRequest)
      )
        .then(response => {
          if (response.Error) {
            this.formState.loading = false;
            this.formState.error = true;
            this.formErrorMessage = response.Header + ' ' + response.Message;
          } else {
            this.formState.loading = false;
            this.formState.ok = true;
          }
        })
        .catch(error => {
          this.formState.loading = false;
          this.formState.error = true;
          this.formErrorMessage = error.message;
        });
    }
  }
}
