





















































import { Component, Prop, Vue } from "vue-property-decorator";

import Slider from "utils/components/Slider.vue";
import FormClient from "utils/formClient";

import { BuyBioFuelDTO } from "generated/member/buy-bio-fuel-dto";
import { FrontendUserStoreDTO } from 'generated/store/frontend-user-store-dto';
import { createFormData } from "generated/api/base-api-request-dto";

@Component({
  components: {
    Slider
  }
})
export default class BuyBioFuel extends Vue {
  @Prop({})
  protected dto!: BuyBioFuelDTO;
  private selectedPoints: number = this.siteUser.awardPoints >= 1000 ? 1000 : 0;
  private formErrorMessage: string = "";
  private formState = {
    ok: false,
    error: false,
    loading: false
  };

  get siteUser(): FrontendUserStoreDTO {
    return this.$store.getters["site/user"];
  }

  pointsToLitres(points: number) {
    return Math.round(points * (2 / 1000) * 10) / 10;
  }

  onBuyBioFuelFormSubmit(event) {
    this.formState.loading = true;
    this.formState.ok = false;
    this.formState.error = false;
    FormClient.send(
      this.dto.form,
      createFormData(event.target, this.dto.form)
    )
      .then(response => {
        this.formState.loading = false;
        this.formState.ok = true;
        this.$store.dispatch("site/setUserAwardPoints", response);
      })
      .catch(error => {
        this.formState.loading = false;
        this.formState.error = true;
        this.formErrorMessage = error.message;
      });
  }
}
