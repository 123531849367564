




import { Component, Prop, Vue } from "vue-property-decorator";
import eventBus from "utils/event-bus/event-bus";
import { EVENT_MODAL_HIDDEN } from "utils/event-bus/types";

@Component
export default class PasswordEye extends Vue {
  @Prop()
  private passwordFields!: HTMLInputElement[];

  private isPeekOn: boolean = false;

  mounted(){
    eventBus.$on(EVENT_MODAL_HIDDEN, () => {
         this.setPeek(false);
      });
  }

  inversePeek() {
    this.setPeek(!this.isPeekOn);
  }
  setPeek(state:boolean){
    this.isPeekOn = state;
    this.passwordFields.forEach(element => {
      if(element){
        element.type = this.isPeekOn ? "text" : "password";
      }
    });
  }
}
