










































































































































import { Component, Prop, Vue, Mixins, Watch } from "vue-property-decorator";
import _get from "lodash/get";
import { hhmm, yyyymmdd } from 'utils/dateFormat';

import ModalsMixin from "utils/modals/modalsMixin";
import AirsearchMixin from "./AirsearchMixin";

import SelectAirportInput from "./SelectAirportInput.vue";
import ToggleSelector from "./ToggleSelector.vue";
import RangeDatepicker from "utils/datepicker-range/RangeDatepicker.vue";
import PassengersInput from "./PassengersInput.vue";
import TicketTypeInput from "./TicketTypeInput.vue";
import UnattendedMinorModal from "./UnattendedMinorModal.vue";
import AirsearchTabs from "./AirsearchTabs.vue";
import TicketInformation from "./TicketInformation.vue";

import {
  AirSearchDTO,
  validateAirSearchPresentDTO,
  AirSearchPresentDTO
} from "generated/airSearch";

import { AirSearchCampaignTicketTypeDTO } from "generated/airSearch/air-search-campaign-ticket-type-dto";
import { minPassengers, maxPassengers } from "./tickettypes";
import { PriceTypeRouteDTO } from "@/generated/airsearch/price-type-route-dto";

@Component({
  components: {
    SelectAirportInput,
    ToggleSelector,
    RangeDatepicker,
    TicketTypeInput,
    PassengersInput,
    UnattendedMinorModal,
    AirsearchTabs,
    TicketInformation
  }
})
export default class Airsearch extends Mixins(AirsearchMixin, ModalsMixin) {
  @Prop({})
  protected dto!: AirSearchDTO;
  private startDateChanged = false;
  private endDateChanged = false;
  private ticketTypeDropdownOpened = false;
  private payWithAVoucherDisabled = false;
  private enableAirportInputFocusEvent = true;
  public state: AirSearchPresentDTO = validateAirSearchPresentDTO(this.dto.airSearchPreset);
  private filteredPrivateTicketTypes = this.getFilterTicketTypes(this.dto.form.typeOfTravel, <any>{});
  private defaultMinimumDate: Date = new Date(2021, 4, 12, 0, 0, 0);

  get priceTypeName() { return _get(this.state, "ticketType.priceTypeName"); }
  get routesFrom() { return  _get(this.state, "to.destinationRoutes", this.dto.routes); }
  get routesTo() { return _get(this.state, "from.destinationRoutes", this.dto.routes); }
  get minPassengers() : number[]{ return minPassengers[_get(this.state.ticketType, 'priceTypeName', 'default')];}
  get maxPassengers() : number[]{ return maxPassengers[_get(this.state.ticketType, 'priceTypeName', 'default')];}

  mounted() {
    this.checkPayWithAVoucher();
  }

  get passengerOptions() {
    const { translations } = this.dto;
    return [
      {
        caption: translations.adultsCaption,
        subcaption: translations.adultsSubCaption
      },
      {
        caption: translations.childsCaption,
        subcaption: translations.childsSubCaption
      },
      {
        caption: translations.infantsCaption,
        subcaption: translations.infantsSubCaption
      }
    ];
  }

  get routeInformation() {
     const { to } = this.getAirports(this.dto.routes,  _get(this.state.from, "airport.airportId", -1), _get(this.state.to, "airport.airportId", -1));
     return _get(to, "airport.routeInformation", null)
  }

  onStartDateChanged(newDate: Date) {
    this.state.startDate = newDate;
    this.checkPayWithAVoucher();
    this.openTicketTypeDropdown();
  }

  onEndDateChanged(newDate: Date) {
    this.state.endDate = newDate;
    this.checkPayWithAVoucher();
    this.openTicketTypeDropdown();
  }

  onRoundTripChanged(newValue: boolean) {
    this.state.isRoundTrip = newValue;
    this.checkPayWithAVoucher();
  }

  checkPayWithAVoucher() {
    this.payWithAVoucherDisabled = this.checkPayWithAVoucherDisabled();
    if (this.state.payWithAVoucher && this.payWithAVoucherDisabled) {
      this.state.payWithAVoucher = false;
    }
  }

  checkPayWithAVoucherDisabled(): boolean {
    if (this.state.showPayWithAVoucher) {
      if (this.state.startDate && (this.state.startDate < this.state.payWithAVoucherMinimumDate || this.state.startDate > this.state.payWithAVoucherMaximumDate)) {
        return true;
      } else if (this.state.endDate && (this.state.endDate < this.state.payWithAVoucherMinimumDate || this.state.endDate > this.state.payWithAVoucherMaximumDate)) {
        return true;
      }
    }

    return false;
  }

  openTicketTypeDropdown(){
    if(this.startDateChanged && this.endDateChanged && !this.ticketTypeDropdownOpened)
    {
      (this.$refs.tickettypeInput as any).$refs.input.focus();
      this.ticketTypeDropdownOpened = true;
    }
  }

  @Watch("state.from")
  onNewStateFrom(newFromState) {
    this.updateFilteredTicketTypes(newFromState, this.state.to);

    // Focus should be applied once
    if(this.enableAirportInputFocusEvent && this.$refs.toInput !== undefined) {
      if(newFromState.airport){
        this.enableAirportInputFocusEvent = false;
        this.state.to = <any>{};
        (this.$refs.toInput as any).$refs.input.focus();
      }
    }

    // If no destinationRoutes exist, we need to back to root
    const from = _get(newFromState, "airport", undefined);
    const to = _get(this.state, "to.airport", undefined);
    const toDestinationRoutesState = _get(this.state, "to.destinationRoutes", undefined);
    if(from == undefined && to != undefined && toDestinationRoutesState == undefined){
      const toRoutes = this.getAirport(this.dto.routes, to.airportId);

      if(toRoutes !== undefined){
        this.state.to = <any>toRoutes;
      }
    }
  }

  @Watch("state.to")
  onNewStateTo(newToState) {
    this.updateFilteredTicketTypes(this.state.from, newToState);

    // Focus should be applied once
    if(this.enableAirportInputFocusEvent && this.$refs.fromInput !== undefined) {
      if(newToState.airport){
        this.enableAirportInputFocusEvent = false;
        this.state.from = <any>{};
        (this.$refs.fromInput as any).$refs.input.focus();
      }
    }

    // If no destinationRoutes exist, we need to back to root
    const from = _get(this.state, "from.airport", undefined);
    const to = _get(newToState, "airport", undefined);
    const fromDestinationRoutesState = _get(this.state, "from.destinationRoutes", undefined);
    if(to == undefined && from !== undefined && fromDestinationRoutesState == undefined){
      const fromRoutes = this.getAirport(this.dto.routes, from.airportId);

      if(fromRoutes !== undefined){
        this.state.from = fromRoutes;
      }
    }
  }

  updateFilteredTicketTypes(fromState, toState){
    const { to } = this.getAirports(this.dto.routes,  _get(fromState, "airport.airportId", -1), _get(toState, "airport.airportId", -1));
    this.filteredPrivateTicketTypes = this.getFilterTicketTypes(this.dto.form.typeOfTravel, _get(to, "airport.priceTypes", []));
  }

  getFilterTicketTypes(typeOfTravel: number, priceTypes: PriceTypeRouteDTO[]) {
    var privateTicketTypes = this.dto.privateTicketTypes
    .filter(it => it.typeOfTravelFilter !== null && it.typeOfTravelFilter !== undefined
      ? it.typeOfTravelFilter === typeOfTravel
      : true
    ).filter(it => priceTypes !== null && priceTypes !== undefined && priceTypes.length > 0
      ? priceTypes.find(x => x.priceType == it.priceType) != null
      : true
    );

    if (this.state && this.state.to && this.state.to.campaignsTicketTypes) {
      privateTicketTypes = privateTicketTypes.concat(this.state.to.campaignsTicketTypes);
    }

    return privateTicketTypes;
  }

  formIsValid(this: any) {
    const formErrors = this.validateInputs(this.$refs);
    return formErrors.length === 0;
  }

  onSubmitUMModal(umForm: { [id: string] : string; }) {
    const formData = this.createFormFromRoutesData(this.dto.routes, this.dto.form, this.state);

    for (var key in umForm) {
        // check if the property/key is defined in the object itself, not in parent
        if (umForm.hasOwnProperty(key)) {
          formData.append(key, umForm[key]);
        }
    }

    this.sendForm(this.dto.form, formData);
    this.hideModal();
  }

  handleSubmit(this: any, event) {
    const formData = this.createFormFromRoutesData(this.dto.routes, this.dto.form, this.state);

    if (this.priceTypeName === "ChildBooking") {
      this.showModal("unattended-minor");
    } else {
      this.sendForm(this.dto.form, formData);
    }
  }

  onShowLowFareChange() {
    this.state.showLowFareCalendar = !this.state.showLowFareCalendar;
  }

  onPayWithAVoucher() {
    this.state.payWithAVoucher = !this.state.payWithAVoucher;
  }

  onSubmit(this: any, e) {
    if (this.formIsValid(e)) {
      this.handleSubmit(e);
    }
  }

  onCampaignTicketType(this: any, ticketType : AirSearchCampaignTicketTypeDTO){
    if (ticketType.isCampaign) {
      const query = {
        isRoundTrip : this.state.isRoundTrip
      };

      if(this.state.startDate != null){
        query['departureDate'] = yyyymmdd(this.state.startDate, "") + hhmm(this.state.startDate, "");
      }

      if(this.state.endDate != null){
        query['returnDate'] = yyyymmdd(this.state.endDate, "") + hhmm(this.state.endDate, "");
      }

      if(this.state.from != null && this.state.from.airport != null){
        query['departureAirportCode'] = this.state.from.airport.code;
      }

      if(this.state.to !== null && this.state.to.airport != null){
        query['arrivalAirportCode'] = this.state.to.airport.code;
      }

      window.location.href = ticketType.campaignUrl + encodeURI("?" + Object.entries(query).map((k) => {
        return k[0].toString() + "=" + k[1].toString();
      }).join('&'));
    }
  }

  onAirportsSwitch(){
    const { to, from } = this.getAirports(this.dto.routes,  _get(this.state.to, "airport.airportId", -1), _get(this.state.from, "airport.airportId", -1));

    if(to !== undefined && from !== undefined){
      this.state.from = from;
      this.state.to = to;
    }
  }
}
