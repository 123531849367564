
































































































































































































































































































import { Component, Prop, Vue, Mixins } from "vue-property-decorator";
import ValidationMixin from "utils/mixins/validationMixin";

import { RegisterDepartmentDTO } from "generated/registerDepartment/register-department-dto";
import {
  constructDTO as constructSignup,
  SessionApiSignUpRequestDTO
} from "generated/api/sessionApi/session-api-sign-up-request-dto";
import {
  constructDTO as constructDepartment,
  RegisterDepartmentDepartmentRequestDTO
} from "generated/api/registerDepartment/register-department-department-request-dto";
import { RegisterDepartmentDepartmentResponseDTO } from "generated/api/registerDepartment/register-department-department-response-dto";

import FormWizardSteps from "utils/components/FormWizardSteps.vue";
import Glide from "@glidejs/glide";
import FormClient from "utils/formClient";
import PhoneInput from "utils/components/PhoneInput.vue";
import PasswordEye from "utils/components/PasswordEye.vue";
import { createEmptyFormData } from "generated/api/base-api-request-dto";
import { setTimeout } from "timers";

import { CountryDTO } from "generated/country-dto";
import CountryService from "utils/services/countryService";

interface RegisterDepartmentState {
  signup: SessionApiSignUpRequestDTO;
  department: RegisterDepartmentDepartmentRequestDTO;
  confirmation: {
    signupEmail: string;
    departmentEmail: string;
  };
}

@Component({
  components: {
    FormWizardSteps,
    PhoneInput,
    PasswordEye
  }
})
export default class RegisterDepartmentForm extends Mixins(ValidationMixin) {
  @Prop({})
  private dto!: RegisterDepartmentDTO;

  private activeStep: number = 0;
  private glide!: any;
  private formErrorMessage: string = "";
  private formState = {
    ok: false,
    error: false,
    loading: false
  };

  private fullPhoneNumber: any = {
    countryCode: null,
    phoneNumber: null
  };

  private departmentFullPhoneNumber: any = {
    countryCode: null,
    phoneNumber: null
  };

  private selectedYear!: any;
  private selectedMonth!: any;
  private countries: CountryDTO[] = [];
  private state: RegisterDepartmentState = {
    signup: constructSignup(),
    department: constructDepartment(),
    confirmation: {
      signupEmail: <any>null,
      departmentEmail: <any>null
    }
  };

  async mounted() {
    this.glide = new Glide(".glide", {
      type: "slider",
      keyboard: false,
      swipeThreshold: false,
      dragThreshold: false,
      startAt: 0,
      animationDuration: 900,
      gap: 100
    }).mount();
    this.state.signup.newsAndOffers = true;
    this.countries = await CountryService.fetch(this.dto.countryRequestUrl);
  }

  get steps(): String[] {
    const stepTranslation = this.dto.translations.step;
    const steps: String[] = [];
    steps.push(`${stepTranslation} 1`);
    if (!this.dto.isAuthenticated) {
      steps.push(`${stepTranslation} 2`);
      steps.push(`${stepTranslation} 3`);
    }
    steps.push(this.dto.translations.stepComplete);
    return steps;
  }

  get isNotEqualSignupEmailText(): string {
    return this.state.signup.email != this.state.confirmation.signupEmail
      ? "Stämmer inte överens med fältet regler"
      : "";
  }

  get isNotEqualDepartmentEmailText(): string {
    return this.state.department.organizationEmail !=
      this.state.confirmation.departmentEmail
      ? "Stämmer inte överens med fältet regler"
      : "";
  }

  get years(): number[] {
    const year = new Date().getFullYear();
    return [...Array(110).keys()].map(i => year - i);
  }

  daysInMonth() {
    if (this.selectedYear && this.selectedMonth) {
      return new Date(this.selectedYear, this.selectedMonth, 0).getDate();
    }
    return 31;
  }

  focusOnNextSlide(event, inputRef: HTMLInputElement) {
    this.nextStep();
    if (inputRef != null) {
      setTimeout(() => {
        inputRef.focus();
      }, 250);
    }
  }

  // update state when phonenumber changed
  onPhoneNumberChange(newFullPhoneNumber) {
    this.state.signup.countryCode = newFullPhoneNumber.countryCode;
    this.state.signup.phoneNumber = newFullPhoneNumber.phoneNumber;
  }

  onDepartmentPhoneNumberChange(newFullPhoneNumber) {
    this.state.department.organizationCountryCode =
      newFullPhoneNumber.countryCode;
    this.state.department.organizationPhone = newFullPhoneNumber.phoneNumber;
  }

  getGlideIndex(elem): number {
    for (; elem && elem !== document; elem = elem.parentNode) {
      if (elem.hasAttribute("data-glide-index")) {
        return parseInt(elem.dataset.glideIndex);
      }
    }
    return 0;
  }

  validateForm(
    event: any,
    formData: FormData,
    offsetGlideIndex: number = 0
  ): Promise<FormData> {
    const firstInvalidElem = event.target.querySelector("*:invalid");

    if (firstInvalidElem) {
      const glideIndex = this.getGlideIndex(firstInvalidElem);
      this.goTo(glideIndex - offsetGlideIndex);
      firstInvalidElem.classList.add("validation-error");
      firstInvalidElem.focus();
      return Promise.reject(formData);
    }

    if (!event.target.checkValidity()) {
      return Promise.reject(formData);
    } else {
      return Promise.resolve(formData);
    }
  }

  appendOrganisationFormData(formData: FormData): Promise<FormData> {
    formData.append("organizationName", this.state.department.organizationName);
    formData.append(
      "organizationPhone",
      this.state.department.organizationPhone
    );
    formData.append(
      "organizationCountryCode",
      this.state.department.organizationCountryCode
    );
    formData.append(
      "organizationFullPhoneNumber",
      this.state.department.organizationFullPhoneNumber
    );
    formData.append(
      "organizationEmail",
      this.state.department.organizationEmail
    );
    formData.append(
      "organizationConfEmail",
      this.state.confirmation.departmentEmail
    );
    formData.append(
      "organizationAddress",
      this.state.department.organizationAddress
    );
    formData.append("organizationCity", this.state.department.organizationCity);
    formData.append(
      "organizationZipCode",
      this.state.department.organizationZipCode
    );
    formData.append(
      "organizationNumber",
      this.state.department.organizationNumber
    );
    return Promise.resolve(formData);
  }

  appendMemberFormData(formData: FormData): Promise<FormData> {
    formData.append("signUpRequest.password", this.state.signup.password);
    formData.append("signUpRequest.year", this.state.signup.year.toString());
    formData.append("signUpRequest.month", this.state.signup.month.toString());
    formData.append("signUpRequest.day", this.state.signup.day.toString());
    formData.append("signUpRequest.firstname", this.state.signup.firstname);
    formData.append("signUpRequest.surname", this.state.signup.surname);
    formData.append("signUpRequest.gender", this.state.signup.gender);
    formData.append("signUpRequest.email", this.state.signup.email);
    formData.append("signUpRequest.phoneNumber", this.state.signup.phoneNumber);
    formData.append("signUpRequest.countryCode", this.state.signup.countryCode);
    formData.append("signUpRequest.newsAndOffers", this.state.signup.newsAndOffers.toString());
    return Promise.resolve(formData);
  }

  sendFormData(request, formData: FormData) {
    formData.append(
      "createNewOrganizationCompleteUrl",
      this.dto.afterSuccessfulRegistrationLink.link
    );

    FormClient.send(request, formData)
      .then((response: RegisterDepartmentDepartmentResponseDTO) => {
        this.formState.loading = false;
        this.formState.ok = true;
        this.formState.error = false;
        if(!response.success)
        {
          this.handleError;
        }
        else
        {
          window.location.href = response.redirectUrl;
        }
      })
      .catch(this.handleError);
  }

  handleError(error) {
    this.formState.loading = false;
    this.formState.ok = false;
    this.formState.error = true;

    if (error.message != null) {
      this.formErrorMessage = error.message;
    }
  }

  onRegisterDepartmentSubmit(event: any) {
    this.formState.loading = true;
    this.formState.ok = false;
    this.formState.error = false;
    
    if (this.dto.isAuthenticated) {
      const departmentFormData = createEmptyFormData(this.dto.departmentRequest);

      this.validateForm(event, departmentFormData, 2)
        .then(this.appendOrganisationFormData)
        .then(formData => {
          this.sendFormData(this.dto.departmentRequest, formData);
        })
        .catch(this.handleError);
    } else {
      const userAndDepartmentFormData = createEmptyFormData(this.dto.userAndDepartmentRequest);

      this.validateForm(event, userAndDepartmentFormData)
        .then(this.appendOrganisationFormData)
        .then(this.appendMemberFormData)
        .then(formData => {
          this.sendFormData(this.dto.userAndDepartmentRequest, formData);
        })
        .catch(this.handleError);
    }
  }

  validateCustomConfirmInputOnFocus(
    event: FocusEvent,
    confRef,
    message: string
  ) {
    this.validateInputOnFocus(event);
    this.validateInput(confRef, message);
  }

  nextStep() {
    this.glide.go(">");
    this.activeStep = this.glide.index;
  }

  prevStep() {
    this.glide.go("<");
    this.activeStep = this.glide.index;
  }

  goTo(index) {
    this.glide.go(`=${index}`);
    this.activeStep = this.glide.index;
  }

  selectYear(e) {
    this.selectedYear = e.target.value;
  }

  selectMonth(e) {
    this.selectedMonth = e.target.value;
  }
}
