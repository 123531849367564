



























































































































































































import _get from "lodash/get";
import { Component, Prop, Vue, Watch, Mixins } from 'vue-property-decorator';
import Pagination from 'utils/components/Pagination.vue';
import myActivityService from 'utils/services/myActivityService';
import Datepicker from 'utils/datepicker/Datepicker.vue';
import dateHelper from 'utils/dateHelper';

import {
  ProductActivitiesDTO,
  validateDTO
} from 'generated/member/productActivities/product-activities-dto';

import { ProductActivityDTO, validateDTOs as validateProductActivityDTO } from 'generated/member/productActivities/product-activity-dto';
import { error } from 'util';

// classes for icons that each sort type represents
const SORT_NONE = {
  className: 'fa fa-sort text-muted',
  direction: 0
};
const SORT_DESC = {
  className: 'fa fa-sort-desc',
  direction: -1
};
const SORT_ASC = {
  className: 'fa fa-sort-asc',
  direction: 1
};

const sortTypes = [SORT_NONE, SORT_DESC, SORT_ASC];

@Component({
  components: {
    Pagination,
    Datepicker
  }
})
export default class MyActivities extends Vue {
  @Prop({})
  private dto: ProductActivitiesDTO = validateDTO(this.dto);

  private filterDateFrom: Date = new Date();
  private filterDateTo: Date = new Date();

  private filterDateFromStart: Date = new Date(this.filterDateFrom.getUTCFullYear() - 1, 0, 1,);
  private startRangeMonthOffset: number = -(dateHelper.getDifferenceInMonths(this.filterDateFromStart, this.filterDateFrom) + 1);

  private activities: ProductActivityDTO[] = [];
  private currentPage: number = 1;
  private itemsPerPage: number = 5;
  private loadingActivities : boolean = false;

  private expandActivityItem: string = '';

  private sortSettings = {
    activityDate: {
      sortType: SORT_NONE,
      getSortProp: a => a.flightInformation[0].departureDate
    },
    activityTraveler: {
      sortType: SORT_NONE,
      getSortProp: a => a.accountName
    },
    activityRoute: {
      sortType: SORT_NONE,
      getSortProp: a => a.flightInformation[0].departureAirportCode
    },
    activityBookingRef: {
      sortType: SORT_NONE,
      getSortProp: a => a.bookingRef
    },
    activityStatus: {
      sortType: SORT_NONE,
      getSortProp: a => a.flightInformation[0].status
    },
    activityValidUntil: {
      sortType: SORT_NONE,
      getSortProp: a => a.flightInformation[0].validTo
    },
    activityUsed: {
      sortType: SORT_NONE,
      getSortProp: a => a.totalAmountOfClipUsed
    }
  };

  get showCurrentPage() {
    return this.$store.getters["memberPage/pageId"] === this.dto.pageId;
  }

  get headerBackgroundStyle(): string {
    return 'linear-gradient(0deg,rgba(0, 160, 176, 0.9),rgba(0, 160, 176, 0.9))';
  }

  sortedList() {
    const sortedList: ProductActivityDTO[] = [...this.activities];

    // do not sort if sortType === SORT_NONE
    const includedSortSettings: any = [];
    const keys = Object.keys(this.sortSettings);
    keys.forEach(k => {
      if (this.sortSettings[k].sortType !== SORT_NONE) {
        includedSortSettings.push(this.sortSettings[k]);
      }
    });

    sortedList.sort(function(a, b) {
      let i = 0,
        result = 0;
      while (i < includedSortSettings.length && result === 0) {
        const sortSetting = includedSortSettings[i];
        const { sortType } = sortSetting;
        const aProp = sortSetting.getSortProp(a).toString();
        const bProp = sortSetting.getSortProp(b).toString();

        result =
          sortType.direction * (aProp < bProp ? -1 : aProp > bProp ? 1 : 0);
        i++;
      }

      return result;
    });

    return sortedList;
  }

  onShowHistoryClick() {
    this.fetchActivities(this.filterDateFrom, this.filterDateTo);
  }

  sortItemClicked(sortItem) {
    const currentSortType = this.sortSettings[sortItem].sortType;
    const index = sortTypes.indexOf(currentSortType);
    const nextSortType =
      index < sortTypes.length - 1 ? sortTypes[index + 1] : sortTypes[0];
    this.sortSettings[sortItem].sortType = nextSortType;
  }

  getSortIcon(sortItem) {
    return this.sortSettings[sortItem].sortType.className;
  }

  expandActivity(reference) {
    this.expandActivityItem = reference;
  }

  isExpanded(reference) {
    return this.expandActivityItem === reference;
  }

  exportToCsvLink() {
    const fromAsString = dateHelper.getStandardizedDateAsString(this.filterDateFrom);
    const toAsString = dateHelper.getStandardizedDateAsString(this.filterDateTo);
    const link = `${this.dto.exportToCSVUrl}?from=${fromAsString}&to=${toAsString}`;
    window.open(link, '_blank');
  }

  created() {
    // Fetch data
    this.loadingActivities = true;
    myActivityService
      .fetch(this.dto.fetchRequest)
      .then(resp => {        
        this.activities = validateProductActivityDTO(resp.activities || []);
        this.loadingActivities = false;
      })
      .catch(error => {
        this.loadingActivities = false;
      });
  }

  fetchActivities(from: Date, to: Date) {
    this.loadingActivities = true;
    myActivityService
      .fetchQuery(this.dto.fetchRequest, from, to)
      .then(resp => {       
        this.activities = validateProductActivityDTO(resp.activities || []);
        this.loadingActivities = false;
      })
      .catch(error => {
        this.loadingActivities = false;
      });
  }

  // show items in range for selected page
  get activitiesRange() {
    const startRange = this.currentPage * this.itemsPerPage - this.itemsPerPage;
    const endRange = startRange + this.itemsPerPage;
    return this.sortedList().slice(startRange, endRange);
  }

  @Watch('sortedList')
  onSortedList() {
    this.currentPage = 1;
  }

  onClickedDateFrom(this: any) {
    window.setTimeout(this.$refs.filterDateFrom.focus, 10);
  }

  onClickedDateTo(this: any) {
    window.setTimeout(this.$refs.filterDateTo.focus, 10);
  }

  @Watch('filterDateFrom')
  onDateFromChanged(this: any) {
    this.$refs.filterDateFrom.blur();
  }

  @Watch('filterDateTo')
  onDateToChanged(this: any) {
    this.$refs.filterDateTo.blur();
  }
}
