import { Prop, Component, Vue } from "vue-property-decorator";
import {
  getValidationMessageFromState,
  PropertyValidationTranslationsDTO
} from "generated/validation/property-validation-translations-dto";

@Component
export default class ValidationMixin extends Vue {
  @Prop({})
  public validationTranslation!: PropertyValidationTranslationsDTO;
  private validationErrorClassName : string = "validation-error";
  private validationClassName: string = "validation__msg";

  public validateInputOnFocus(event: FocusEvent, customError: string = "") {
    this.validateInput(event.target as HTMLInputElement, customError);
  }

  public validateInput(inputElement: HTMLInputElement, customError: string = "") {
    if (!inputElement) return;
    this.removeValidationMsg(inputElement);
    this.setValidationMsg(inputElement, customError);
  }

  protected getFirstOrDefaultInvalidInput(form: HTMLFontElement): HTMLInputElement | null {
    if (!form) return null;
    return form.querySelector("*:invalid") as HTMLInputElement;
  }

  protected removeValidationMsg(inputElement: HTMLInputElement) {
    const nextSibling = inputElement.nextSibling as HTMLDivElement;
    if (nextSibling != null && nextSibling.parentElement != null && nextSibling.classList.contains(this.validationClassName)) {
      nextSibling.parentElement.removeChild(nextSibling);
    }
  }

  protected focusOnInvalidInput(inputElement: HTMLInputElement){
    if(!inputElement) return;
    this.validateInput(inputElement);
    inputElement.focus();
  }

  protected setValidationMsg(inputElement: HTMLInputElement, message: string) {
    if (!inputElement) return;
    inputElement.classList.remove(this.validationErrorClassName);
    inputElement.setCustomValidity(message);

    if (!inputElement.validity.valid && !inputElement.validity.customError) {
      message = getValidationMessageFromState(
        this.validationTranslation,
        inputElement.validity
      );
    }

    const parentElement = inputElement.parentElement;
    if (!message || message === "" || !parentElement) return;

    const divElement = document.createElement("div");
    divElement.classList.add(this.validationClassName);
    divElement.innerText = message;

    inputElement.classList.add(this.validationErrorClassName);
    inputElement.setCustomValidity(message);
    parentElement.insertBefore(divElement, inputElement.nextSibling);
  }
}
