




























import { Component, Prop, Vue, Mixins } from "vue-property-decorator";
import stickybits from "stickybits";

import { MemberBarDTO } from "generated/memberBar";
import { MemberRelatedLinkDTO } from "generated/member-related-link-dto";

import restClient from "utils/restClient";
import ModalsMixin from "utils/modals/modalsMixin";
import Modal from "utils/modals/Modal.vue";
import ChangeToTMModal from "utils/components/ChangeToTmModal.vue";
import LogoutLink from "utils/components/LogoutLink.vue";

@Component({
  components: {
    Modal,
    ChangeToTMModal,
    LogoutLink
  }
})
export default class MemberBar extends Mixins(ModalsMixin) {
  @Prop({})
  private dto!: MemberBarDTO;

  mounted() {
    const windowWidth = window.innerWidth;
    // might be set up in store in feature
    const mediaBreakpointLarge = 992;

    // Do not use sticky on smaller screens
    if (windowWidth < mediaBreakpointLarge) return;

    // polyfill for position sticky
    stickybits(".navbar-logged-in__wrapper", {
      useStickyClasses: true,
      stickyBitStickyOffset: 70
    });

    const divElement = this.$refs.loggedInwrapper as HTMLDivElement;
    if (divElement.style.position != "sticky") {
      window.addEventListener("scroll", () => {
        if (window.pageYOffset === 0) {
          if (divElement.style.position === "fixed") {
            divElement.style.position = "inherit";
          }
        } else {
          if (divElement.style.position === "inherit") {
            divElement.style.position = "fixed";
          }
        }
      });
    }
  }

  get zIndex() {
    return this.isCurrentModal("change-role") ? 1036 : 1034;
  }

  showSubpage(pageId) {
    this.$store.dispatch("memberPage/setPageId", pageId);
  }

  get isAuthenticated(): boolean {
    return this.dto.isAuthenticated;
  }

  get links() {
    return this.dto.links;
  }

  get logoutForm() {
    return this.dto.logoutForm;
  }
}
