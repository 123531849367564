























import { Component, Prop, Vue } from "vue-property-decorator";
import LoginPage from "./LoginPage.vue";
import RegisterMember from "../registerMember/RegisterMember.vue";
import { LoginAndRegisterModalDTO } from "generated/loginModal/login-and-register-modal-dto";
import { SignInAction } from "utils/store/actionTypes";
import { SessionApiSignUpResponseDTO } from "generated/api/sessionApi/session-api-sign-up-response-dto";
import EventBus from "utils/event-bus/event-bus";
import { EVENT_OPEN_LOGIN_MODAL } from "utils/event-bus/types";

@Component({
  components: { RegisterMember, LoginPage }
})
export default class LoginPageModal extends Vue {
  @Prop({})
  private dto!: LoginAndRegisterModalDTO;
  private registerFormVisible: boolean = false;

  mounted(){
      EventBus.$on(EVENT_OPEN_LOGIN_MODAL, (redirectUrl) => {
         this.dto.loginDto.loginRequest.memberStartPage = redirectUrl;
         this.$store.dispatch('modals/show', 'login-page-modal');
      });
  }
  
  setRegisterFormVisible(isVisible: boolean) {
    this.registerFormVisible = isVisible;
  }

  onMemberRegistered(response: SessionApiSignUpResponseDTO, state) {
    const signInModel: SignInAction = Object.assign(
      this.dto.loginDto.loginRequest,
      {
        keepMeSignedIn: false,
        password: state.password,
        userName: response.usernameOrFfid
      }
    );

    this.$store.dispatch("request/signInMember", signInModel);
  }
}
