















import { Component, Prop, Vue } from "vue-property-decorator";

import { InformationPageHeaderDTO } from "generated/informationPage/information-page-header-dto";

@Component
export default class InfoHeader extends Vue {
  @Prop({})
  private dto!: InformationPageHeaderDTO;

  get headerBackgroundStyle(): string {
    return this.dto.hasBackground ? `url('${this.dto.backgroundUrl}')` : "";
  }

  get titleTextColor(): string {
    return this.dto.hasTitelRgbaColor ? this.dto.titelRgbaColor : "";
  }

  get ingressTextColor(): string {
    return this.dto.hasIngressRgbaColor ? this.dto.ingressRgbaColor : "";
  }
}
