import { render, staticRenderFns } from "./RegisterPointsLight.vue?vue&type=template&id=c69e589c&"
import script from "./RegisterPointsLight.vue?vue&type=script&lang=ts&"
export * from "./RegisterPointsLight.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "RegisterPointsLight.vue"
export default component.exports