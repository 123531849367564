





















































import { Component, Prop, Vue } from "vue-property-decorator";
import { ForgotMemberIdDTO } from "../../generated/forgotMemberId";
import fmids from '../../utils/services/forgotMemberIdService';

@Component({})
export default class ForgotMemberId extends Vue {
    @Prop({})
    private dto!: ForgotMemberIdDTO;
    private currentView = '';
    private formLoading = false;
    private formCreateEmailModel = {
      FirstName: '',
      LastName: '',
      Email: ''
    };

    async onForgotMemberIdCreateEmailFormSubmit(event: any) {
    //   if(event.target[0].validity.valid &&
    //     event.target[1].validity.valid &&
    //     event.target[2].validity.valid) {
          this.formLoading = true;
          let result = await fmids.createEmail(this.formCreateEmailModel);
          this.formLoading = false;
          this.onHandleSubmitCreateEmailResult(result);
    //   }
    }

    onHandleSubmitCreateEmailResult(result: any) {
      switch(result) {
        case "invalid":
          this.currentView = 'create-invalid';
          break;
        case "duplicated":
          this.currentView = 'create-duplicated';
          break;
        case "success":
          this.currentView = 'create-success';
          break;
        case "error":
        default:
          this.currentView = 'create-error';
          break;
      }
    }
}
