import { Module, GetterTree, ActionTree, MutationTree } from "vuex";
import { PageLoaderState, RootState } from "../types";

const namespaced: boolean = true;

export const state: PageLoaderState = Object.assign({}, {
  isVisible: false,
  text: ''
});

const getters: GetterTree<PageLoaderState, RootState> = {
  isVisible: state => state.isVisible,
  text: state => state.text,
};

const actions: ActionTree<PageLoaderState, RootState> = {
  show({ commit }) {
    commit('setVisible', true);
  },
  hide({ commit }) {
    commit('setVisible', false);
  },
  text({ commit }, value) {
    commit('setText', value);
  },
};

const mutations: MutationTree<PageLoaderState> = {
  setVisible(state, value) {
    state.isVisible = value;
  },
  setText(state, value) {
    state.text = value;
  },
};

export const module: Module<PageLoaderState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};