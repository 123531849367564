















import { Component, Prop, Vue } from "vue-property-decorator";
import { AlertsDTO } from "generated/alert/alerts-dto";
import { AlertDTO } from "generated/alert/alert-dto";

@Component
export default class Alerts extends Vue {
  @Prop({})
  private dto!: AlertsDTO;

  get alerts(): AlertDTO[] {
    return this.dto.hasAlerts ? this.dto.alerts : [];
  }
}
