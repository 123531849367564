
























import { Component, Prop, Vue } from "vue-property-decorator";
import { CreditCardDTO } from "generated/credit-card-dto";

@Component
export default class CreditCard extends Vue {
  @Prop({})
  protected card!: CreditCardDTO;
  @Prop({})
  protected onRemoveClick!: (card : CreditCardDTO) => void;

  get showRemoveButton(): boolean {
    return this.onRemoveClick != null && !this.card.isInherited;
  }
}
