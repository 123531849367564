







































































































































import { Component, Prop, Mixins } from "vue-property-decorator";
import { AirSearchTranslationsDTO } from "generated/airsearch/air-search-translations-dto";
import { createFormData } from "generated/api/base-api-request-dto";
import { MemberApiUpdateTravelAccountInformationRequestDTO } from "generated/api/member/member-api-update-travel-account-information-request-dto";
import { MemberApiUpdateTravelAccountInformationResponseDTO } from "generated/api/member/member-api-update-travel-account-information-response-dto";
import FormClient from "utils/formClient";
import ValidationMixin from "utils/mixins/validationMixin";
import { TacValues } from "src/components/tac/models";
import SubmitButton from "utils/components/SubmitButton.vue";

@Component({
  components: {
    SubmitButton
  }
})
export default class TacReferenceModal extends Mixins(ValidationMixin) {
  @Prop({ default: () => true })
  protected disableCancel!: boolean;
  @Prop({ default: () => false })
  protected disableSendData!: boolean;

  @Prop({ default: () => "" })
  protected title!: string;
  @Prop({ default: () => "" })
  protected cancelBtnText!: string;
  @Prop({ default: () => "" })
  protected submitBtnText!: string;
  @Prop({ default: () => "" })
  protected okText!: string;

  @Prop({ required: true })
  protected translations!: AirSearchTranslationsDTO;
  @Prop({ required: true })
  protected request!: MemberApiUpdateTravelAccountInformationRequestDTO;
  @Prop({ required: true })
  protected tacValues!: TacValues;

  @Prop({})
  private onTacSubmit!: (tacValues: TacValues) => void;
  @Prop({})
  private onTacCancel!: () => void;

  private formErrorMessage: string = "";
  private isInEditMode: boolean = false;
  private formState = {
    ok: false,
    error: false,
    loading: false,
    isFormValid: true,
  };

  onFormSubmit(event) {
    const firstInvalidElem = this.getFirstOrDefaultInvalidInput(event.target);
    if (firstInvalidElem) {
      this.focusOnInvalidInput(firstInvalidElem);
    } else {
      if (this.disableSendData) {
        this.sendTacEvent(this.tacValues);
      } else {
        this.sendData(event);
      }
    }
  }

  private sendData(event) {
    this.formState.loading = true;
    this.formState.ok = false;
    this.formState.error = false;
    let formData = createFormData(event.target, this.request);

    formData.append(
      "updateSession",
      this.request.updateSession ? "true" : "false"
    );

    if(!this.valid(this.tacValues)) {
      this.formState.loading = false;
      this.formState.error = true;
      this.formState.ok = false;
      this.formErrorMessage = this.translations.tacMissingMessage;
    }
    else {
      FormClient.send(this.request, formData)
          .then((response: MemberApiUpdateTravelAccountInformationResponseDTO) => {
            this.formState.loading = false;
            this.formState.ok = true;
            this.$store.dispatch("site/setUserTac", response.travelAccountInformation);
            this.sendTacEvent(this.tacValues);
          })
          .catch(error => {
            this.formState.loading = false;
            this.formState.error = true;
            this.formErrorMessage = error.message;
          });
    }
  }

  private sendTacEvent(tacValues: TacValues) {
    if(!this.valid(tacValues)) {
      this.formState.loading = false;
      this.formState.error = true;
      this.formState.ok = false;
      this.formErrorMessage = this.translations.tacMissingMessage;
    }
    else {
      if (this.onTacSubmit) {
        this.onTacSubmit(tacValues);
      }
    }
  }

  private valid(tacValues: TacValues) {
    return Object.values(tacValues).some(p => p !== null && p !== undefined && p !== '');
  }
}
