import { render, staticRenderFns } from "./SessionExpiration.vue?vue&type=template&id=5ecc5423&"
import script from "./SessionExpiration.vue?vue&type=script&lang=ts&"
export * from "./SessionExpiration.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "SessionExpiration.vue"
export default component.exports