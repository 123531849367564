























import { Component, Prop, Vue, Model } from "vue-property-decorator";
import { AirSearchTranslationsDTO } from "generated/airSearch";
import { AirSearchMemberDTOPrepaidProducts } from "generated/airSearch/air-search-member-dto-prepaid-products";
import { AirSearchMemberPrepaidProductNames } from "generated/airSearch/air-search-member-prepaid-product-names";

import { EVENT_TICKETTYPE_ONSCROLL } from "utils/event-bus/types";
import eventBus from "utils/event-bus/event-bus";
import PointsHistory from '@/components/memberPages/pointsHistory/PointsHistory.vue';


@Component
export default class PrePaidTicketTypeInput extends Vue {
  @Model("select-value", { type: Object })
  selectedProduct!: AirSearchMemberDTOPrepaidProducts;
  @Prop({})
  private products!: AirSearchMemberDTOPrepaidProducts[];
  @Prop({})
  private productNames!: AirSearchMemberPrepaidProductNames[];
  @Prop({})
  private translations!: AirSearchTranslationsDTO;
  private activateInfoNoteId : string = "";

  get prepaidByCategoryAndSource() {
    let keys = {};

    this.products.forEach(product => {
      const category = product.category;
      if (!(category in keys)) {
        keys[category] = {};
      }

      const source = product.source;
      if (!(source in keys[category])) {
        keys[category][source] = [];
      }
      keys[category][source].push(product);
    });

    return keys;
  }

  mounted(){
    eventBus.$on(EVENT_TICKETTYPE_ONSCROLL, () => {
      this.activateInfoNoteId = "";
    })
  }

  isActive(value: string){
    return this.activateInfoNoteId == value;
  }

  getCategory(category) {
    return this.productNames[category].caption;
  }

  getSource(category, source) {
    return this.productNames[category].source[source];
  }

  hasSource(category, source) {
    return this.productNames[category].source[source] !== null;
  }

  selectValue(value : AirSearchMemberDTOPrepaidProducts) {
    this.activateInfoNoteId = "";
    this.$emit("select-value", value);
  }

  onClick(event : MouseEvent,value : string){
    if(this.isActive(value)){
      this.activateInfoNoteId = "";
    }else{
      const target = event.target as HTMLDivElement;
      const ul = document.getElementById("airsearch-ticket-list") as HTMLUListElement;

      if(target.nextElementSibling != null && ul != null){
        const nextSibling = target.nextElementSibling as HTMLDivElement;
        nextSibling.style.top = (target.offsetTop - ul.scrollTop) + "px";
      }

      this.activateInfoNoteId = value;
    }
  }
  showBuyProductUrl(product: AirSearchMemberDTOPrepaidProducts) : boolean{
    // CATEGORY 2 FOR ANNUAL PRODUCTS
    if (product.category === 2){
      var diff = Math.abs(product.expiryDate.getTime() - (new Date()).getTime());
      var diffDays = Math.ceil(diff / (1000 * 3600 * 24));
      return diffDays < 31;
    }
    // CATEGORY 4 and 1 FOR CLIP and PUNCH PRODUCTS
    const clipProductsCategories = [1,4];
    return clipProductsCategories.includes(product.category) && product.ticketsLeft < 4;
  }

  buyNowText(product:AirSearchMemberDTOPrepaidProducts) : string{
    return product.category === 2 ? `${this.translations.renewHerePart1} ${product.details} ${this.translations.renewHerePart2}` :
    this.translations.buyNewPunchCardHere;
  }

  getUniqueListElementId(product : AirSearchMemberDTOPrepaidProducts, index : number){
    return product.category.toString() + product.source.toString() + index.toString();
  }

 bitsIframeWithProduct(product: AirSearchMemberDTOPrepaidProducts): string {
    return this.$store.getters["site/pageUrl"].bitFlowIframeLink
        + "?category=" + product.category + "&productId=" + product.id + "&productDetail=" + product.details;
}
}
