















































import { Component, Prop, Vue } from "vue-property-decorator";
import { PointsHistoryDTO, validateDTO } from "generated/member/points-history-dto";
import { FrontendUserStoreDTO } from 'generated/store/frontend-user-store-dto';

import Pagination from 'src/utils/components/Pagination.vue';

@Component({
  components: {
    Pagination
  }
})
export default class PointsHistory extends Vue {
  @Prop({})
  protected dto: PointsHistoryDTO = validateDTO(this.dto);
  private currentPage: number = 1;
  private itemsPerPage: number = 10;
  private hideZeroPointsTransactions: boolean = false;

  get siteUser(): FrontendUserStoreDTO {
    return this.$store.getters["site/user"];
  }

  get activities() {
    if (this.hideZeroPointsTransactions) {
      return this.siteUser.loyalty.activities.filter(x => x.awardPoints !== "0");
    } else {
      return this.siteUser.loyalty.activities;
    }
  }

  get activitiesRange() {
    const startRange = (this.currentPage * this.itemsPerPage) - this.itemsPerPage;
    const endRange = startRange + this.itemsPerPage;
    return this.activities.slice(startRange, endRange);
  }

  get hasActivities() {
    return this.activities.length > 0;
  }

  get showCurrentPage() {
    return this.$store.getters["memberPage/pageId"] === this.dto.pageId;
  }

  isValidOperation(activity) {
    return activity.operationType == "R" || activity.operationType == "A";
  }

  onHideZeroPointsTransactionsChange() {
    this.hideZeroPointsTransactions = !this.hideZeroPointsTransactions;
  }
}
