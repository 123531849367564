















































import { Component, Prop, Vue, Model, Watch } from "vue-property-decorator";
import { Countries } from "utils/domain/countries";
import { CountryDTO } from "generated/country-dto";

@Component({})
export default class PhoneInput extends Vue {
  @Model("phone-nbr-changed")
  private fullPhoneNumber!: any;

  @Prop({ default: [] })
  private countryList!: CountryDTO[];

  @Prop()
  private onChange!: Function;

  @Prop({ default: false })
  private required!: boolean;

  @Prop()
  private theme!: string;

  @Prop({ default: () => "Telefonnummer" })
  private placeholder!: string;

  private phoneNumber = "";
  private countries: Countries = new Countries(this.countryList);
  private selectedCountry: any = {};

  @Watch("countryList")
  onNewCountries(countries : CountryDTO[]){
    this.countries = new Countries(this.countryList);
    
    let selectedCountry;

    if (this.fullPhoneNumber != null) {
      this.phoneNumber = this.fullPhoneNumber.phoneNumber || "";

      if (this.fullPhoneNumber.countryCode != null) {
        selectedCountry = this.countryList.find(
          country => country.callingCode === this.fullPhoneNumber.countryCode
        );
      }
    }

    // Should not focus on mounted
    this.select(selectedCountry || this.countries.sweden, false);
  }

  select(country, focus = true) {
    this.selectedCountry = country;
    this.updatePhoneNbr();

    if (this.$refs.input && focus) {
      (this.$refs.input as HTMLElement).focus();
    }
  }

  updatePhoneNbr() {
    const fullNumber = {
      countryCode: this.selectedCountry.callingCode,
      phoneNumber: this.phoneNumber
    };

    this.$emit("phone-nbr-changed", fullNumber);

    if (this.onChange) {
      this.onChange(fullNumber);
    }
  }
}
