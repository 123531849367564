





























import { Component, Prop, Vue } from "vue-property-decorator";
import restClient from "utils/restClient";

import { ErretailFormGridControlDto } from "generated/erretailFormGridControl/erretail-form-grid-control-dto.ts";
import { setTimeout } from "timers";

@Component({})
export default class ErretailForm extends Vue {
  constructor() {
    super();
    this.state = {
      surname: "",
      bookingReference: "",
      error: false
    };
  }
  @Prop()
  private dto!: ErretailFormGridControlDto;

  private state: {
    surname: string;
    bookingReference: string;
    error: boolean;
  };

  encodeQueryData(data: any) {
    const ret = Array<string>();
    for (let d in data)
      ret.push(encodeURIComponent(d) + "=" + encodeURIComponent(data[d]));
    return ret.join("&");
  }

  showError() {
    if (!this.state.error) {
      this.state.error = true;
      setTimeout(() => {
        this.state.error = false;
      }, 7000);
    }
  }

  onFormSubmit() {
    this.$store.dispatch("pageLoader/show");
    let request = {
      actionType: this.dto.actionType,
      surname: this.state.surname,
      bookingReference: this.state.bookingReference,
      viewport: "lg"
    };

    if (this.dto.cleanLayout) {
      request["layout"] = "clean";
    }
    ///todo: [INTERIM] Remove below line and uncomment what is below after BITS will implement Constanz replacement
    window.location.href =
            this.dto.erretailPageUrl + "?" + this.encodeQueryData(request);
    // restClient
    //   .post(this.dto.searchBookingUrl, {
    //     surname: this.state.surname,
    //     bookingReference: this.state.bookingReference
    //   })
    //   .then(response => {
    //     if (response.exists) {
    //       window.location.href =
    //         this.dto.erretailPageUrl + "?" + this.encodeQueryData(request);
    //     } else {
    //       this.showError();
    //       this.$store.dispatch("pageLoader/hide");
    //     }
    //   })
    //   .catch(error => {
    //     this.showError();
    //     this.$store.dispatch("pageLoader/hide");
    //   });
  }
}
