





import { Component, Prop, Vue } from "vue-property-decorator";
import EventBus from "utils/event-bus/event-bus";
import { EVENT_OPEN_LOGIN_MODAL } from "utils/event-bus/types";

@Component
export default class MemberAuthorizedLink extends Vue {
  redirectOrLogin($event: Event) {
    if (!this.$store.getters["site/user"].isAuthenticated) {
      $event.preventDefault();
      EventBus.$emit(EVENT_OPEN_LOGIN_MODAL, this.$refs["a"]["href"]);
    }
  }
}
