










import { Component, Model, Vue } from 'vue-property-decorator';

@Component({})
export default class SearchPanel extends Vue {
    @Model('search-changed')
    private query!: string;

    private searchInputText: string = this.query;

    onSearchSubmit(): void {
        this.$emit('search-changed', this.searchInputText);
    }
}
