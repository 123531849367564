



























import { Component, Prop, Vue, Mixins, Watch } from "vue-property-decorator";
import { AirSearchMemberFooterDTO } from "generated/airSearch/air-search-member-footer-dto";
import {ProductCategory} from "utils/domain/productCategories";

@Component({})
export default class MemberFooter extends Vue {
  @Prop()
  protected dto!: AirSearchMemberFooterDTO;
  private productCategories = ProductCategory;

  bitsIframeWithProduct(productCategory: ProductCategory): string {
    return this.$store.getters["site/pageUrl"].bitFlowIframeLink
            + "?category="+ productCategory;
  }
}
