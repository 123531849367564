





















import { Component, Prop, Vue } from "vue-property-decorator";
import FormClient from "utils/formClient";
import { createEmptyFormData } from "generated/api/base-api-request-dto";
import { SessionApiPasswordProtectedLoginDTO } from "generated/api/sessionApi/session-api-password-protected-login-dto";

@Component({})
export default class SessionRestrictedLoginPage extends Vue {
  @Prop({})
  private dto!: SessionApiPasswordProtectedLoginDTO;

  private state: {
    success: boolean;
    message: string;
  } = { success: true, message: "" };

  submit(): void {
    this.$store.dispatch("pageLoader/text", "");
    this.$store.dispatch("pageLoader/show");

    this.state = { success: true, message: "" };
    const formData = createEmptyFormData(this.dto.loginRequest);

    formData.append("accessKey", this.dto.loginRequest.accessKey);
    formData.append(
      "loginPageId",
      this.dto.loginRequest.loginPageId.toString()
    );
    formData.append("domain", this.dto.loginRequest.domain);
    if (this.dto.loginRequest.returnPage) {
      formData.append("returnPage", this.dto.loginRequest.returnPage);
    }
    FormClient.send(this.dto.loginRequest, formData).then(
      resp => {
        this.state = resp;
        if (this.state.success) {
          window.location.href = resp.redirectUrl;
          return;
        }
        this.$store.dispatch("pageLoader/hide");
      },
      error => {
        this.$store.dispatch("pageLoader/hide");
      }
    );
  }
}
