import restClient from '../restClient';

class ForgotPasswordService {
  constructor() {
    this.baseUrl = '/Umbraco/Api/ForgotPasswordApi';
  }
  /* eslint-disable class-methods-use-this */
  /* eslint no-console: 0 */
  async validateLink(token) {
    try {
      const url = `${this.baseUrl}/ValidateLink?token=${token}`;
      const result = await restClient.get(url);
      return result;
    } catch (error) {
      console.log(error);
    }
    return false;
  }
  /* eslint-disable class-methods-use-this */
  /* eslint no-console: 0 */
  async createLink(data) {
    try {
      const url = `${this.baseUrl}/CreateLink`;
      const result = await restClient.post(url, data);
      return result;
    } catch (error) {
      console.log(error);
    }
    return null;
  }
  /* eslint-disable class-methods-use-this */
  /* eslint no-console: 0 */
  async updateProfile(token, data) {
    try {
      const url = `${this.baseUrl}/UpdateProfile?token=${token}`;
      const result = await restClient.put(url, data);
      return result;
    } catch (error) {
      console.log(error);
    }
    return null;
  }
}
export default new ForgotPasswordService();
