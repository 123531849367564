
























































import { Component, Prop, Vue, Model, Watch } from "vue-property-decorator";
import _get from "lodash/get";

import { constants, passengertypes } from "./tickettypes";
import {AirSearchTicketTypeDTO} from 'generated/airSearch/air-search-ticket-type-dto';

@Component({})
export default class PassengersInput extends Vue {
  @Model("passengers-changed", { type: Array })
  private passengers!: Array<number>;
  private errors = [];
  private validationMsgs = [];

  @Prop({})
  private options!: Array<object>;

  // @Prop({})
  // private ticketType!: object;

  @Prop({})
  private passengerText!: string;

  @Prop({})
  private passengersText!: string;

  @Prop({})
  private minPassengers!: number[];

  @Prop({})
  private maxPassengers!: number[];

  @Prop({})
  private groupBookingFormUrl!: string;

  @Prop({})
  private groupBookingUrlText!: string;
  @Prop({})
  private groupBookingText!: string;

  @Prop({})
  private selectedTicketType!: AirSearchTicketTypeDTO

  @Prop({})
  private disabled!: boolean;

  @Watch("minPassengers")
  onMinPassengersChanged(this: any, minPassengers) {
    this.$emit("passengers-changed", [...minPassengers]);
  }

  displayedValue(this: any) {
    const numPassengers = this.passengers.reduce((acc, it) => acc + it, 0);
    const suffix = numPassengers > 1 ? this.passengersText : this.passengerText;
    return `${numPassengers} ${suffix}`;
  }

  onInputMousedown(event) {
    // close dropdown if already open
    const isElemFocused = document.activeElement === event.target;
    if (isElemFocused) {
      event.target.blur();
      event.preventDefault();
    }
  }

  removeClicked(this: any, passengerType) {
    if (!this.removeDisabled(passengerType)) {
      this.removePassenger(passengerType);
      this.validate();
    }
  }

  addClicked(this: any, passengerType) {
    if (!this.addDisabled(passengerType)) {
      this.addPassenger(passengerType);
      this.validate();
    }
  }

  addPassenger(this: any, passengerType) {
    const newPassengers = [...this.passengers];
    newPassengers[passengerType] += 1;
    this.$emit("passengers-changed", newPassengers);
  }

  removePassenger(this: any, passengerType) {
    const newPassengers = [...this.passengers];
    newPassengers[passengerType] -= 1;
    this.$emit("passengers-changed", newPassengers);
  }

  addDisabled(this: any, passengerType) {
    const reachedTotalMaxTravelers =
      this.passengers[passengertypes.ADULTS] +
        this.passengers[passengertypes.CHILDREN] >=
      constants.MAX_TRAVELERS;

    const hasLessInfantsThanAdults =
      this.passengers[passengertypes.ADULTS] >
      this.passengers[passengertypes.INFANTS];

    const reachedMaxForPassengerType =
      this.passengers[passengerType] >= this.maxPassengers[passengerType];

    let isDisabled = false;
    if (reachedMaxForPassengerType) {
      isDisabled = true;
    } else if (passengerType === passengertypes.INFANTS) {
      isDisabled = !hasLessInfantsThanAdults ||
              (this.selectedTicketType.priceTypeName === "STU"
                      || this.selectedTicketType.priceTypeName === "YCD"
                      || this.selectedTicketType.priceTypeName === "YTH");
    } else if (reachedTotalMaxTravelers) {
      isDisabled = true;
    }

    return isDisabled;
  }

  removeDisabled(this: any, passengerType) {
    const reachedTotalMinTravelers =
      this.passengers[passengertypes.ADULTS] +
        this.passengers[passengertypes.CHILDREN] <=
      constants.MIN_TRAVELERS;

    const moreAdultsThanInfants =
      this.passengers[passengertypes.ADULTS] >
      this.passengers[passengertypes.INFANTS];

    const reachedMinForPassengerType =
      this.passengers[passengerType] === this.minPassengers[passengerType];

    let isDisabled = false;
    if (reachedMinForPassengerType) {
      isDisabled = true;
    } else if (passengerType === passengertypes.ADULTS) {
      isDisabled = !moreAdultsThanInfants;
    } else if (reachedTotalMinTravelers) {
      isDisabled = true;
    }

    return isDisabled;
  }

  validate(this: any) {
    this.errors = [];
    // Not empty
    let amtPassengers = this.passengers.reduce((acc, it) => acc + it);

    if (amtPassengers === 0) {
      this.errors.push(this.validationMsgs.atLeastOnePassenger);
    }

    return this.errors;
  }
}
