





















































import { Component, Prop, Vue, Mixins } from "vue-property-decorator";
import ModalsMixin from "utils/modals/modalsMixin";
import { TierPointsSettingsListDTO } from "generated/tierpoints/tier-points-settings-list-dto";
import { TierPointsSettingsDTO } from "generated/tierpoints/tier-points-settings-dto";
import { FrontendUserStoreDTO } from "generated/store/frontend-user-store-dto";

@Component({})
export default class ProgressBarComponent extends Mixins(ModalsMixin) {
    @Prop({
        required: true
    })
    private dto!: TierPointsSettingsListDTO;

    private currentValue!: number;
    private overScale: boolean = false;
    private maxValue!: number;
    private levels!: number;

    getCurrentMinValue(collection: TierPointsSettingsDTO[], index: number): number {
        if (index == 0) {
            return 0
        }
        return collection[index - 1].limit;
    }

    created(): void {
        this.init();
    }

    init(): void {
        this.levels = this.dto.collection.length;
        this.maxValue = this.dto.collection[this.levels - 1].limit;
        if (this.siteUser.isAuthenticated) {
            this.currentValue = this.siteUser.loyalty.memberCard.qualifyingMiles;
            this.overScale = this.currentValue > this.maxValue;
        } else {
            this.currentValue = 0;
        }
    };

    get siteUser(): FrontendUserStoreDTO {
        return this.$store.getters["site/user"];
    }

    calculateWidth(tier: TierPointsSettingsDTO, index: number): number {
        if (index == 0) {
            return (tier.limit / this.maxValue) * 100;
        }
        const currentMin = this.getCurrentMinValue(this.dto.collection, index);
        const limit = tier.limit - currentMin;
        return (limit / this.maxValue) * 100;
    }

    calculateMargin(currentValue: number): number {
        if(this.overScale){
            return 100;
        }
        return (currentValue / this.maxValue)*100;
    }

    calculatePadding(tier: TierPointsSettingsDTO, index: number) {
        return this.calculateWidth(tier, index) - 4;
    }

    getHeader(): string {
        if (this.siteUser.isAuthenticated) {
            return `${this.currentValue.toString()} ${this.dto.header}`;
        }
        return this.dto.header;
    }

    formatNumber(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }
}
