
















import { Component, Prop, Vue, Mixins, Watch } from "vue-property-decorator";
import { AirSearchTranslationsDTO } from "generated/airSearch/air-search-translations-dto";
import { AirSearchTicketTypeDTO } from "generated/airSearch/air-search-ticket-type-dto";

@Component({})
export default class TicketInformation extends Vue {
  @Prop()
  protected translations!: AirSearchTranslationsDTO;
  
  @Prop()
  protected passengers!: number[];

  @Prop()
  protected ticketType!: AirSearchTicketTypeDTO;
}
