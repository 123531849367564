
export const constants = {
  MAX_TRAVELERS: 9,
  MIN_TRAVELERS: 0,
  MAX_INFANTS_CORPORATE: 4,
  MAX_UNACCOMPANIED_MINORS: 2,
};

export const typeOfTravel = {
  Private : 1,
  Corporate : 2
}

export const tickettypes = {
  ADT: 0,
  Bonus: 1,
  Contract: 2,
  TMP: 3,
  TPU: 4,
  STU: 5,
  YCD: 6,
  YTH: 7,
  MaxPax: 8,
  ChildBooking: 9,
  TPP: 10,
};

export const passengertypes = {
  ADULTS: 0,
  CHILDREN: 1,
  INFANTS: 2,
};

export const maxPassengers : any = {
  default: [constants.MAX_TRAVELERS, constants.MAX_TRAVELERS, constants.MAX_TRAVELERS],
  ADT: [constants.MAX_TRAVELERS, constants.MAX_TRAVELERS, constants.MAX_TRAVELERS],
  Bonus: [constants.MAX_TRAVELERS, constants.MAX_TRAVELERS, constants.MAX_TRAVELERS],
  Contract: [1, 0, 0],
  TMP: [1, 0, 0],
  TPU: [1, 0, 0],
  STU: [constants.MAX_TRAVELERS, 0, constants.MAX_TRAVELERS],
  YCD: [constants.MAX_TRAVELERS, 0, constants.MAX_TRAVELERS],
  YTH: [constants.MAX_TRAVELERS, 0, constants.MAX_TRAVELERS],
  MaxPax: [1, 0, 0],
  BraBank: [1, 0, 1],
  VoucherFre: [1, 0, 1],
  ChildBooking: [0, constants.MAX_UNACCOMPANIED_MINORS, 0],
  TPP: [1, 0, 0],
};

export const minPassengers : any = {
  default: [1, 0, 0],
  ADT: [1, 0, 0],
  Bonus: [1, 0, 0],
  Contract: [1, 0, 0],
  TMP: [1, 0, 0],
  TPU: [1, 0, 0],
  STU: [1, 0, 0],
  YCD: [1, 0, 0],
  YTH: [1, 0, 0],
  MaxPax: [1, 0, 0],
  BraBank: [1, 0, 0],
  VoucherFre: [1, 0, 0],
  ChildBooking: [0, 1, 0],
  TPP: [1, 0, 0],
};
