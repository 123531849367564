import { Module, GetterTree, ActionTree, MutationTree } from "vuex";
import { ModalState, RootState } from "../types";

const namespaced: boolean = true;

export const state: ModalState = {
  currentModal: ""
};

const getters: GetterTree<ModalState, RootState> = {
  currentModal(state): string {
    return state.currentModal;
  }
};

const actions: ActionTree<ModalState, RootState> = {
  show({ commit }, modal): any {
    commit("show", modal);
  },
  toggle({ commit }, modal): any {
    commit("toggle", modal);
  },
  hide({ commit }): any {
    commit("show", "");
  }
};

function setState(state: ModalState, modal: any) {
  state.currentModal = modal;
}

const mutations: MutationTree<ModalState> = {
  show(state, modal) {
    setState(state, modal);
  },
  toggle(state, modal) {
    const newCurrentModalState = state.currentModal !== modal ? modal : "";
    setState(state, newCurrentModalState);
  },
  hide(state) {
    setState(state, "");
  }
};

export const module: Module<ModalState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
