




















































































































































































import { Component, Prop, Mixins, Watch } from "vue-property-decorator";
import _get from "lodash/get";

import ModalsMixin from "utils/modals/modalsMixin";
import AirsearchMixin from "./AirsearchMixin";
import AirsearchTabs from "./AirsearchTabs.vue";

import SelectAirportInput from "./SelectAirportInput.vue";
import ToggleSelector from "./ToggleSelector.vue";
import RangeDatepicker from "utils/datepicker-range/RangeDatepicker.vue";
import PassengersInput from "./PassengersInput.vue";
import TicketInformation from "./TicketInformation.vue";

import {
  AirSearchMaxPaxDTO,
  validateAirSearchPresentDTO,
  AirSearchPresentDTO
} from "generated/airSearch";
import { AirSearchFlowApiMaxPaxRequestDTO } from "generated/api/air-search-flow-api-max-pax-request-dto";
import { MaxPaxApiVerifyCodeRequestDTO } from "generated/api/maxPax";
import restClient from "utils/restClient";
import { minPassengers, maxPassengers } from "./tickettypes";
import { nameofFactory } from "utils/nameofFactory";
const nameOfAirSearchFlowApiMaxPaxRequest = nameofFactory<
  AirSearchFlowApiMaxPaxRequestDTO
>();

@Component({
  components: {
    SelectAirportInput,
    ToggleSelector,
    RangeDatepicker,
    PassengersInput,
    AirsearchTabs,
    TicketInformation
  }
})
export default class AirsearchMaxpax extends Mixins(
  ModalsMixin,
  AirsearchMixin
) {
  @Prop({})
  public dto!: AirSearchMaxPaxDTO;
  public state: AirSearchPresentDTO = validateAirSearchPresentDTO(
    this.dto.airSearchPreset
  );
  private verificationCodeValid: boolean = false;
  private validationErrors: any = {
    verificationCode: <any>[],
    termsAndConditions: ""
  };
  private verificationCode: number | null = null;
  private acceptTnc: boolean = this.dto.maxPaxAcceptedTerms;
  private payWithAVoucherDisabled = false;

  mounted() {
    this.checkPayWithAVoucher();
  }

  get priceTypeName() {
    return _get(this.state, "ticketType.priceTypeName");
  }
  get routesTo() {
    return _get(this.state, "from.destinationRoutes", []);
  }
  get isBraBankCampaign(): boolean {
    return this.dto.cffCode1 == "BRABANK" || this.dto.cffCode2 == "BRABANK";
  }
  get isVoucherFreCampaign(): boolean {
    return (
      this.dto.cffCode1 == "VOUCHERFRE" || this.dto.cffCode2 == "VOUCHERFRE"
    );
  }

  get routeInformation() {
    const { to } = this.getAirports(
      this.dto.routes,
      _get(this.state.from, "airport.airportId", -1),
      _get(this.state.to, "airport.airportId", -1)
    );
    return _get(to, "airport.routeInformation", null);
  }

  get minPassengers(): number[] {
    if (this.isBraBankCampaign) {
      return minPassengers["BraBank"];
    } else if (this.isVoucherFreCampaign) {
      return minPassengers["VoucherFre"];
    } else {
      const ticketTypeKey = _get(
        this.state.ticketType,
        "priceTypeName",
        "default"
      );
      return minPassengers[ticketTypeKey];
    }
  }

  get maxPassengers(): number[] {
    if (this.isBraBankCampaign) {
      return maxPassengers["BraBank"];
    } else if (this.isVoucherFreCampaign) {
      return maxPassengers["VoucherFre"];
    } else {
      const ticketTypeKey = _get(
        this.state.ticketType,
        "priceTypeName",
        "default"
      );
      return maxPassengers[ticketTypeKey];
    }
  }

  get vertificationPlaceholder(): string {
    return `${this.dto.authenticationCaption} - ${this.dto.authenticationInfoText}`;
  }

  get vertificationType(): string {
    return this.dto.maxPaxIsCustomPassword ? "text" : "number";
  }

  get passengerOptions() {
    const { translations } = this.dto;
    return [
      {
        caption: translations.adultsCaption,
        subcaption: translations.adultsSubCaption
      },
      {
        caption: translations.childsCaption,
        subcaption: translations.childsSubCaption
      },
      {
        caption: translations.infantsCaption,
        subcaption: translations.infantsSubCaption
      }
    ];
  }

  resetTncMessage(): void {
    this.validationErrors.termsAndConditions = "";
  }

  onStartDateChanged(newDate: Date) {
    this.state.startDate = newDate;
    this.checkPayWithAVoucher();
  }

  onEndDateChanged(newDate: Date) {
    this.state.endDate = newDate;
    this.checkPayWithAVoucher();
  }

  onRoundTripChanged(newValue: boolean) {
    this.state.isRoundTrip = newValue;
    this.checkPayWithAVoucher();
  }

  checkPayWithAVoucher() {
    this.payWithAVoucherDisabled = this.checkPayWithAVoucherDisabled();
    if (this.state.payWithAVoucher && this.payWithAVoucherDisabled) {
      this.state.payWithAVoucher = false;
    }
  }

  checkPayWithAVoucherDisabled(): boolean {
    if (this.state.showPayWithAVoucher) {
      if (this.state.startDate && (this.state.startDate < this.state.payWithAVoucherMinimumDate || this.state.startDate > this.state.payWithAVoucherMaximumDate)) {
        return true;
      } else if (this.state.endDate && (this.state.endDate < this.state.payWithAVoucherMinimumDate || this.state.endDate > this.state.payWithAVoucherMaximumDate)) {
        return true;
      }
    }

    return false;
  }

  @Watch("state.from")
  onNewStateFrom(newFromState) {
    // Focus should be applied once
    if (this.$refs.toInput !== undefined && newFromState.airport) {
      this.state.to = <any>{};
      (this.$refs.toInput as any).$refs.input.focus();
    }
  }

  // This need to be inplace, otherwise DateRange will throw an error
  openTicketTypeDropdown() {}

  validateCode() {
    this.validationErrors.verificationCode = [];
    if (this.dto.maxPaxVerificationRequired) {
      const codeLength = (this.$refs["verificationCode"] as any).value.length;
      if (codeLength === 0) {
        this.validationErrors.verificationCode.push(
          this.dto.propertyValidationTranslations.notEmpty
        );
      }
    }

    return this.validationErrors.verificationCode;
  }

  onVerificationCodeInput() {
    if (this.validationErrors.verificationCode.length > 0) {
      this.validateCode();
    }
  }

  onShowLowFareChange() {
    this.state.showLowFareCalendar = !this.state.showLowFareCalendar;
  }

  onPayWithAVoucher() {
    this.state.payWithAVoucher = !this.state.payWithAVoucher;
  }

  formIsValid(this: any) {
    let formErrors = this.validateInputs(this.$refs);
    // validate on this component's verification code
    if (!this.acceptTnc && this.dto.maxPaxShowTermsCheckBox) {
      this.validationErrors.termsAndConditions = this.dto.propertyValidationTranslations.maxPaxAcceptTerms;
      return false;
    }
    formErrors.push(...this.validateCode());
    return formErrors.length === 0;
  }

  async validateMaxPaxVerificationCode(request: MaxPaxApiVerifyCodeRequestDTO) {
    try {
      request.code = this.verificationCode!.toString();
      return await restClient.post(request.action, request);
    } catch (err) {
      //console.log("ERROR", err);
    }
  }

  async handleSubmit(this: any, event) {
    const formData = this.createFormFromRoutesData(
      this.dto.routes,
      this.dto.form,
      this.state
    );

    formData.append(
      nameOfAirSearchFlowApiMaxPaxRequest("campaignId"),
      this.dto.maxPaxCampaignId
    )

    if (this.dto.maxPaxVerificationRequired) {
      const result = await this.validateMaxPaxVerificationCode(
        this.dto.maxPaxApiVerifyCodeForm
      );
      this.verificationCodeValid = result ? result.Valid : false;

      if (this.verificationCodeValid) {
        this.sendForm(this.dto.form, formData);
      } else {
        this.validationErrors.verificationCode.push(
          this.dto.propertyValidationTranslations.maxPaxWrongVertificationCode
        );
      }
    } else {
      this.sendForm(this.dto.form, formData);
    }
  }

  onSubmit(e) {
    if (this.formIsValid()) {
      this.handleSubmit(e);
    }
  }

  onAirportsSwitch() {
    const { to, from } = this.getAirports(
      this.dto.routes,
      _get(this.state.to, "airport.airportId", -1),
      _get(this.state.from, "airport.airportId", -1)
    );

    if (to !== undefined && from !== undefined) {
      this.state.from = from;
      this.state.to = to;
    }
  }
}
