import "whatwg-fetch";
import { BaseApiRequestDTO } from "generated/api/base-api-request-dto";
import { JsonErrorResponseDTO } from "generated/error/json-error-response-dto";

const CUSTOM_CULTURE_HEADER = "X-Umb-Culture";
const CUSTOM_PAGEID_HEADER = "X-Umb-PageId";

class FormClient {
  private headers: { [id: string]: string };

  constructor() {
    this.headers = {
      "X-Umb-Culture": "sv-SE",
      "X-Umb-PageId": "-1"
    };
    this.setCulture(document.body.getAttribute('lang'));
    this.setPageId(document.body.getAttribute('data-pageid'));
  }

  setCulture(culture: string | null) {
    if (culture !== null && this.headers[CUSTOM_CULTURE_HEADER] !== culture) {
      this.headers[CUSTOM_CULTURE_HEADER] = culture;
    }
  }

  setPageId(currentPageId: string | null) {
    if (currentPageId !== null && this.headers[CUSTOM_PAGEID_HEADER] !== currentPageId) {
      this.headers[CUSTOM_PAGEID_HEADER] = currentPageId;
    }
  }

  async send(request: BaseApiRequestDTO, formData: FormData): Promise<any> {
    const req = await window.fetch(request.action, {
      headers: this.headers,
      method: request.method,
      credentials: "include",
      body: formData
    });

    let response: any | JsonErrorResponseDTO = null;

    try {
      response = await req.json();
    } catch (error) {
      throw {
        code: 500,
        header: "NetworkError",
        message: "Teknisk fel, kontakta FlygBRA kundservice."
      };
    }

    if (response == null) {
      return response;
    }
    else if(this.isErrorResponse(response) || !req.ok) {
      throw {
        code: response.code,
        header: response.header,
        message: response.message
      };
    }
    else {
      return response;
    }
  }

  private isErrorResponse(response: any | JsonErrorResponseDTO): boolean {
    return (<JsonErrorResponseDTO>response).error !== undefined && (<JsonErrorResponseDTO>response).error !== null;
  }
}

export default new FormClient();
