




















import { Component, Prop, Vue, Mixins } from "vue-property-decorator";
import ModalsMixin from "utils/modals/modalsMixin";
import { HeaderDTO } from "generated/header";
import ChangeToTmModal from "utils/components/ChangeToTmModal.vue";

@Component({
  components: {
    ChangeToTmModal
  }
})
export default class MainNavLogOut extends Mixins(ModalsMixin) {
  @Prop({})
  private dto!: HeaderDTO;

  get logout() {
    return {
      url: this.$store.getters["site/pageUrl"]["logoutPageUrl"],
      text: this.$store.getters["site/translation"]["mainNavLogOut"]["signOut"]
    };
  }

  get myPage() {
    return {
      url: this.$store.getters["site/pageUrl"]["memberStartPageUrl"],
      text: this.$store.getters["site/translation"]["mainNavLogOut"]["myPage"]
    };
  }

  get initials(): string {
    let { firstName, surName } = this.$store.getters["site/user"];
    return firstName[0] + surName[0];
  }

  get showChangeRoleModal() : boolean {
    return this.dto.isAuthenticated && this.dto.showChangeRoleModal;
  }
}
