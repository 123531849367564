





import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

const scrollOptions = {
  easing: 'ease-in',
  offset: -160, // floating navbar
  force: true,
  cancelable: true,
  onStart: function(element) {
    // scrolling started
  },
  onDone: function(element) {
    // scrolling is done
  },
  onCancel: function() {
    // scrolling has been interrupted
  },
  x: false,
  y: true
};

@Component
export default class MemberPageSection extends Vue {
  @Prop({ default: () => 1423 })
  protected defaultpageid!: number;

  get currentPage() {
    return this.$store.getters['memberPage/pageId'];
  }

  @Watch('currentPage')
  onPageChanged(this: any, newPage) {
    if(this.defaultpageid != newPage){
      this.scrollToSubPage();
    }
  }

  scrollToSubPage() {
    if (!this.$refs.dynamicPages) return;

    // timeout to allow some time for the selected component to render before scrolling to it
    window.setTimeout(() => {
      (<any>this).$scrollTo(this.$refs.dynamicPages, 200, scrollOptions);
    }, 50);
  }
  
  beforeMount() {
   this.$store.dispatch("memberPage/setPageId", this.defaultpageid);
  }
}
