














































































import { Component, Prop, Vue, Mixins, Watch } from "vue-property-decorator";
import { RegisterPointsDTO } from "generated/member/register-points-dto";

import { RouteItemResponseDTO } from "generated/api/route/route-item-response-dto";
import routeService from 'utils/services/routeService';
import FormClient from "utils/formClient";
import ValidationMixin from "utils/mixins/validationMixin";
import SubmitButton from "utils/components/SubmitButton.vue";
import Datepicker from 'utils/datepicker/Datepicker.vue';
import { yyyymmdd } from "utils/dateFormat";

interface RegisterPointState {
  to: Array<any>;
  from: Array<any>;
  flightNumber: number;
  ticketNumber: number;
  selectedToDestination: string;
  selectedFromDestination: string;
  flightDate: Date;
  selectedAirline: string;
  selectedAirlineTicketPrefix: number;
}

@Component({
  components: {
    Datepicker,
    SubmitButton
  }
})
export default class RegisterPoints extends Mixins(ValidationMixin) {
  @Prop({})
  protected dto!: RegisterPointsDTO;  
  private routes : Array<any> = [];
  private finnairRoutes : Array<any> = [];
  private formErrorMessage: string = "";
  private formOkMessage: string = "";  
  private formState = {
    ok: false,
    error: false,
    loading: false
  };

  private state: RegisterPointState = {
    to: [],
    from: [],
    flightNumber: <any>null,
    ticketNumber: <any>null,
    selectedToDestination: "",
    selectedFromDestination: "",
    flightDate: new Date(),
    selectedAirline: "",
    selectedAirlineTicketPrefix: <any>null
  };

  private privateFlightDate = new Date(this.state.flightDate);
  private flightDateHasFocus = false;

  get showCurrentPage() {
    return this.$store.getters["memberPage/pageId"] === this.dto.pageId;
  }

  mounted() {
    const airlineCode = this.dto.airlineList[0].value; 
    routeService
      .fetch(this.dto.finnairRouteListRequest)
      .then(resp => {
        this.finnairRoutes = resp.routes;
      });
    

    routeService
      .fetch(this.dto.routeListRequest)
      .then(resp => {
        this.routes = resp.routes;
        this.setStateOnAirline(airlineCode);
        this.state.selectedAirline = airlineCode;
      });
  }

  onChange(airline) {
    this.setStateOnAirline(airline);
  }

  onFromDestinationChange(selectFrom : string){
    if(selectFrom != null && this.state.selectedAirline == "TF"){
      const foundItem = this.routes.find((it : RouteItemResponseDTO) => it.value == selectFrom);
      this.state.to = foundItem != null ? foundItem.destinationRoutes : [];
    }
    else {
      if(selectFrom === 'HEL'){
          this.state.to = this.finnairRoutes.filter(x=>x.value !== "HEL");
      }else{
          this.state.to = this.finnairRoutes.filter(x=>x.value === "HEL");
      }
    }
  }

  setStateOnAirline(airlineCode: string) {
    if (airlineCode == "") return;
    this.state.selectedAirlineTicketPrefix = this.getSelectedAirlineTicketPrefix(airlineCode);
    this.state.from = this.getFromDestinations(airlineCode);
    this.state.to = this.getToDestinations(airlineCode);   
    this.state.selectedFromDestination = "";
    this.state.selectedToDestination = "";
  }

  getFromDestinations(airlineCode: string) {
    switch (airlineCode) {
      case "TF":
        return this.routes;
      case "AY":
        return this.finnairRoutes;
      default:
        return this.routes;
    }
  }

  getToDestinations(airlineCode: string) {
    switch (airlineCode) {
      case "TF":
        return [];
      case "AY":
        return this.finnairRoutes;
      default:
        return [];
    }
  }
  
  getSelectedAirlineTicketPrefix(airlineCode: string) {
    switch (airlineCode) {
      case "TF":
        return 276;
      case "AY":
        return 105;
      default:
        return <any>null;
    }
  }

  onClickedDate(this: any) {
    window.setTimeout(this.$refs.flightDate.focus, 10);
  }

  onFocusChangedFlightDate(hasFocus) {
    this.flightDateHasFocus = hasFocus;
  }
  
  @Watch('state.flightDate')
  onFlightDateChanged(this: any) {
    this.$refs.flightDate.blur();
  }

  onRegistrationPointsFormSubmit(event) {
    const firstInvalidElem = this.getFirstOrDefaultInvalidInput(event.target);
    const pattern = new RegExp("^[0-9]{10}$");

    if (firstInvalidElem) {
      this.focusOnInvalidInput(firstInvalidElem);
    } else if (!pattern.test(this.state.ticketNumber.toString())) {
      this.focusOnInvalidInput((this.$refs as any).ticketNr);
    } else {
      const formData = new FormData();
      formData.append("operatingAirline", this.state.selectedAirline);
      formData.append("originAirportCode", this.state.selectedFromDestination);
      formData.append(
        "destinationAirportCode",
        this.state.selectedToDestination
      );
      formData.append("flightNumber", this.state.flightNumber.toString());
      formData.append("ticketNumber", this.state.ticketNumber.toString());
      formData.append("flightDate", yyyymmdd(this.state.flightDate));
      FormClient.send(this.dto.form, formData)
        .then(response => {
          this.formState.loading = false;
          this.formState.ok = true;
          this.formOkMessage = response.message;
        })
        .catch(error => {
          this.formState.loading = false;
          this.formState.error = true;
          this.formErrorMessage = error.message;
        });
    }
  }
}
