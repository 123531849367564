
















import { Component, Prop, Vue, Mixins } from "vue-property-decorator";
import ModalsMixin from "utils/modals/modalsMixin";
import restClient from "utils/restClient";
import {
  constructDTO as constructLogoutRequest,
  SessionApiLogoutRequestDTO
} from "generated/api/sessionApi/session-api-logout-request-dto";
import { SessionExpirationDTO } from "generated/sesionExpiration/session-expiration-dto";

@Component
export default class SessionExpiration extends Mixins(ModalsMixin) {
  private sessionExpirationTimeout!: any;

  mounted() {
    this.refresh();
    document.addEventListener("click", this.refresh);
  }

  @Prop({ default: constructLogoutRequest })
  private dto!: SessionExpirationDTO;

  get loginUrl(): string {
    return this.$store.getters["site/pageUrl"].loginPageUrl;
  }

  refresh() {
    clearTimeout(this.sessionExpirationTimeout);
    this.sessionExpirationTimeout = setTimeout(() => {
      this.logOut();
    }, this.dto.timeout);
  }

  logOut() {
    Vue.prototype.userData = null;
    restClient
      .post(this.dto.logoutRequest.action)
      .then(resp => {
        this.showModal("you-have-been-signed-out-modal");
      })
      .catch(error => {
        this.goToLoginPage();
      });
  }
  
  goToLoginPage() {
    this.$store.dispatch(
      "pageLoader/text",
      this.dto.translations.sessionApiExpirationRedirectMessage
    );
    this.$store.dispatch("pageLoader/show");
    window.location.replace(this.loginUrl);
  }
}
