




























import { Component, Prop, Vue, Model } from 'vue-property-decorator';
import DatepickerDropdown from './DatepickerDropdown.vue';
import dateHelper from 'utils/dateHelper';

@Component({
  components: {
    DatepickerDropdown
  }
})
export default class Datepicker extends Vue {
  @Model('select-date', { type: Date })
  selectedDate!: Date;

  @Prop({})
  private startDate!: Date;

  @Prop({})
  private minimumStartDate!: Date;

  @Prop({})
  private maximumStartDate!: Date;

  @Prop({})
  private endDate!: Date;

  @Prop({ default: 'form-date' })
  private inputClass!: String;

  @Prop({})
  private inputPlaceholderName!: String;

  @Prop({})
  private focusCb!: Function;

  @Prop({ default: () => true })
  private isSettingStartDate!: boolean;

  @Prop({default: () => 0})
  private startRangeMonthOffset!: number;

  /*
    If set to true: Will allow dropdown to be positioned absolute 
    after first parent that has relative position
  */
  @Prop({ default: false })
  private posStatic!: boolean;

  private errors = [];
  private calendar = null;

  private validationMsgs = {};
  private translations = {};

  onInputMousedown(event) {
    // close dropdown if already open
    const isElemFocused = document.activeElement === event.target;
    if (isElemFocused) {
      event.target.blur();
      event.preventDefault();
    }
  }

  focus(this: any) {
    this.$refs.inputElem.focus();
  }

  blur(this: any) {
    this.$refs.inputElem.blur();
  }

  onInputFocus(this: any) {
    if (this.focusCb) {
      this.focusCb(true);
    }
  }

  onInputBlur(this: any) {
    if (this.focusCb) {
      this.focusCb(false);
    }
  }

  selectDate(date: Date) {
    this.$emit('select-date', date);
  }

  get dateText(): String {
    if (!this.selectedDate) return '';

    const dateText = `${this.selectedDate.getDate()} ${
      dateHelper.monthNames[this.selectedDate.getMonth()]
    } ${this.selectedDate.getFullYear()}`;

    return dateText;
  }
}
