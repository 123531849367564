




























import { Component, Prop, Vue } from "vue-property-decorator";
import FormClient from "utils/formClient";
import PasswordEye from "utils/components/PasswordEye.vue";
import { createEmptyFormData } from "generated/api/base-api-request-dto";
import { MyBookingsEntryDTO } from "generated/myBookings/my-bookings-entry-dto";
import { SessionApiLoginResponseDTO } from "generated/api/sessionApi/session-api-login-response-dto";

interface MyBookingsLoginFormState {
  username: string;
  password: string;
}

@Component({components:{PasswordEye}})
export default class MyBookingsLoginForm extends Vue {
  @Prop({})
  private dto!: MyBookingsEntryDTO;
  private formErrorMessage: string = "";
  private formState = {
    ok: false,
    error: false,
    loading: false
  };

  private state: MyBookingsLoginFormState = {
    username: <any>null,
    password: <any>null
  };

  onLoginFormSubmit(event: any): void {
    this.formState.loading = true;
    this.formState.ok = false;
    this.formState.error = false;
    const formData = createEmptyFormData(this.dto.loginRequest);
    
    formData.append("userName", this.state.username);
    formData.append("password", this.state.password);
    formData.append("memberStartPage", this.dto.loginRequest.memberStartPage);
    formData.append(
      "keepMeSignedIn",
      this.dto.loginRequest.keepMeSignedIn ? "true" : "false"
    );

    FormClient.send(this.dto.loginRequest, formData)
      .then((response: SessionApiLoginResponseDTO) => {
        this.formState.loading = false;
        this.formState.ok = true;
        window.location.reload();
      })
      .catch(error => {
        this.formState.loading = false;
        this.formState.error = true;
        this.formErrorMessage = error.message;
      });
  }
}
