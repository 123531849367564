import { render, staticRenderFns } from "./ProgressBarComponent.vue?vue&type=template&id=12d80905&"
import script from "./ProgressBarComponent.vue?vue&type=script&lang=ts&"
export * from "./ProgressBarComponent.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "ProgressBarComponent.vue"
export default component.exports