

















import { Component, Prop, Vue, Mixins, Watch } from "vue-property-decorator";
import { AirSearchMemberOrganizationDTO } from "generated/airSearch/air-search-member-organization-dto";
import { FrontendUserStoreDTO } from 'generated/store/frontend-user-store-dto';

@Component({})
export default class MemberOganization extends Vue {
  @Prop()
  protected dto!: AirSearchMemberOrganizationDTO;
}
