













































import { Component, Prop, Vue, Mixins, Watch } from "vue-property-decorator";
import ModalsMixin from "utils/modals/modalsMixin";
import { AirSearchMemberHeaderDTO } from "generated/airSearch/air-search-member-header-dto";
import { FrontendUserStoreDTO } from 'generated/store/frontend-user-store-dto';
import ProgressBarComponent from '../../utils/components/ProgressBarComponent.vue';

@Component({
  components: {
    ProgressBarComponent
  }
})
export default class MemberHeader extends Mixins(ModalsMixin) {
  @Prop()
  protected dto!: AirSearchMemberHeaderDTO;

  get welcomeHeaderText(): string {
    return (
      this.dto.translations.profileWelcome +
      " " +
      this.siteUser.firstName
    );
  }

  get siteUser(): FrontendUserStoreDTO {
    return this.$store.getters["site/user"];
  }
  
  get profileBackgroundStyle(): string {
    return this.siteUser.profilePictureUrl != null
      ? `background-image: url('${
          this.siteUser.profilePictureUrl
        }'); background-repeat: round;`
      : "";
  }

  showSubpage(pageId) {
    this.$store.dispatch("memberPage/setPageId", pageId);
  }
}
