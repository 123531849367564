





































import { Component, Prop, Vue } from "vue-property-decorator";
import {
  FeedbackAPIRequestDTO,
  constructFeedbackAPIRequestDTO
} from "generated/api/feedback/feedback-api-request-dto";
import { FeedbackDTO } from "generated/feedback/feedback-dto";
import restClient from "utils/restClient";

@Component({})
export default class FeedbackBox extends Vue {
  @Prop({})
  private dto!: FeedbackDTO;
  private amtRatingStars: number = 4;
  private hoveredRating: number = -1;
  private isExpanded: boolean = false;
  private hasRecentlySubmitted = false;
  private hasBeenOpened: boolean = false;
  private animationClass: string = "";

  expand() {
    this.hasBeenOpened = true;
    this.isExpanded = true;
    (this.$refs.textarea as HTMLElement).focus();
  }

  shrink() {
    this.isExpanded = false;
  }

  toggleExpand() {
    if (this.isExpanded) {
      this.shrink();
    } else {
      this.expand();
    }
  }

  selectRating(index) {
    this.dto.form.rating = index;
  }

  isSubmitDisabled() {
    if (this.dto.form.message.length === 0) return true;
    if (this.dto.form.rating < 0) return true;
    return false;
  }

  isStarActive(index) {
    const rating =
      this.hoveredRating >= 0 ? this.hoveredRating : this.dto.form.rating;
    return rating >= index;
  }

  onMouseRating(index) {
    this.hoveredRating = index;
  }

  onMouseLeave() {
    this.hoveredRating = -1;
  }

  onSubmit() {
    this.hasRecentlySubmitted = true;
    restClient.post(this.dto.form.action, this.dto.form).then(resp => {
      this.hasRecentlySubmitted = false;
      this.isExpanded = false;
      //this.dto.form = constructFeedbackAPIRequestDTO();
    });
  }
}
