












































import { Component, Prop, Vue, Mixins } from "vue-property-decorator";
import { BoardingDTO } from "generated/boarding";
import restClient from "utils/restClient";
import { TravelerBoardingCheckInResponseDTO } from "generated/api/travelerBoarding/traveler-boarding-check-in-response-dto";
import ValidationMixin from "utils/mixins/validationMixin";
import SubmitButton from "utils/components/SubmitButton.vue";

interface BoardingState {
  surname: string;
  bookingReference: string;
  cultureInfo: string;
}

@Component({
  components: {
    SubmitButton
  }
})
export default class Boarding extends Mixins(ValidationMixin) {
  @Prop({})
  private dto!: BoardingDTO;
  private formErrorMessage: string = "";

  private formState = {
    ok: false,
    error: false,
    loading: false
  };

  private state: BoardingState = {
    surname: <any>null,
    bookingReference: <any>null,
    cultureInfo: this.dto.checkInRequest.cultureInfo
  };

  get getHeaderBackgroundUrl(): string {
    return this.dto.headerBackgroundUrl != null
      ? "url(" + this.dto.headerBackgroundUrl + ")"
      : "";
  }

  onBoardingFormSubmit(event) {
    const firstInvalidElem = this.getFirstOrDefaultInvalidInput(event.target);
    if (firstInvalidElem) {
      this.focusOnInvalidInput(firstInvalidElem);
    } else {
      
      this.formState.loading = true;
      this.formState.ok = false;
      this.formState.error = false;
      
      this.$store.dispatch("pageLoader/text", "Vänta ett ögonblick...");
      this.$store.dispatch("pageLoader/show");
      
      restClient
        .post(this.dto.checkInRequest.action, this.state)
        .then((response) => {
          this.formState.loading = false;
          this.formState.ok = true;          
          window.location.href = response.Url;
        })
        .catch(error => {
          this.formState.loading = false;
          this.formState.error = true;
          this.formErrorMessage = error.message;
          this.$store.dispatch("pageLoader/hide");
        });
    }
  }
}
