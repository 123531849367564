import { Module, GetterTree, ActionTree, MutationTree } from "vuex";
import { BaseApiRequestDTO, createEmptyFormData } from 'generated/api/base-api-request-dto';

import { RequestState, RootState, } from "../types";
import { SignInAction } from '../actionTypes';
import FormClient from 'utils/formClient';

const namespaced: boolean = true;

export const state: RequestState = {
};

const getters: GetterTree<RequestState, RootState> = {
};

const actions: ActionTree<RequestState, RootState> = {
  signInMember(this: any, { }, signInAction: SignInAction) {
    this.dispatch('pageLoader/text', 'Vänta ett ögonblick...');
    this.dispatch('pageLoader/show');

    let signInForm: BaseApiRequestDTO = {
      action: signInAction.action,
      inputList: signInAction.inputList,
      method: signInAction.method,
    };

    let formData = createEmptyFormData(signInForm);

    formData.append('userName', signInAction.userName);
    formData.append('password', signInAction.password);
    formData.append('keepMeSignedIn', signInAction.keepMeSignedIn ? 'true' : 'false');
    formData.append('memberStartPage', signInAction.memberStartPage);

    FormClient.send(signInForm, formData)
      .then((response: any) => {
        if (signInAction.onSuccess) {
          signInAction.onSuccess(response);
        }
        
        this.dispatch('pageLoader/text', 'Loggas in...');
        window.location.href = response.userRedirectUrl;
      })
      .catch(error => {
        if (signInAction.onError) {
          signInAction.onError(error);
        }
        this.dispatch('pageLoader/hide');
      });
  }
};

const mutations: MutationTree<RequestState> = {
};

export const module: Module<RequestState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
