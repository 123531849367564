














































import { Component, Prop, Vue } from 'vue-property-decorator';
import { SearchPageDTO } from 'generated/searchPage/search-page-dto';

@Component({})
export default class SearchPage extends Vue {
  @Prop({})
  private dto!: SearchPageDTO;
  private backgroundImageUrl: string =
    'background: url(' + this.dto.backgroundImageUrl + ')';
  private inputPlaceholderText: string = this.dto.inputPlaceholderText;
  private searchInputText: String = this.dto.searchInputText;
  private pageNumbers: number = Math.ceil(
    this.dto.totalRecords / this.dto.take
  );
  private activePageNumber: number = 0;
  private noSearchResultsFoundMessage: string = this.dto
    .noSearchResultsFoundMessage;

  mounted(): void {
    this.calculateActivePagNumber();
  }

  calculateActivePagNumber(): void {
    const current = (this.dto.skip + this.dto.take) / this.dto.take;
    this.activePageNumber = Math.ceil(current);
  }

  createPageLink(index): string {
    const skip = index * this.dto.take;
    const take = this.dto.take;
    let query = this.searchInputText;
    const uri = encodeURI(`?q=${query}&s=${skip}&t=${take}`);
    return uri;
  }

  onSearchSubmit(): void {
    let query = this.searchInputText;
    const uri = encodeURI(`?q=${query}`);
    window.location.href = uri;
  }
}
