
import { createDefault as createFrontendDefaultPageUrl } from 'generated/store/frontend-page-url-store-dto';
import { createDefault as createFrontendDefaultGeneral } from 'generated/store/frontend-general-store-dto';
import { createDefault as createFrontendDefaultUser } from 'generated/store/frontend-user-store-dto';
import { createDefault as createFrontendDefaultTranslations } from 'generated/store/frontend-translations-store-dto';
import { createDefault as createDefaultUtil } from 'generated/store/frontend-util-store-dto';

export default {
  createFrontendDefaultPageUrl,
  createFrontendDefaultGeneral,
  createFrontendDefaultUser,
  createFrontendDefaultTranslations,
  createDefaultUtil
};