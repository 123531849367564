import { Module, GetterTree, ActionTree, MutationTree } from "vuex";
import { CaruselState, RootState } from "../types";

const namespaced: boolean = false;

export const state: CaruselState = {
  carouselIndex: 0,
};

const getters: GetterTree<CaruselState, RootState> = {
  airSearchCarouselIndex: state => state.carouselIndex,
};

const actions: ActionTree<CaruselState, RootState> = {
  setCarouselIndex({ commit }, index) {
    commit('setCarouselIndex', index);
  },
};

const mutations: MutationTree<CaruselState> = {
  setCarouselIndex(state, index) {
    state.carouselIndex = index;
  },
};

export const module: Module<CaruselState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
