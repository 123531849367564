








import { Component, Prop, Vue } from "vue-property-decorator";
import { PrepaidModalDTO } from "generated/member/prepaid-modal-dto";
@Component
export default class PrepaidModal extends Vue {
  @Prop({})
  protected dto!: PrepaidModalDTO;
}
