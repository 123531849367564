

















































import { Component, Prop, Vue, Mixins } from "vue-property-decorator";
import stickybits from "stickybits";

import {
  HeaderDTO,
  HeaderSubNavigationDTO,
  HeaderLoginDTO
} from "generated/header";

import { LanguageDTO } from "generated/language-dto";
import { AlertDTO } from "generated/alert/alert-dto";
import { NcNavigationItemDTO } from "generated/nc-navigation-item-dto";

import ModalsMixin from "utils/modals/modalsMixin";
import MainNavMenu from "./components/mainNavMenu/MainNavMenu.vue";
import MainNavLogin from "./components/mainNavLogin/MainNavLogin.vue";
import MainNavLogOut from "./components/mainNavLogin/MainNavLogOut.vue";
import LogoutLink from "utils/components/LogoutLink.vue";
import ieDetector from  'utils/ieDetector';
import MainNavSearch from './components/mainNavSearch/MainNavSearch.vue';

@Component({
  components: {
    MainNavSearch,
    MainNavMenu,
    MainNavLogin,
    MainNavLogOut,
    LogoutLink
  }
})
export default class HeaderComponent extends Mixins(ModalsMixin) {
  @Prop({})
  private dto!: HeaderDTO;

  mounted() {
    // polyfill for position sticky
    stickybits(".navbar-main__wrapper", { useStickyClasses: true });
  }

  toggleMenu(this: any): void {
    this.toggleModal("main-nav-menu");
  }

  showLogin(this: any): void {
    this.showModal("main-nav-login");
  }

  get startPageUrl(): String {
    return this.dto.startPageUrl;
  }

  get logotypeUrl(): String {
    return this.dto.logotypeUrl;
  }

  get isAuthenticated(): Boolean {
    return this.dto.isAuthenticated;
  }

  get navItems(): Array<NcNavigationItemDTO> {
    return this.dto.mainNavigationItems;
  }

  get languages(): Array<LanguageDTO> {
    return this.dto.languages;
  }

  get loginDTO(): HeaderLoginDTO {
    return this.dto.login;
  }
  
  get isIEBrowser() : boolean{
    return ieDetector.isIEBrowser;
  }
}
