






















import { Component, Prop, Vue } from 'vue-property-decorator';

import RegisterDepartmentLogin from './RegisterDepartmentLogin.vue';
import RegisterDepartmentForm from './RegisterDepartmentForm.vue';

import { RegisterDepartmentDTO } from 'generated/registerDepartment/register-department-dto';

@Component({
  components: {
    RegisterDepartmentLogin,
    RegisterDepartmentForm
  }
})
export default class RegisterDepartment extends Vue {
  @Prop({})
  private dto!: RegisterDepartmentDTO;
  private willCreateMember: boolean = false;

  get headerBackgroundStyle(): string {
    return this.dto.registerBackgroundUrl != null
      ? `url('${this.dto.registerBackgroundUrl}')`
      : '';
  }
  
  onCreateMember() {
    this.willCreateMember = true;
  }

  onLoginMember(response) {
    window.location.reload();
  }

  /*
  3 different states:
  1. Not authenticated - have not yet decided to authenticate or create member:
      shows option to either authenticate or create member

  2. Not authenticated - decided to create member:
      Shows form to create member & create department

  3. Authenticated
      Shows form to create department
  */
}
