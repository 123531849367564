











import { Component, Prop, Mixins } from "vue-property-decorator";
import { PersonalInformationDTO } from "generated/member/personal-information-dto";

import { FormOfPaymentDcpUrlResponseDTO } from "generated/api/formOfPayment/form-of-payment-dcp-url-response-dto";
import { createEmptyFormData } from "generated/api/base-api-request-dto";

import FormClient from "utils/formClient";
import ValidationMixin from "utils/mixins/validationMixin";
import SubmitButton from "utils/components/SubmitButton.vue";

@Component({
  components: {
    SubmitButton
  }
})
export default class CreateCreditCardForm extends Mixins(ValidationMixin) {
  @Prop({ default: () => "" })
  protected title!: string;

  @Prop({})
  protected dto!: PersonalInformationDTO;

  @Prop({ required: true })
  protected formOfPaymentTypeIndex!: number;

  @Prop({ required: true })
  protected onCreatedCard!: (success: boolean) => void;

  private isLoadingIFrame: boolean = false;
  private selectedValue: string = "";

  selectCard(e) {
    const formData = createEmptyFormData(this.dto.formOfPaymentAddCreditCardRequest);

    formData.append(
      "frequentFlyerId",
      this.dto.formOfPaymentAddCreditCardRequest.frequentFlyerId
    );
    formData.append(
      "formOfPaymentType",
      this.formOfPaymentTypeIndex.toString()
    );
    formData.append("cardType", e.target.value);

    formData.append(
      "backUrl",
      this.dto.formOfPaymentAddCreditCardRequest.backUrl
    );
    formData.append(
      "successUrl",
      this.dto.formOfPaymentAddCreditCardRequest.successUrl
    );
    formData.append(
      "failureUrl",
      this.dto.formOfPaymentAddCreditCardRequest.failureUrl
    );

    this.isLoadingIFrame = true;

    FormClient.send(this.dto.formOfPaymentAddCreditCardRequest, formData).then(
      (response: FormOfPaymentDcpUrlResponseDTO) => {
        if (this.$refs.iframeform) {
          (<any>this.$refs.iframeform).src = response.url;
        }
      }
    );
  }

  onIFrameLoad() {
    this.isLoadingIFrame = false;
    let iframetag = <HTMLIFrameElement>this.$refs.iframeform;

    if (
      iframetag &&
      iframetag.contentDocument &&
      iframetag.contentDocument.body
    ) {
      let iframeBody = iframetag.contentDocument.body;
      let failedElement = iframeBody.querySelector(
        "#Failure"
      ) as HTMLInputElement;
      let successElement = iframeBody.querySelector(
        "#Success"
      ) as HTMLInputElement;

      if (failedElement != null && this.onCreatedCard != null) {
        this.selectedValue = "";
        this.onCreatedCard(false);
      }

      if (successElement != null && this.onCreatedCard != null) {
        this.selectedValue = "";
        this.onCreatedCard(true);
      }
    }
  }
}
