







import { Component, Prop, Mixins } from "vue-property-decorator";
import ModalsMixin from 'utils/modals/modalsMixin';
import {AirSearchTranslationsDTO} from 'generated/airSearch';

@Component
export default class OnlyOneClipLeftModal extends Mixins(ModalsMixin) {
  @Prop({})
  private dto!: AirSearchTranslationsDTO;
}
