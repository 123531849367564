

















































import { Component, Prop, Mixins } from "vue-property-decorator";
import { AirSearchTranslationsDTO } from "generated/airsearch/air-search-translations-dto";
import { PropertyValidationTranslationsDTO } from "generated/validation/property-validation-translations-dto";
import { format } from "utils/store/helpers/string";
import ModalsMixin from "utils/modals/modalsMixin";

interface State {
  resetReference: string;
  reference: string;
  isInEditMode: boolean;
  isReferenceValid: boolean;
  inputErrorReferenceMessage: string;
}

@Component
export default class InvoiceReferenceModal extends Mixins(ModalsMixin) {
  @Prop({ required: true })
  protected translations!: AirSearchTranslationsDTO;
  @Prop({ required: true })
  protected properyValidation!: PropertyValidationTranslationsDTO;
  @Prop({ default: () => "" })
  protected reference!: string;
  @Prop({})
  private onReferenceSubmit!: (reference: string) => void;

  private currentState: State = {
    resetReference: this.reference.slice(0),
    reference: this.reference.slice(0),
    isInEditMode: false,
    isReferenceValid: false,
    inputErrorReferenceMessage: ""
  };

  get editBtnText(): string {
    return !this.currentState.isInEditMode
      ? this.translations.tacEditInformation
      : this.translations.referenceCancel;
  }

  mounted(){
    this.currentState.isReferenceValid = this.validateReference(this.reference);
  }

  validateReference(reference: string) {
    if (reference == null || reference == undefined) {
      this.currentState.inputErrorReferenceMessage = this.translations.referenceMissingMessage;
      return false;
    }
    else if(reference == "")
    {
      return true;
    }
    else if (!/^[a-zA-Z 0-9]+$/.test(reference)) {
      this.currentState.inputErrorReferenceMessage = this.properyValidation.regExpIsAToZAndNumeric;
      return false;
    } else if (reference.length > 20) {
      this.currentState.inputErrorReferenceMessage = format(
        this.properyValidation.tooLongFormat,
        [0, 20]
      );
      return false;
    }
    return true;
  }

  onCancel() {
    if (this.currentState.isInEditMode) {
      this.currentState = Object.assign(this.currentState, {
        reference: this.currentState.resetReference.slice(0)
      });
    }

    this.currentState.isInEditMode = !this.currentState.isInEditMode;
  }

  onContinue() {
    if (this.currentState.isInEditMode) {
      this.currentState.isInEditMode = !this.currentState.isInEditMode;
      this.currentState = Object.assign(this.currentState, {
        resetReference: this.currentState.reference.slice(0)
      });
    } else {
      if (
        this.validateReference(this.currentState.reference) &&
        this.onReferenceSubmit
      ) {
        this.onReferenceSubmit(this.currentState.reference);
      }
    }
  }
}
