
























import { Component, Prop, Vue, Mixins } from "vue-property-decorator";
import { WebShopTileDto } from "generated/webShopGridControl/web-shop-tile-dto";
import MemberAuthorizedLink from "../memberAuthorizedLink/MemberAuthorizedLink.vue";

@Component({
  components: {
    MemberAuthorizedLink
  }
})
export default class WebshopTile extends Vue {
  @Prop()
  private dto!: WebShopTileDto;

  get bitsIframeWithProduct(): string {
    if (this.dto.relatedPrepaidProductCategory && this.dto.relatedPrepaidProductId) {
      return (
              this.$store.getters["site/pageUrl"].bitFlowIframeLink +
              "?categoryUdi=" +
              this.dto.relatedPrepaidProductCategory +
              "&productId=" +
              this.dto.relatedPrepaidProductId
      );
    }
    else if(this.dto.relatedPrepaidProductCategory) {
      return (
              this.$store.getters["site/pageUrl"].bitFlowIframeLink +
              "?categoryUdi=" +
              this.dto.relatedPrepaidProductCategory
      );
    }
    return "";
  }
}
