



import { Component, Prop, Vue } from "vue-property-decorator";
import restClient from "../restClient";

import FeedbackBox from "./components/FeedbackBox.vue";
import CookieConsent from "./components/CookieConsent.vue";
import Alerts from "./components/Alerts.vue";

@Component({
  components: {
    CookieConsent,
    FeedbackBox,
    Alerts
  }
})
export default class LazyLoad extends Vue {
  @Prop({})
  private url!: string;
  private dto: any = null;
  private compiled: any = null;

  mounted() {
    restClient.get(this.url).then(async resp => {
      if (resp.componentName == "") {
        // destroy the vue listeners, etc
        this.$destroy();

        // remove the element from the DOM
        if (this.$el && this.$el.parentNode) {
          this.$el.parentNode.removeChild(this.$el);
        }
      } else {
        this.dto = resp.dto;
        this.compiled = resp.componentName;
      }
    });
  }
}
