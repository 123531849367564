<script>
export default {
  data() {
    return {
      scrollSections: [],
      activeSection: {}
    };
  },
  methods: {
    handleScroll() {
      const currentScrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;

      for (let i = 0; i < this.scrollSections.length; i += 1) {
        const scrollSection = this.scrollSections[i];
        const sectionOffsetFromBottom =
          scrollSection.offsetTop + scrollSection.offsetHeight - 10;
        if (currentScrollTop <= sectionOffsetFromBottom) {
          this.activeSection = scrollSection;
          break;
        }
      }
    }
  },
  beforeMount() {
    window.addEventListener('scroll', this.handleScroll);
  },
  mounted() {
    this.scrollSections = document
      .getElementById('scrollable-content')
      .querySelectorAll('article');
    this.activeSection = this.scrollSections[0];

    // alternate scroll sections' background color
    for (let i = 1; i < this.scrollSections.length; i+=2) {
      const bgDiv = document.createElement('div');
      const offsetLeft = this.scrollSections[i].offsetLeft;

      bgDiv.setAttribute(
        'style',
        `position: absolute; z-index: -1;  content: ""; top: 0; bottom: 0; left: -${offsetLeft}px; width: 100vw; background: #f8f8f8;`
      );

      this.scrollSections[i].appendChild(bgDiv);
    }
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  }
};
</script>
