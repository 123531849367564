












































































































































































import { Component, Prop, Mixins } from "vue-property-decorator";
import { HeaderLoginDTO } from "generated/header/header-login-dto";
import { SessionApiLoginResponseDTO } from "generated/api/sessionApi/session-api-login-response-dto";
import { SessionApiLoginRequestDTO } from "generated/api/sessionApi/session-api-login-request-dto";
import { NcNavigationItemDTO } from "generated/nc-navigation-item-dto";
import { createEmptyFormData } from "generated/api/base-api-request-dto";
import FormClient from "utils/formClient";
import ModalsMixin from "utils/modals/modalsMixin";
import ieDetector from "utils/ieDetector";
import PasswordEye from "utils/components/PasswordEye.vue";

import { FrontendPageLoginUrlStoreDTO } from "generated/store/frontend-page-login-url-store-dto";
import { LoginDTOTranslations } from "generated/login/login-dto-translations";

@Component({components:{PasswordEye}})
export default class MainNavLogin extends Mixins(ModalsMixin) {
  @Prop({})
  private dto!: HeaderLoginDTO;

  get translation(): LoginDTOTranslations {
    return this.$store.getters["site/translation"].login;
  }

  get links(): FrontendPageLoginUrlStoreDTO {
    return this.$store.getters["site/loginUrl"];
  }

  private state: SessionApiLoginRequestDTO = {
    action: this.dto.loginRequest.action,
    inputList: this.dto.loginRequest.inputList,
    method: this.dto.loginRequest.method,
    memberStartPage: this.dto.loginRequest.memberStartPage,
    userName: <any>null,
    password: <any>null,
    keepMeSignedIn: this.dto.loginRequest.keepMeSignedIn
  };
  private formErrorMessage: string = "";
  private formState = {
    ok: false,
    error: false,
    loading: false,
    usernameValid: false,
    usernameEmpty: false
  };

  get navItem(): NcNavigationItemDTO {
    return this.dto.mainNavigationItem;
  }

  showLogin(this: any): void {
    this.showModal("main-nav-login");
  }

  get isIEBrowser(): boolean {
    return ieDetector.isIEBrowser;
  }

  get loginText(): string {
    return this.$store.getters["site/translation"]["login"]["login"];
  }

  get becomeMemberText(): string {
    return this.$store.getters["site/translation"]["login"]["sign"];
  }

  onLoginFormSubmit(event: any): void {
    this.formState.loading = true;
    this.formState.ok = false;
    this.formState.error = false;
    this.$store.dispatch("pageLoader/text", "Vänta ett ögonblick...");
    this.$store.dispatch("pageLoader/show");

    const formData = createEmptyFormData(this.state);

    formData.append("userName", this.state.userName);
    formData.append("password", this.state.password);
    formData.append(
      "keepMeSignedIn",
      this.state.keepMeSignedIn ? "true" : "false"
    );
    formData.append("memberStartPage", this.state.memberStartPage);

    FormClient.send(this.state, formData)
      .then((response: SessionApiLoginResponseDTO) => {
        this.formState.loading = false;
        this.formState.ok = true;

        this.$store.dispatch("pageLoader/text", "Loggas in...");
        window.location.href = response.userRedirectUrl;
      })
      .catch(error => {
        this.$store.dispatch("pageLoader/hide");
        this.formState.loading = false;
        this.formState.error = true;
        this.formErrorMessage = error.message;
      });
  }

  async validateUsernameInputOnFocus(event: FocusEvent) {
    if (event.currentTarget !== null) {
      const element = event.currentTarget as HTMLInputElement;

      if (element.getAttribute("required") && !element.value) {
        this.formState.usernameValid = true;
        this.formState.usernameEmpty = true;
      } else if (
        element.getAttribute("pattern") &&
        element.value &&
        !new RegExp(element.getAttribute("pattern") as string).test(
          element.value
        )
      ) {
        this.formState.usernameValid = false;
        this.formState.usernameEmpty = false;
      } else {
        this.formState.usernameValid = true;
        this.formState.usernameEmpty = false;
      }
    }
  }
}
