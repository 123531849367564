













import { Component, Prop, Vue } from "vue-property-decorator";
import restClient from "utils/restClient";
import { LogoutDTO } from "generated/logout/logout-dto";

@Component
export default class LogoutAction extends Vue {
  @Prop({})
  private dto!: LogoutDTO;

  created() {
    this.$store.dispatch("pageLoader/text", this.dto.loaderText);
    this.$store.dispatch("pageLoader/show");
    restClient
      .post(this.dto.logoutRequest.action)
      .then(resp => {
        window.location.replace(this.dto.redirectUrl);
      })
      .catch(error => {
        window.location.replace(this.dto.redirectUrl);
      });
  }
}
