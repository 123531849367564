

































































































































































import { Component, Prop, Vue, Mixins } from "vue-property-decorator";
import { HeaderDTO } from "generated/header";
import ModalsMixin from "utils/modals/modalsMixin";
import Modal from "utils/modals/Modal.vue";
import ChangeToTMModal from "utils/components/ChangeToTmModal.vue";
import LogoutLink from "utils/components/LogoutLink.vue";
import { FrontendPageLoginUrlStoreDTO } from 'generated/store/frontend-page-login-url-store-dto';
import { SessionApiChangeToTmRequestDTO, constructDTO as constructRequest } from "generated/api/sessionApi/session-api-change-to-tm-request-dto";
import restClient from "utils/restClient";

@Component({
  components: {
    Modal,
    ChangeToTMModal,
    LogoutLink
  }
})
export default class MainNavMenu extends Mixins(ModalsMixin) {
  @Prop({})
  private dto!: HeaderDTO;
  private searchInputText: String = "";
  private expandedLink: Number = -1;

  get leftNavigationItemsLength(): number {
    return this.dto.subNavigationItems.leftNavigationItems.length;
  }


  get loginUrl() : FrontendPageLoginUrlStoreDTO {
    return this.$store.getters["site/loginUrl"];
  }

  toggleExpand(linkId: Number, e: any): void {
    if (!e.currentTarget.classList.contains("active")) {
      this.expandedLink = linkId;
      e.preventDefault();
    }
  }

  isExpanded(linkId: Number): boolean {
    const isLargeWindow = window.innerWidth > 991;
    return isLargeWindow || this.expandedLink === linkId;
  }

  onSearchSubmit(): void {
    const uri =
      this.dto.searchPageUrl + encodeURI(`?q=${this.searchInputText}`);
    window.location.href = uri;
  }

  get showChangeRoleModal() : boolean {
    return this.dto.isAuthenticated && this.dto.showChangeRoleModal;
  }

  changeToTm() {
    restClient
      .post(this.dto.member.changeToTmRequest.action)
      .then(response => {
        if (response.isTravelManager) {
          this.$store.dispatch("pageLoader/text", "Skickas till TM...");
          window.location.href = response.redirectUrl;
        } 
      });
  }
}
