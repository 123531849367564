











import { Component, Prop, Vue, Model, Watch } from "vue-property-decorator";

@Component({})
export default class SelectAirportInput extends Vue {
  @Model("toggled", { type: Boolean })
  model!: Boolean;

  @Prop({})
  private leftLabel!: String;

  @Prop({})
  private rightLabel!: String;

  // decides if model should be true if right label is selected.
  // default is set to left label
  @Prop({default: false})
  private targetRight!: Boolean;

  setValue(value) {
    this.$emit("toggled", value);
  }

  get isOnLeftLabel() {
    return (this.targetRight) ? !this.model : this.model;
  }
  
  toggle() {
    this.$emit("toggled", !this.model);
  }
}
