























import { Component, Prop, Vue } from "vue-property-decorator";
import { PrepaidItemDTO } from "generated/prepaid/prepaid-item-dto";
import { MyProductsDTOTranslations } from "generated/member/my-products-dto-translations";

@Component
export default class ProductItem extends Vue {
  @Prop({ required: true })
  protected dto!: PrepaidItemDTO;
  @Prop({ required: true })
  protected translations!: MyProductsDTOTranslations;

  get ticketType(): string {
    return this.dto.category == 2
      ? this.translations.annualPass
      : this.translations.punchTicket;
  }

  get hasTickets() : boolean {
    return this.dto.ticketsLeft != -1;
  }

  get buttonText() : string {
    return this.dto.category == 2
      ? this.translations.buyNew
      : this.translations.refill;
  }
}
