













import { Component, Prop, Vue } from "vue-property-decorator";
import { CookieConsentDTO } from "generated/cookie/cookie-consent-dto";
@Component
export default class CookieConsent extends Vue {
  @Prop({})
  private dto!: CookieConsentDTO;
  private showConsent: boolean = false;
  private didMount: boolean = false;

  get policyForCookieUrl(): string {
    return this.$store.getters["site/pageUrl"].policyForCookieUrl;
  }

  get hasCookieUrl(): boolean {
    return this.policyForCookieUrl != null;
  }

  mounted() {
    this.showConsent =
      document.cookie
        .split(";")
        .filter(item => item.includes(this.dto.cookieKey + "=")).length == 0;

    this.didMount = true;
  }

  close() {
    this.updateCookie();
    this.showConsent = false;
  }

  updateCookie() {
    let currentDate = new Date();
    var year = currentDate.getFullYear();
    var month = currentDate.getMonth();
    var day = currentDate.getDate();
    let expireDate = new Date(year + 5, month, day);
    let path = "/";
    document.cookie = `${
      this.dto.cookieKey
    }=Accepted=true;expires=${expireDate.toUTCString()};path=${path}`;
  }
}
