import restClient from '../restClient';
import { CountryDTO } from 'generated/country-dto';

class CountryService {
  async fetch(requestUrl: string): Promise<CountryDTO[]> {
    return await restClient.get(requestUrl) as CountryDTO[];
  }
}

export default new CountryService();
