


















import { Component, Prop, Vue } from "vue-property-decorator";
import { DestinationsDTO } from "generated/compositions/destinations-dto";

@Component
export default class Destinations extends Vue {
  @Prop({})
  private dto!: DestinationsDTO;
}
